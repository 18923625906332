import { useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { AddOutlined } from '@mui/icons-material';
import { MessageOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

const { TextArea } = Input;

function TextButtonNode({ data }) {
  const {nodeData} = data;
  const [inputCount, setInputCount] = useState(0);
  const textAreaRef = useRef(null); 
  const textAreaRefs = useRef([useRef(null), useRef(null), useRef(null)]);

  useEffect(() => {
    const count = data && data.nodeData && data.nodeData.additionalTexts ? Object.keys(data.nodeData.additionalTexts).length : 0;
      setInputCount(count);
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
  },[]);

  const handleMainTextAreaChange = (e) => {
    const textAreaElement = textAreaRef.current.resizableTextArea?.textArea;
    if (textAreaElement) {
      const cursorPosition = textAreaElement.selectionStart;
      data.onMainTextAreaChange(data.id, e.target.value);
      setTimeout(() => {
        textAreaElement.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }
  };

  const handleAddButton = () => {
    if (inputCount < 3) {
      setInputCount(inputCount + 1);
    }
  };

  const onCloseIconClick = () => {
    if (typeof data.onClose === 'function') {
      data.onClose(data.id); 
    }
  };

  const handleRemoveButton = (index) => {
    const newInputs = [...Array(inputCount).keys()].filter((i) => i !== index);
    setInputCount(newInputs.length);
  };

  const handleAdditionalTextAreaChange = (index, e) => {
    const textAreaElement = textAreaRefs.current[index + 1]?.current?.resizableTextArea?.textArea;
    if (textAreaElement) {
      const cursorPosition = textAreaElement.selectionStart;
      data.onAdditionalTextAreaChange(data.id, index, e.target.value);
      setTimeout(() => {
        textAreaElement.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }
  }

  const renderAdditionalInputs = () => {
    const inputs = [];
    for (let i = 0; i < inputCount; i++) {
      inputs.push(
        <div key={i} className="additionalInput">
          <TextArea
            maxLength={20}
            rows={1}
            placeholder={`Type Button Name ${i + 1}`}
            count={{ show: true, max: 20 }}
            // onChange={(e) => handleAdditionalTextAreaChange(i, e)}
            onChange={(e) => data.onAdditionalTextAreaChange(data.id, i, e.target.value)}
            className="nodrag"
            defaultValue={data?.nodeData?.additionalTexts?.[i]}
            // ref={textAreaRefs.current[i + 1]}
          />
          <Button
            className="removeBtn"
            onClick={() => handleRemoveButton(i)}
          >
            <CloseOutlined />
          </Button>
          <Handle type="source" position={Position.Right} id={`${data.id}${i}`} />
        </div>
      );
    }
    return inputs;
  };

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div className='nodes'>
        <div className='header'>
          <p><MessageOutlined /> {data.label}</p>
          <CloseOutlined
            className="closeIcon"
            onClick={onCloseIconClick}
          />
        </div>
        <div className='body'>
          <div className='inputMainBox'>
            <TextArea
              maxLength={1024}
              rows={5}
              placeholder="Type Message"
              count={{ show: true, max: 1024 }}
              allowClear
              onChange={(e) => data.onMainTextAreaChange(data.id, e.target.value)}
              className="nodrag"
              defaultValue={data?.nodeData?.mainText}
              // ref={textAreaRef}
            />
            {renderAdditionalInputs()}
            <Button
              className='addMoreBtn'
              onClick={handleAddButton}
            >
              Add Button <AddOutlined />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TextButtonNode;