import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { DatePicker, TimePicker, Table, Skeleton, Button, Flex, message, Input, Tooltip, Spin, Popconfirm, Col, Row, Drawer, Form, Select, Space, Checkbox, Typography, ColorPicker, Tag, Upload, Radio } from 'antd';
import { PlusOutlined, PhoneOutlined, LinkOutlined, DeleteOutlined, EditOutlined, UserAddOutlined, NotificationOutlined, LoadingOutlined } from '@ant-design/icons';
import textIcon from '../assets/attachmenticons/text.png';
import fileIcon from '../assets/attachmenticons/file.png';
import excelIcon from '../assets/attachmenticons/excel.png';
import pdfIcon from '../assets/attachmenticons/pdf.png';
import pptIcon from '../assets/attachmenticons/ppt.png';
import wordIcon from '../assets/attachmenticons/word.png';
const { Search } = Input;
const { Option } = Select;

const getBase64One = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const Campaign = (userData) => {
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [pageSize, setPageSize] = useState(20);
    const [sortOrder, setSortOrder] = useState('');
    const [sortField, setSortField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [openNewUser, setOpenNewUser] = useState(false);
    const [openRetargatDrawer, setOpenRetargatDrawer] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [templatedata, setTemplatedata] = useState([]);
    const [templateImage, setTemplateImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [imageList, setImageList] = useState([]);
    const [activeImageUid, setActiveImageUid] = useState(null);
    const [imageUploadLoading, setImageUploadLoading] = useState(false);
    const [templateImageId, setTemplateImageId] = useState('');
    const [templateImageExtension, setTemplateImageExtension] = useState('');
    const [data, setData] = useState([]);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [form] = Form.useForm();
    const [retargetform] = Form.useForm();
    const [editform] = Form.useForm();
    const [tagsData, setTagsData] = useState([]);
    const [isSchduleEnabled, setIsSchduleEnabled] = useState('enable');
    const [scheduling, setScheduling] = useState('now');


    const handleSchedule = (e) => {
        setScheduling(e.target.value);
    };
    const handleRadioChange = (e) => {
        setIsSchduleEnabled(e.target.value);
    };

    const filterOption = [
        {
            value: 'Name',
            label: 'Name',
        },
        {
            value: 'Email',
            label: 'Email',
        },
        {
            value: 'Phone',
            label: 'Phone Number',
        },
    ];
    const [selectedValues, setSelectedValues] = useState({});


    useEffect(() => {
        // const fetchGroupList = async () => {
        //     try {
        //         const response = await fetch(`https://connectby.io:3001/api/getgrouplist/?customerId=${userData?.userData[0]?.user_id}`);
        //         if (!response.ok) {
        //             throw new Error('Failed to fetch group list');
        //         }
        //         const responseData = await response.json();
        //         setGroupList(responseData.data);
        //     } catch (error) {
        //         console.error('Error fetching group list:', error);
        //     }
        // };
        // fetchGroupList();
        const getTags = async () => {
            const userId = userData?.userData[0]?.user_id;
            fetch('https://connectby.io:3001/api/get-tags', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_id: userId })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setTagsData(data);
                })
                .catch(error => {
                    console.error('Error fetching notes:', error);
                });
        };
        getTags();
    }, []);

    useEffect(() => {
        const templatedataUrl = `https://connectby.io:3001/api/get-template`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                'wabaid': userData?.userData[0]?.wabaid
            }
        };
        fetch(templatedataUrl, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then((body) => {
                if (body.success === true) {
                    setTemplatedata(body.responseData.data);
                } else {
                    console.error('Error: Success is not true');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [userData]);

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await fetch('https://connectby.io:3001/countrycode');
                if (!response.ok) {
                    throw new Error('Failed to fetch country codes');
                }
                const data = await response.json();
                const filteredData = data.filter(country => country.dial_code);
                const uniqueCountryCodes = [];
                const map = new Map();
                for (const country of filteredData) {
                    if (!map.has(country.dial_code)) {
                        map.set(country.dial_code, true);
                        uniqueCountryCodes.push(country);
                    }
                }
                setCountryCodes(uniqueCountryCodes);
                setFilteredCodes(uniqueCountryCodes);

            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };
        fetchCountryCodes();
    }, []);

    const handleEdit = async (record) => {
        const updatedEditUser = {
            ...record,
            customer_dob: record && record.customer_dob && record.customer_dob !== '' ? dayjs(record.customer_dob, "YYYY-MM-DD") : null,
        };
        setEditUser(updatedEditUser);
    };

    useEffect(() => {
        if (editUser !== null) {
            editform.setFieldsValue(editUser);
            setOpenEditUser(true);
        }
    }, [editform, editUser]);

    const fetchCustomerData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`https://connectby.io:3001/api/getcampaigns/?customerId=${userData?.userData[0]?.user_id}&result=${pageSize}&page=${pageNumber}&searchTerm=${searchTerm}&sortorder=${sortOrder}&sortfield=${sortField}`,);
            if (!response.ok) {
                throw new Error('Failed to fetch customer data');
            }
            const responseData = await response.json();
            const { data, totalItems } = responseData;
            console.log(data);
            setFilteredData(data);
            setTotalItems(totalItems);
        } catch (error) {
            console.log(error);
            console.error('Error fetching group data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomerData();
    }, [userData, pageNumber, pageSize, sortOrder, sortField, searchTerm]);

    const handleDelete = (id) => {

    };

    const handleGroupSelect = (groupId) => {
        setSelectedGroupId(groupId);
    };

    const handleTemplateSelect = (templateId) => {
        setSelectedTemplateId(templateId);
        const selectedTemplateData = templatedata.find(template => template.id === templateId);
        setSelectedTemplate(selectedTemplateData);
        console.log(selectedTemplateData);
    };

    useEffect(() => {

    }, [selectedTemplate]);

    const handleSearch = (value) => {
        setSearchTerm(value);
    };


    const handleTableChange = (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
        // console.log(sorter.order);
        // console.log(sorter.field);
        if (sorter.order) {
            setSortOrder(sorter.order);
            setSortField(sorter.field);
        } else {
            setSortOrder('');
            setSortField('');
        }
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'campaign_id',
            width: '5%',
            render: (_, __, index) => ((pageNumber - 1) * pageSize) + index + 1,
            sorter: true,
            sortOrder: sortField === 'campaign_id' && sortOrder,
        },
        {
            title: 'Campaign Name',
            dataIndex: 'campaign_name',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'campaign_name' && sortOrder,
            render: (_, record) => (
                <span onClick={() => navigate('/viewcampaigndetails', { state: { campaign_id: record?.campaign_id } })}>
                    {record?.campaign_name}
                </span>
            ),
        },
        {
            title: 'Template Name',
            dataIndex: 'template_name',
            width: '15%',
            sorter: true,
            sortOrder: sortField === 'template_name' && sortOrder,
        },
        {
            title: 'Status',
            dataIndex: 'campaign_status',
            width: '15%',
            sorter: true,
            render: (campaign_status) => (
                <>{campaign_status === '1' ? (<span style={{ color: 'orange' }}>In Progress</span>) : (<span style={{ color: 'green' }}>Completed</span>)}</>
            ),
        },
        {
            title: 'Attempted',
            dataIndex: 'group_id',
            width: '5%',
            sorter: true,
            render: (_, record) => {
                const total = parseInt(record['sent_count']) + parseInt(record['delivered_count']) + parseInt(record['read_count']) + parseInt(record['failed_count']);
                return `${total}`;
            }
        },
        {
            title: 'Sent',
            dataIndex: 'sent_count',
            width: '5%',
            sorter: true,
            render: (_, record) => {
                const total = parseInt(record['sent_count']) + parseInt(record['delivered_count']) + parseInt(record['read_count']);
                return `${total}`;
            }
        },
        {
            title: 'Delivered',
            dataIndex: 'delivered_count',
            width: '5%',
            sorter: true,
            render: (_, record) => {
                const total = parseInt(record['delivered_count']) + parseInt(record['read_count']);
                return `${total}`;
            }
        },
        {
            title: 'Read',
            dataIndex: 'read_count',
            width: '5%',
            sorter: true,
            render: (_, record) => {
                const total = parseInt(record['read_count']);
                return `${total}`;
            }
        },
        {
            title: 'Replied',
            dataIndex: 'read_count',
            width: '5%',
            sorter: true,
            render: (_, record) => {
                const total = parseInt(record['read_count']) - 1;
                return total < 0 ? '0' : `${total}`;
            }
        },
        {
            title: 'Failed',
            dataIndex: 'failed_count',
            width: '5%',
            sorter: true,
            render: (_, record) => {
                const total = parseInt(record['failed_count']);
                return `${total}`;
            }
        },
        {
            title: 'Created at ',
            dataIndex: 'created_at',
            width: '20%',
            render: (timestamp) => (
                <span>{dayjs.unix(timestamp).format('YYYY-MM-DD HH:mm:ss')}</span>
            ),
        }
        ,
        {
            title: 'Action',
            dataIndex: 'id',
            width: '10%',
            render: (_, record) => (
                <Flex gap="small" align="flex-end" vertical>
                    <Flex gap="small">
                        <Button type="primary" className='editButton' icon={<NotificationOutlined />} onClick={(e) => { e.stopPropagation(); handleRetargate(record); }}> Retarget Campaign </Button>
                    </Flex>
                </Flex>
            )
        },
    ];
    const onCloseNewUser = () => {
        setOpenNewUser(false);
    };

    const onCloseRetargatDrawer = () => {
        setSelectedCampaign({});
        setOpenRetargatDrawer(false);
    };


    const onCloseEditUser = () => {
        setOpenEditUser(false);
        setEditUser(null);
        editform.resetFields();
    };

    useEffect(() => {
        if (countryCodes && countryCodes.length > 0) {
            const filtered = countryCodes.filter(country => {
                return (
                    country.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    country.dial_code.includes(searchText) ||
                    country.code.includes(searchText)
                );
            });
            setFilteredCodes(filtered);
        }
    }, [searchText, countryCodes]);

    const handleAddNewGroup = () => {
        setOpenNewUser(true);
    };

    const handleRetargate = (record) => {
        setSelectedCampaign(record);
        setOpenRetargatDrawer(true);
    };

    const handleUpdateUserSubmit = async () => {
        try {
            setLoading(true);
            const formData = await editform.validateFields();
            const response = await fetch(`https://connectby.io:3001/api/update-whatsapp-group/${formData.group_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.userData[0]?.user_id,
                    group_name: formData.group_name || '',
                    group_color: formData.group_color || '',
                }),
            });
            if (response.ok) {
                message.success('Group Updated successfully');
                const updatedData = filteredData.map(item => {
                    if (item.group_id === formData.group_id) {
                        // Update the edited contact
                        return {
                            ...item,
                            group_name: formData.group_name,
                            group_color: formData.group_color
                        };
                    }
                    return item;
                });
                setFilteredData(updatedData);
                onCloseEditUser();
                editform.resetFields();
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error updating group:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleRetargateSubmit = async () => {
        const template = _.cloneDeep(selectedTemplate);
        const templateName = template.name;
        const templateLanguage = template.language;
        const variables = selectedValues;
        const imageId = templateImageId;
        const imageExtension = templateImageExtension;
        let datacomponent = [];
        template?.components?.forEach(component => {
            if (component.type === "HEADER") {
                const header = {
                    type: "header",
                    parameters: []
                };

                if (component.format === 'TEXT') {
                    let replacedHeader = component.text;
                    component.text.match(/{{\d+}}/g).forEach(element => {
                        const replacement = variables[`HEADER-${element}`] === 'Name' ? 'customer_name' : variables[`HEADER-${element}`] === 'Email' ? 'customer_email' : variables[`HEADER-${element}`] === 'Phone' ? 'customer_phone' : variables[`HEADER-${element}`];
                        replacedHeader = replacedHeader.replace(element, replacement);
                        header.parameters.push({
                            type: "text",
                            text: variables[`HEADER-${element}`] === 'Name' ? 'customer_name' : variables[`HEADER-${element}`] === 'Email' ? 'customer_email' : variables[`HEADER-${element}`] === 'Phone' ? 'customer_phone' : variables[`HEADER-${element}`]
                        });
                    });
                    component.text = replacedHeader;
                }

                if (component.format === 'IMAGE') {
                    if (imageExtension === 'image/png') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.png`;
                    }
                    if (imageExtension === 'image/jpeg') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.jpeg`;
                    }
                    header.parameters.push({
                        type: "image",
                        image: {
                            id: imageId
                        }
                    });
                }

                if (header.parameters.length > 0) {
                    datacomponent.push(header);
                }
            }

            if (component.type === "BODY") {
                const body = {
                    type: "body",
                    parameters: []
                };
                let replacedBody = component.text;
                if (component.text.match(/{{\d+}}/g)) {
                    component.text.match(/{{\d+}}/g).forEach(element => {
                        const replacement = variables[`BODY-${element}`] === 'Name' ? 'customer_name' : variables[`BODY-${element}`] === 'Email' ? 'customer_email' : variables[`BODY-${element}`] === 'Phone' ? 'customer_phone' : variables[`BODY-${element}`];
                        replacedBody = replacedBody.replace(element, replacement);
                        body.parameters.push({
                            type: "text",
                            text: variables[`BODY-${element}`] === 'Name' ? 'customer_name' : variables[`BODY-${element}`] === 'Email' ? 'customer_email' : variables[`BODY-${element}`] === 'Phone' ? 'customer_phone' : variables[`BODY-${element}`]
                        });
                    });
                }
                component.text = replacedBody;
                if (body.parameters.length > 0) {
                    datacomponent.push(body);
                }
            }
        });
        try {
            setLoading(true);
            const formData = await retargetform.validateFields();
            const response = await fetch('https://connectby.io:3001/api/send-retarget-waba-campaign', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'phonenumberid': userData?.userData[0]?.phonenumberid
                },
                body: JSON.stringify({
                    campaign_id: selectedCampaign.campaign_id,
                    campaign_name: formData.campaign_name,
                    selected_status: formData.selected_status === 'replied' ? 'read' : formData.selected_status,
                    template_id: selectedTemplateId,
                    template_name: templateName,
                    language: templateLanguage,
                    template: template,
                    components: datacomponent,
                    user_id: userData?.userData[0]?.user_id,
                }),
            });
            if (response.ok) {
                message.success('Campaign send successfully');
                fetchCustomerData();
                onCloseRetargatDrawer();
                retargetform.resetFields();
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error while sending Campaign:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNewUserSubmit = async () => {
        
        const template = _.cloneDeep(selectedTemplate);
        const templateName = template.name;
        const templateLanguage = template.language;
        const group_id = selectedGroupId;
        const variables = selectedValues;
        const imageId = templateImageId;
        const imageExtension = templateImageExtension;
        console.log(template);
        let datacomponent = [];
        template?.components?.forEach(component => {
            if (component.type === "HEADER") {
                const header = {
                    type: "header",
                    parameters: []
                };

                if (component.format === 'TEXT') {
                    let replacedHeader = component.text;
                    component.text.match(/{{\d+}}/g).forEach(element => {
                        const replacement = variables[`HEADER-${element}`] === 'Name' ? 'customer_name' : variables[`HEADER-${element}`] === 'Email' ? 'customer_email' : variables[`HEADER-${element}`] === 'Phone' ? 'customer_phone' : variables[`HEADER-${element}`];
                        replacedHeader = replacedHeader.replace(element, replacement);
                        header.parameters.push({
                            type: "text",
                            text: variables[`HEADER-${element}`] === 'Name' ? 'customer_name' : variables[`HEADER-${element}`] === 'Email' ? 'customer_email' : variables[`HEADER-${element}`] === 'Phone' ? 'customer_phone' : variables[`HEADER-${element}`]
                        });
                    });
                    component.text = replacedHeader;
                }

                if (component.format === 'IMAGE') {
                    if (imageExtension === 'image/png') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.png`;
                    }
                    if (imageExtension === 'image/jpeg') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.jpeg`;
                    }
                    header.parameters.push({
                        type: "image",
                        image: {
                            id: imageId
                        }
                    });
                }

                if (header.parameters.length > 0) {
                    datacomponent.push(header);
                }
            }

            if (component.type === "BODY") {
                const body = {
                    type: "body",
                    parameters: []
                };
                let replacedBody = component.text;
                if (component.text.match(/{{\d+}}/g)) {
                    component.text.match(/{{\d+}}/g).forEach(element => {
                        const replacement = variables[`BODY-${element}`] === 'Name' ? 'customer_name' : variables[`BODY-${element}`] === 'Email' ? 'customer_email' : variables[`BODY-${element}`] === 'Phone' ? 'customer_phone' : variables[`BODY-${element}`];
                        replacedBody = replacedBody.replace(element, replacement);
                        body.parameters.push({
                            type: "text",
                            text: variables[`BODY-${element}`] === 'Name' ? 'customer_name' : variables[`BODY-${element}`] === 'Email' ? 'customer_email' : variables[`BODY-${element}`] === 'Phone' ? 'customer_phone' : variables[`BODY-${element}`]
                        });
                    });
                }
                component.text = replacedBody;
                if (body.parameters.length > 0) {
                    datacomponent.push(body);
                }
            }
        });
        try {
            setLoading(true);
            const formData = await form.validateFields();
            const response = await fetch('https://connectby.io:3001/api/send-waba-campaign-two', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'phonenumberid': userData?.userData[0]?.phonenumberid
                },
                body: JSON.stringify({
                    tag_id: JSON.stringify(formData.tag_id),
                    campaign_name: formData.campaign_name,
                    template_id: selectedTemplateId,
                    template_name: templateName,
                    language: templateLanguage,
                    template: template,
                    components: datacomponent,
                    reschdule: isSchduleEnabled,
                    user_id: userData?.userData[0]?.user_id,
                    scheduling: formData?.scheduling,
                    schedule_datetime: formData?.schedule_datetime || '',
                }),
            });
            if (response.ok) {
                message.success('Campaign send successfully');
                fetchCustomerData();
                onCloseNewUser();
                form.resetFields();
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error while sending Campaign:', error);
        } finally {
            setLoading(false);
        }
    };

    const truncateNotes = (notes) => {
        if (notes.length > 25) {
            return notes.substring(0, 25) + '...';
        }
        return notes;
    };

    const handleReplaceText = (text) => {
        if (text) {
            text = text.replace(/\*([^*]+)\*/g, "<b>$1</b>");
            text = text.replace(/_([^_]+)_/g, "<i>$1</i>");
        }
        return text;
    };

    const onVarChange = (element, value) => {
        setSelectedValues(prevValues => ({
            ...prevValues,
            [element]: value,
        }));
    };

    const onVarSearch = (value) => {
        console.log('search:', value);
    };

    const checkFile = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPEG/PNG file!');
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt5M;
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handleChange = (info) => {
        console.log(info);
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        console.log(info.file.status);
        if (info.file.status === 'done') {
            getBase64One(info.file.originFileObj, (url) => {
                setTemplateImage(url);
                setLoading(false);
            });
        }
    };

    const customRequestImage = () => {
        return async ({ action, data, file, filename, headers, onError, onProgress, onSuccess }) => {
            try {
                const formData = new FormData();
                formData.append('file', file);
                const fileType = file.type;
                console.log(fileType);
                const response = await fetch(action, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        ...headers,
                        'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                        'phonenumberid': userData?.userData[0]?.phonenumberid
                    },
                });
                const responseData = await response.json();
                setTemplateImageId(responseData.id);
                setTemplateImageExtension(fileType);
                onSuccess(responseData, file);
            } catch (error) {
                onError(error);
                console.log(error);
            }
        };
    };

    const customRequest = ({ file, onSuccess, onError, onProgress }) => {
        setFilesToUpload(prevFiles => [...prevFiles, file]);
        if (!previewVisible) {
            setPreviewVisible(true);
        }
        setImageUploadLoading(true);
        const maxRetries = 3;
        let retryCount = 0;
        const uploadWithRetry = () => {
            setTimeout(() => {
                const isUploaded = Math.random() > 0.5;

                if (isUploaded) {
                    onSuccess();
                    handlePreview(file);
                    setImageUploadLoading(false);
                } else {
                    retryCount++;
                    if (retryCount <= maxRetries) {
                        uploadWithRetry();
                    } else {
                        onError();
                        message.error(`${file.name} file upload failed after ${maxRetries} retries`);
                        setImageUploadLoading(false);
                    }
                }
            }, 1000);
        };

        uploadWithRetry();
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            } else {
                reject(new Error('Invalid file type. Expected a Blob.'));
            }
        });
    const searchTetm = '';
    const userdataUrl = `https://connectby.io:3001/api/users/?customerId=${userData?.userData[0]?.user_id}&phoneNoId=${userData?.userData[0]?.phonenumberid}&result=20&page=${pageNumber}&searchTerm=${searchTetm}`;
    const appendData = () => {
        fetch(userdataUrl)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();

            })
            .then((body) => {
                const newPaginatedData = body || [];
                console.log(newPaginatedData);
                if (newPaginatedData.length < 20) {
                    setHasMoreData(false);
                }
                setData((prevData) => [...prevData, ...newPaginatedData]);
                setPageNumber((prevPageNumber) => prevPageNumber + 1);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handlePreview = async (file) => {
        console.log(file.type);
        if (!file.url && !file.preview) {
            try {
                const isImage = file.type.startsWith('image/');
                const isVideo = file.type.startsWith('video/');
                const isText = file.type === 'text/plain';
                const isPDF = file.type === 'application/pdf';
                const isPPT = file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                const isExcel = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (isImage) {
                    // Generate a preview URL using FileReader
                    const preview = await getBase64(file);
                    setPreviewImage(preview);
                    // setUploadFileList((prevImageList) => [
                    //     ...prevImageList,
                    //     { uid: file.uid, url: preview },
                    // ]);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: preview },
                    ]);
                } else if (isVideo) {
                    const preview = await getBase64(file);
                    setPreviewImage(preview);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: preview, filetype: 'image' },
                    ]);
                } else if (isText) {
                    setPreviewImage(textIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: textIcon, filetype: 'icon' },
                    ]);
                } else if (isPDF) {
                    setPreviewImage(pdfIcon);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: pdfIcon, filetype: 'icon' },
                    ]);
                } else if (isPPT) {
                    setPreviewImage(pptIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: pptIcon, filetype: 'icon' },
                    ]);
                } else if (isWord) {
                    setPreviewImage(wordIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: wordIcon, filetype: 'icon' },
                    ]);
                } else if (isExcel) {
                    setPreviewImage(excelIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: excelIcon, filetype: 'icon' },
                    ]);
                } else {
                    setPreviewImage(fileIcon);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: fileIcon, filetype: 'icon' },
                    ]);
                }
                setActiveImageUid(file.uid);
            } catch (error) {
                console.error('Error getting base64:', error);
                setPreviewImage('');
            }
        }

        setPreviewVisible(true);
    };

    const handleCancel = () => setPreviewVisible(false);

    const inputfilterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Row>
            <Col span={24} style={{ display: 'none' }}>
                <b>Note:</b> As per the whatsapp guidence you can send 200 message per hour.
                <br /><br />
            </Col>
            <Col span={6}>
                <Search placeholder="Search campaign by name" onChange={(e) => handleSearch(e.target.value)} style={{ marginBottom: '16px' }} />
            </Col>
            <Col span={18}>
                <Flex gap="small" justify='end'>
                    <Button type='primary' onClick={handleAddNewGroup}><NotificationOutlined /> Add New Campaign</Button>
                </Flex>
            </Col>
            <Col span={24}>
                {isLoading ? (
                    <Skeleton active />
                ) : (
                    <Table
                        pagination={{
                            total: totalItems,
                            current: pageNumber,
                            pageSize: pageSize,
                            onChange: (page, pageSize) => {
                                setPageNumber(page);
                                setPageSize(pageSize);
                            },
                            showTotal: (total, range) => (
                                <span className="totaldata">Showing {range[0]} to {range[1]} of {total} entries</span>
                            )
                        }}
                        onRow={(record) => ({
                            onClick: () => navigate('/viewcampaigndetails', { state: { campaign_id: record.campaign_id } }),
                        })}
                        style={{ cursor: 'pointer' }}
                        columns={columns}
                        dataSource={filteredData.map((item, index) => ({ ...item, key: index }))}
                        onChange={handleTableChange}
                    />
                )}
            </Col>
            <Drawer
                title={<><NotificationOutlined /> Add New Campaign</>}
                width={720}
                onClose={onCloseNewUser}
                open={openNewUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseNewUser}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleNewUserSubmit} type="primary">
                                    <span>Save and send</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={form}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="campaign_name"
                                label="Campaign Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter campaign name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter campaign name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Enable/Disable Re-attempt of failed messages">
                                <Radio.Group onChange={handleRadioChange} defaultValue={userData?.userData[0]?.schduleenable === '1' ? 'enable' : 'disable'}>
                                    <Radio value="enable">Enable</Radio>
                                    <Radio value="disable">Disable</Radio>
                                </Radio.Group>
                            </Form.Item>
                            (<b>Note: </b>By enabling this re-attempt of failed messages will occur after 24 hours.)
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="tag_id"
                                label="Tags"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select tags',
                                    },
                                ]}
                            >
                                <Select mode="multiple" placeholder="Select tags" style={{ width: '100%' }}>
                                    {tagsData.map(tags => (
                                        <Option key={tags.tag_id} value={tags.tag_id}>{tags.tag_name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="template_id"
                                label="Template"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Template',
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue="Select a template"
                                    style={{ width: '100%' }}
                                    onChange={handleTemplateSelect}
                                    value={selectedTemplateId}
                                >
                                    {templatedata.map(template => (
                                        template.status === 'APPROVED' && (
                                            <Option key={template.id} value={template.id}>{`${template.name} (${template.language})`}</Option>
                                        )
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            {selectedTemplate && selectedTemplate.components && selectedTemplate.components.length > 0 && (
                                selectedTemplate.components.map((component, index) => (
                                    <React.Fragment key={component.type}>
                                        {component.type === "HEADER" && (
                                            <div>
                                                <h4>Header</h4>
                                                {component.format === 'TEXT' && component.text.match(/{{\d+}}/g) ? (
                                                    component.text.match(/{{\d+}}/g).map(element => (
                                                        <Row key={`H${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                            <Col span={12} style={{ paddingRight: '6px' }}>
                                                                <Select
                                                                    showSearch
                                                                    placeholder="Select Predefined Options"
                                                                    optionFilterProp="children"
                                                                    onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                    onSearch={onVarSearch}
                                                                    filterOption={inputfilterOption}
                                                                    options={filterOption}
                                                                    style={{ width: '100%' }}
                                                                    value={selectedValues[`H${component.type}-${element}`]}
                                                                />
                                                            </Col>
                                                            <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                <Input
                                                                    placeholder={`Enter Text for variable ${element}`}
                                                                    prefix={element}
                                                                    value={selectedValues[`${component.type}-${element}`]}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    ))
                                                ) : component.format === 'IMAGE' ? (
                                                    <Row>
                                                        <Col span={6} style={{ paddingRight: '6px', maxHeight: '100px', overflow: 'hidden' }}>
                                                            <div className='previewContent'><img alt="Header" src={(templateImage !== '') ? (templateImage) : (component.example.header_handle[0])} style={{ height: '100px', width: 'auto' }} /></div>
                                                        </Col>
                                                        <Col span={18} style={{ paddingLeft: '6px' }}>
                                                            <Upload
                                                                accept=".jpeg,.png"
                                                                listType="picture-card"
                                                                className="avatar-uploader"
                                                                customRequest={(fileInfo) => customRequestImage()(fileInfo)}
                                                                showUploadList={false}
                                                                action="https://connectby.io:3001/api/upload-temp-media/"
                                                                beforeUpload={checkFile}
                                                                onChange={(info) => handleChange(info)}
                                                            >
                                                                {uploadButton}
                                                            </Upload>
                                                        </Col>

                                                    </Row>
                                                ) : component.format === 'VIDEO' ? (
                                                    <Row>
                                                        <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                            <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                        </Col>
                                                        <Col span={18}>
                                                            <Upload
                                                                fileList={fileList}
                                                                customRequest={customRequest}
                                                                showUploadList={false}
                                                                beforeUpload={checkFile}
                                                                accept=".mp4"
                                                                onChange={({ fileList }) => setFileList(fileList)}
                                                            >
                                                                <Button className='addMoreFile'>
                                                                    <PlusOutlined />
                                                                </Button>
                                                            </Upload>
                                                        </Col>
                                                    </Row>
                                                ) : component.format === 'DOCUMENT' ? (
                                                    <Row>
                                                        <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                            <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                        </Col>
                                                        <Col span={18}>
                                                            <Upload
                                                                fileList={fileList}
                                                                customRequest={customRequest}
                                                                showUploadList={false}
                                                                beforeUpload={checkFile}
                                                                accept=".pdf"
                                                                onChange={({ fileList }) => setFileList(fileList)}
                                                            >
                                                                <Button className='addMoreFile'>
                                                                    <PlusOutlined />
                                                                </Button>
                                                            </Upload>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        )}
                                        {component.type === "BODY" && (
                                            component.text.match(/{{\d+}}/g) && component.text.match(/{{\d+}}/g).length > 0 ? (
                                                <div>
                                                    <h4>Body</h4>
                                                    {component.text.match(/{{\d+}}/g).map(element => (
                                                        <>
                                                            <Row key={`${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                                <Col span={12} style={{ paddingRight: '6px' }}>
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Select Predefined Options"
                                                                        optionFilterProp="children"
                                                                        onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                        onSearch={onVarSearch}
                                                                        filterOption={inputfilterOption}
                                                                        options={filterOption}
                                                                        style={{ width: '100%' }}
                                                                        value={selectedValues[`${component.type}-${element}`]}
                                                                    />
                                                                </Col>
                                                                <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                    <Input
                                                                        placeholder={`Enter Text for variable ${element}`}
                                                                        prefix={element}
                                                                        value={selectedValues[`${component.type}-${element}`]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ))}
                                                </div>
                                            ) : (
                                                ''
                                            )
                                        )}
                                    </React.Fragment>
                                ))
                            )}
                        </Col>
                        <Col span={12}>
                            {selectedTemplate && selectedTemplate.components && selectedTemplate.components.length > 0 && (
                                <div style={{ backgroundColor: '#e3ffcb', borderRadius: 5, padding: 15 }}>
                                    {selectedTemplate.components.map((component, index) => (
                                        <React.Fragment key={index}>
                                            {component.type === "HEADER" && (
                                                component.format === 'TEXT' ? (
                                                    <p className='headeretxt'>{component.text}</p>
                                                ) : component.format === 'IMAGE' ? (
                                                    <div className='previewContent'><img alt="Header" src={templateImage !== '' ? templateImage : component.example.header_handle[0]} style={{ height: 'auto', width: '100%' }} /></div>
                                                ) : component.format === 'VIDEO' ? (
                                                    <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                ) : component.format === 'DOCUMENT' ? (
                                                    <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                ) : (
                                                    <p>Unsupported format</p>
                                                )
                                            )}
                                            {component.type === "BODY" && (
                                                <div className='bodytext' dangerouslySetInnerHTML={{ __html: handleReplaceText(component.text) }}></div>
                                            )}
                                            {component.type === "FOOTER" && (
                                                <div className='footertext'>{component.text}</div>
                                            )}
                                            {component.type === "BUTTONS" && (
                                                <React.Fragment>
                                                    <hr className='buttonhr' />
                                                    {component.buttons.map((button, btnIndex) => (
                                                        <div key={btnIndex}>
                                                            {button.type === 'QUICK_REPLY' && (
                                                                <div className='quickreply'>
                                                                    <a target="_blank" href="javascript:void(0)" rel="noreferrer">{button.text}</a>
                                                                </div>
                                                            )}
                                                            {button.type === 'URL' && (
                                                                <div className='urlbtn'>
                                                                    <a target="_blank" href={button.url} rel="noreferrer">
                                                                        <LinkOutlined />
                                                                        {button.text}
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {button.type === 'PHONE_NUMBER' && (
                                                                <div className='phonebtn'>
                                                                    <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                        <PhoneOutlined />
                                                                        {button.text}
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}

                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="scheduling" label="Scheduling">
                                <Radio.Group onChange={handleSchedule} defaultValue='now'>
                                    <Radio value="now">Immediately</Radio>
                                    <Radio value="schedule">Custom date</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {scheduling === 'schedule' && (
                            <Col span={12}>
                                <Form.Item
                                    name="schedule_datetime"
                                    label="Schedule Date & Time"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a schedule date and time',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        showTime={{ format: 'HH:mm' }}
                                        format="YYYY-MM-DD HH:mm"
                                        style={{ width: '100%' }}
                                        disabledDate={current => current && current < dayjs().startOf('day')}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </Drawer>
            <Drawer
                title={<><NotificationOutlined /> Retarget Campaign</>}
                width={720}
                onClose={onCloseRetargatDrawer}
                open={openRetargatDrawer}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseRetargatDrawer}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleRetargateSubmit} type="primary">
                                    <span>Save and send</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={retargetform}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="campaign_name"
                                label="Campaign Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter campaign name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter campaign name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {Object.keys(selectedCampaign).length > 0 && (
                            <Col span={12}>
                                <Form.Item
                                    name="selected_campaign_id"
                                    label="previous Campaign"
                                >
                                    <Input placeholder="Please enter campaign name" value={selectedCampaign?.campaign_name} defaultValue={selectedCampaign?.campaign_name} readOnly />
                                </Form.Item>
                                <Form.Item
                                    name="selected_status"
                                    label="Target Customer Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Target Customer Type',
                                        },
                                    ]}>
                                    <Checkbox.Group>
                                        <Row>
                                            <Col span={5}>
                                                <Checkbox value="sent">Sent</Checkbox>
                                            </Col>
                                            <Col span={7}>
                                                <Checkbox value="delivered">Delivered</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="read">Read</Checkbox>
                                            </Col>
                                            <Col span={5}>
                                                <Checkbox value="replied">Replied</Checkbox>
                                            </Col>
                                            <Col span={3}>
                                                <Checkbox value="failed">Failed</Checkbox>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item
                                name="template_id"
                                label="Template"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Template',
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue="Select a template"
                                    style={{ width: '100%' }}
                                    onChange={handleTemplateSelect}
                                    value={selectedTemplateId}
                                >
                                    {templatedata.map(template => (
                                        template.status === 'APPROVED' && (
                                            <Option key={template.id} value={template.id}>{`${template.name} (${template.language})`}</Option>
                                        )
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            {selectedTemplate && selectedTemplate.components && selectedTemplate.components.length > 0 && (
                                selectedTemplate.components.map((component, index) => (
                                    <React.Fragment key={component.type}>
                                        {component.type === "HEADER" && (
                                            <div>
                                                <h4>Header</h4>
                                                {component.format === 'TEXT' && component.text.match(/{{\d+}}/g) ? (
                                                    component.text.match(/{{\d+}}/g).map(element => (
                                                        <Row key={`H${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                            <Col span={12} style={{ paddingRight: '6px' }}>
                                                                <Select
                                                                    showSearch
                                                                    placeholder="Select Predefined Options"
                                                                    optionFilterProp="children"
                                                                    onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                    onSearch={onVarSearch}
                                                                    filterOption={inputfilterOption}
                                                                    options={filterOption}
                                                                    style={{ width: '100%' }}
                                                                    value={selectedValues[`H${component.type}-${element}`]}
                                                                />
                                                            </Col>
                                                            <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                <Input
                                                                    placeholder={`Enter Text for variable ${element}`}
                                                                    prefix={element}
                                                                    value={selectedValues[`${component.type}-${element}`]}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    ))
                                                ) : component.format === 'IMAGE' ? (
                                                    <Row>
                                                        <Col span={6} style={{ paddingRight: '6px', maxHeight: '100px', overflow: 'hidden' }}>
                                                            <div className='previewContent'><img alt="Header" src={(templateImage !== '') ? (templateImage) : (component.example.header_handle[0])} style={{ height: '100px', width: 'auto' }} /></div>
                                                        </Col>
                                                        <Col span={18} style={{ paddingLeft: '6px' }}>
                                                            <Upload
                                                                accept=".jpeg,.png"
                                                                listType="picture-card"
                                                                className="avatar-uploader"
                                                                customRequest={(fileInfo) => customRequestImage()(fileInfo)}
                                                                showUploadList={false}
                                                                action="https://connectby.io:3001/api/upload-temp-media/"
                                                                beforeUpload={checkFile}
                                                                onChange={(info) => handleChange(info)}
                                                            >
                                                                {uploadButton}
                                                            </Upload>
                                                        </Col>

                                                    </Row>
                                                ) : component.format === 'VIDEO' ? (
                                                    <Row>
                                                        <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                            <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                        </Col>
                                                        <Col span={18}>
                                                            <Upload
                                                                fileList={fileList}
                                                                customRequest={customRequest}
                                                                showUploadList={false}
                                                                beforeUpload={checkFile}
                                                                accept=".mp4"
                                                                onChange={({ fileList }) => setFileList(fileList)}
                                                            >
                                                                <Button className='addMoreFile'>
                                                                    <PlusOutlined />
                                                                </Button>
                                                            </Upload>
                                                        </Col>
                                                    </Row>
                                                ) : component.format === 'DOCUMENT' ? (
                                                    <Row>
                                                        <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                            <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                        </Col>
                                                        <Col span={18}>
                                                            <Upload
                                                                fileList={fileList}
                                                                customRequest={customRequest}
                                                                showUploadList={false}
                                                                beforeUpload={checkFile}
                                                                accept=".pdf"
                                                                onChange={({ fileList }) => setFileList(fileList)}
                                                            >
                                                                <Button className='addMoreFile'>
                                                                    <PlusOutlined />
                                                                </Button>
                                                            </Upload>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        )}
                                        {component.type === "BODY" && (
                                            component.text.match(/{{\d+}}/g) && component.text.match(/{{\d+}}/g).length > 0 ? (
                                                <div>
                                                    <h4>Body</h4>
                                                    {component.text.match(/{{\d+}}/g).map(element => (
                                                        <>
                                                            <Row key={`${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                                <Col span={12} style={{ paddingRight: '6px' }}>
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Select Predefined Options"
                                                                        optionFilterProp="children"
                                                                        onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                        onSearch={onVarSearch}
                                                                        filterOption={inputfilterOption}
                                                                        options={filterOption}
                                                                        style={{ width: '100%' }}
                                                                        value={selectedValues[`${component.type}-${element}`]}
                                                                    />
                                                                </Col>
                                                                <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                    <Input
                                                                        placeholder={`Enter Text for variable ${element}`}
                                                                        prefix={element}
                                                                        value={selectedValues[`${component.type}-${element}`]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ))}
                                                </div>
                                            ) : (
                                                ''
                                            )
                                        )}
                                    </React.Fragment>
                                ))
                            )}
                        </Col>
                        <Col span={12}>
                            {selectedTemplate && selectedTemplate.components && selectedTemplate.components.length > 0 && (
                                <div style={{ backgroundColor: '#e3ffcb', borderRadius: 5, padding: 15 }}>
                                    {selectedTemplate.components.map((component, index) => (
                                        <React.Fragment key={index}>
                                            {component.type === "HEADER" && (
                                                component.format === 'TEXT' ? (
                                                    <p className='headeretxt'>{component.text}</p>
                                                ) : component.format === 'IMAGE' ? (
                                                    <div className='previewContent'><img alt="Header" src={templateImage !== '' ? templateImage : component.example.header_handle[0]} style={{ height: 'auto', width: '100%' }} /></div>
                                                ) : component.format === 'VIDEO' ? (
                                                    <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                ) : component.format === 'DOCUMENT' ? (
                                                    <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                ) : (
                                                    <p>Unsupported format</p>
                                                )
                                            )}
                                            {component.type === "BODY" && (
                                                <div className='bodytext' dangerouslySetInnerHTML={{ __html: handleReplaceText(component.text) }}></div>
                                            )}
                                            {component.type === "FOOTER" && (
                                                <div className='footertext'>{component.text}</div>
                                            )}
                                            {component.type === "BUTTONS" && (
                                                <React.Fragment>
                                                    <hr className='buttonhr' />
                                                    {component.buttons.map((button, btnIndex) => (
                                                        <div key={btnIndex}>
                                                            {button.type === 'QUICK_REPLY' && (
                                                                <div className='quickreply'>
                                                                    <a target="_blank" href="javascript:void(0)" rel="noreferrer">{button.text}</a>
                                                                </div>
                                                            )}
                                                            {button.type === 'URL' && (
                                                                <div className='urlbtn'>
                                                                    <a target="_blank" href={button.url} rel="noreferrer">
                                                                        <LinkOutlined />
                                                                        {button.text}
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {button.type === 'PHONE_NUMBER' && (
                                                                <div className='phonebtn'>
                                                                    <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                        <PhoneOutlined />
                                                                        {button.text}
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}

                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <Drawer
                title={<><UserAddOutlined /> Modify or Resend Campaign</>}
                width={720}
                onClose={onCloseEditUser}
                open={openEditUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseEditUser}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleUpdateUserSubmit} type="primary">
                                    <span>Submit</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={editform} >
                    <Form.Item name="campaign_id" hidden>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="campaign_name"
                                label="Campaign Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter campaign name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter campaign name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="tag_id"
                                label="Group"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Group',
                                    },
                                ]}
                            >
                                <Select mode="multiple" placeholder="Select tags" style={{ width: '100%' }}>
                                    {tagsData.map(tags => (
                                        <Option key={tags.tag_id} value={tags.tag_id}>{tags.tag_name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="template_id"
                                label="Template"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Template',
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue="Select a template"
                                    style={{ width: '100%' }}
                                    onChange={handleTemplateSelect}
                                    value={selectedTemplateId}
                                >
                                    {templatedata.map(template => (
                                        template.status === 'APPROVED' && (
                                            <Option key={template.id} value={template.id}>{`${template.name} (${template.language})`}</Option>
                                        )
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            {selectedTemplate && selectedTemplate.components && selectedTemplate.components.length > 0 && (
                                selectedTemplate.components.map((component, index) => (
                                    <React.Fragment key={component.type}>
                                        {component.type === "HEADER" && (
                                            <div>
                                                <h4>Header</h4>
                                                {component.format === 'TEXT' && component.text.match(/{{\d+}}/g) ? (
                                                    component.text.match(/{{\d+}}/g).map(element => (
                                                        <Row key={`H${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                            <Col span={12} style={{ paddingRight: '6px' }}>
                                                                <Select
                                                                    showSearch
                                                                    placeholder="Select Predefined Options"
                                                                    optionFilterProp="children"
                                                                    onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                    onSearch={onVarSearch}
                                                                    filterOption={inputfilterOption}
                                                                    options={filterOption}
                                                                    style={{ width: '100%' }}
                                                                    value={selectedValues[`H${component.type}-${element}`]}
                                                                />
                                                            </Col>
                                                            <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                <Input
                                                                    placeholder={`Enter Text for variable ${element}`}
                                                                    prefix={element}
                                                                    value={selectedValues[`${component.type}-${element}`]}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    ))
                                                ) : component.format === 'IMAGE' ? (
                                                    <Row>
                                                        <Col span={6} style={{ paddingRight: '6px', maxHeight: '100px', overflow: 'hidden' }}>
                                                            <div className='previewContent'><img alt="Header" src={(templateImage !== '') ? (templateImage) : (component.example.header_handle[0])} style={{ height: '100px', width: 'auto' }} /></div>
                                                        </Col>
                                                        <Col span={18} style={{ paddingLeft: '6px' }}>
                                                            <Upload
                                                                accept=".jpeg,.png"
                                                                listType="picture-card"
                                                                className="avatar-uploader"
                                                                customRequest={(fileInfo) => customRequestImage()(fileInfo)}
                                                                showUploadList={false}
                                                                action="https://connectby.io:3001/api/upload-temp-media/"
                                                                beforeUpload={checkFile}
                                                                onChange={(info) => handleChange(info)}
                                                            >
                                                                {uploadButton}
                                                            </Upload>
                                                        </Col>

                                                    </Row>
                                                ) : component.format === 'VIDEO' ? (
                                                    <Row>
                                                        <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                            <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                        </Col>
                                                        <Col span={18}>
                                                            <Upload
                                                                fileList={fileList}
                                                                customRequest={customRequest}
                                                                showUploadList={false}
                                                                beforeUpload={checkFile}
                                                                accept=".mp4"
                                                                onChange={({ fileList }) => setFileList(fileList)}
                                                            >
                                                                <Button className='addMoreFile'>
                                                                    <PlusOutlined />
                                                                </Button>
                                                            </Upload>
                                                        </Col>
                                                    </Row>
                                                ) : component.format === 'DOCUMENT' ? (
                                                    <Row>
                                                        <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                            <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                        </Col>
                                                        <Col span={18}>
                                                            <Upload
                                                                fileList={fileList}
                                                                customRequest={customRequest}
                                                                showUploadList={false}
                                                                beforeUpload={checkFile}
                                                                accept=".pdf"
                                                                onChange={({ fileList }) => setFileList(fileList)}
                                                            >
                                                                <Button className='addMoreFile'>
                                                                    <PlusOutlined />
                                                                </Button>
                                                            </Upload>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        )}
                                        {component.type === "BODY" && (
                                            component.text.match(/{{\d+}}/g) && component.text.match(/{{\d+}}/g).length > 0 ? (
                                                <div>
                                                    <h4>Body</h4>
                                                    {component.text.match(/{{\d+}}/g).map(element => (
                                                        <>
                                                            <Row key={`${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                                <Col span={12} style={{ paddingRight: '6px' }}>
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Select Predefined Options"
                                                                        optionFilterProp="children"
                                                                        onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                        onSearch={onVarSearch}
                                                                        filterOption={inputfilterOption}
                                                                        options={filterOption}
                                                                        style={{ width: '100%' }}
                                                                        value={selectedValues[`${component.type}-${element}`]}
                                                                    />
                                                                </Col>
                                                                <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                    <Input
                                                                        placeholder={`Enter Text for variable ${element}`}
                                                                        prefix={element}
                                                                        value={selectedValues[`${component.type}-${element}`]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ))}
                                                </div>
                                            ) : (
                                                ''
                                            )
                                        )}
                                    </React.Fragment>
                                ))
                            )}
                        </Col>
                        <Col span={12}>
                            {selectedTemplate && selectedTemplate.components && selectedTemplate.components.length > 0 && (
                                <div style={{ backgroundColor: '#e3ffcb', borderRadius: 5, padding: 15 }}>
                                    {selectedTemplate.components.map((component, index) => (
                                        <React.Fragment key={index}>
                                            {component.type === "HEADER" && (
                                                component.format === 'TEXT' ? (
                                                    <p className='headeretxt'>{component.text}</p>
                                                ) : component.format === 'IMAGE' ? (
                                                    <div className='previewContent'><img alt="Header" src={templateImage !== '' ? templateImage : component.example.header_handle[0]} style={{ height: 'auto', width: '100%' }} /></div>
                                                ) : component.format === 'VIDEO' ? (
                                                    <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                ) : component.format === 'DOCUMENT' ? (
                                                    <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                ) : (
                                                    <p>Unsupported format</p>
                                                )
                                            )}
                                            {component.type === "BODY" && (
                                                <div className='bodytext' dangerouslySetInnerHTML={{ __html: handleReplaceText(component.text) }}></div>
                                            )}
                                            {component.type === "FOOTER" && (
                                                <div className='footertext'>{component.text}</div>
                                            )}
                                            {component.type === "BUTTONS" && (
                                                <React.Fragment>
                                                    <hr className='buttonhr' />
                                                    {component.buttons.map((button, btnIndex) => (
                                                        <div key={btnIndex}>
                                                            {button.type === 'QUICK_REPLY' && (
                                                                <div className='quickreply'>
                                                                    <a target="_blank" href="javascript:void(0)" rel="noreferrer">{button.text}</a>
                                                                </div>
                                                            )}
                                                            {button.type === 'URL' && (
                                                                <div className='urlbtn'>
                                                                    <a target="_blank" href={button.url} rel="noreferrer">
                                                                        <LinkOutlined />
                                                                        {button.text}
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {button.type === 'PHONE_NUMBER' && (
                                                                <div className='phonebtn'>
                                                                    <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                        <PhoneOutlined />
                                                                        {button.text}
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}

                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </Row>
    );
}

export default Campaign;