import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, List, Input, Col, Row, Select, Typography, Layout } from 'antd';
import { SettingOutlined, LockOutlined, UsergroupAddOutlined, TagsOutlined, WhatsAppOutlined, MessageOutlined } from '@ant-design/icons';
import Permissions from './Permissions.js';
import Tags from './Tags.js';
import Team from './Team.js';
import WhatsappSettings from './WhatsappBusiness.js';
import Profile from './Profile.js';
import Quickreply from './Quickreply.js';

const Settings = (userData) => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = location.search.substring(1);

    const [activeMenu, setActiveMenu] = useState(query ? query : 'profile');

    const menudata = [
        {
            id: 'profile',
            name: 'General Settigs',
            icon: <SettingOutlined />
        },
        {
            id: 'quick_replies',
            name: 'Quick Replies',
            icon: <MessageOutlined />
        },
        {
            id: 'team',
            name: 'Team',
            icon: <UsergroupAddOutlined />
        },
        {
            id: 'tags',
            name: 'Tags',
            icon: <TagsOutlined />
        },
        {
            id: 'access-permissions',
            name: 'Access Permissions',
            icon: <LockOutlined />
        },
        {
            id: 'whatsapp-profile',
            name: 'WhatsApp Profile',
            icon: <WhatsAppOutlined />
        }
    ];
    
    return (
        <Row>
            <Col span={4}>
                <div className='flowmenudiv' style={{ position: 'sticky', top: 0 }}>
                    <List
                        size="large"
                        header={<h2 style={{ margin: 0 }}>Settings</h2>}
                        dataSource={menudata}
                        className='flowmenulist'
                        renderItem={(item) => <List.Item className={`flowmenulistitem ${activeMenu === item.id && 'menuactive'}`} onClick={() => {setActiveMenu(item.id); navigate(`?${item.id}`)}}>{item.icon} {item.name}</List.Item>}
                    />
                </div>
            </Col>
            <Col span={20}>
                <div style={{ marginLeft: 15 }}>
                    {activeMenu === 'profile' ?(
                        <Profile userData={userData?.userData} setUserData={userData?.setUserData} teamData={userData?.teamData} />
                    ): activeMenu === 'team' ? (
                        <Team userData={userData?.userData} teamData={userData?.teamData} />
                    ): activeMenu === 'quick_replies' ? (
                        <Quickreply userData={userData?.userData} teamData={userData?.teamData} />
                    ): activeMenu === 'tags' ? (
                        <Tags userData={userData?.userData} teamData={userData?.teamData} />
                    ):activeMenu === 'access-permissions' ? (
                        <Permissions userData={userData?.userData} teamData={userData?.teamData} />
                    ):activeMenu === 'whatsapp-profile' && (
                        <WhatsappSettings userData={userData?.userData} teamData={userData?.teamData} />
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default Settings;