import { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { AddOutlined } from '@mui/icons-material';
import { MessageOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Input, Upload, message } from 'antd';

const { TextArea } = Input;

function MediaButtonNode({ data }) {
    const [inputCount, setInputCount] = useState(0);

    const fileTypes = [
        { type: 'audio/mp3', extensions: ['.mp3', '.3gp'], maxSize: 16 * 1024 * 1024 }, // 16MB
        { type: 'image/jpeg,image/jpg,image/png', extensions: ['.jpeg', '.jpg', '.png'], maxSize: 5 * 1024 * 1024 }, // 5MB
        { type: 'application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint', extensions: ['.doc', '.pdf', '.xls', '.xlsx', '.docx', '.ppt'], maxSize: 100 * 1024 * 1024 }, // 100MB
        { type: 'video/mp4,video/3gpp', extensions: ['.mp4', '.3gp'], maxSize: 16 * 1024 * 1024 } // 16MB
    ];

    const props = {
        maxCount: 1,
        beforeUpload: (file) => {
            const fileType = fileTypes.find(ft => ft.extensions.some(ext => file.name.endsWith(ext)) || ft.type.split(',').some(type => file.type.startsWith(type)));
            if (!fileType) {
                message.error('Unsupported file format!');
                return false;
            }

            if (file.size > fileType.maxSize) {
                message.error(`File size must be less than ${fileType.maxSize / (1024 * 1024)}MB!`);
                return false;
            }

            return true;
        },
        onChange: (info) => {
            console.log(info.fileList);
        },
    };

    const handleAddButton = () => {
        if (inputCount < 3) {
            setInputCount(inputCount + 1);
        }
    };

    const onCloseIconClick = () => {
        if (typeof data.onClose === 'function') {
            data.onClose(data.id);
        }
    };

    const handleRemoveButton = (index) => {
        const newInputs = [...Array(inputCount).keys()].filter((i) => i !== index);
        setInputCount(newInputs.length);
    };

    const renderAdditionalInputs = () => {
        const inputs = [];
        for (let i = 0; i < inputCount; i++) {
            inputs.push(
                <div key={i} className="additionalInput">
                    <TextArea
                        maxLength={20}
                        rows={1}
                        placeholder={`Type Button Name ${i + 1}`}
                        count={{ show: true, max: 20 }}
                        onChange={(e) => data.onAdditionalTextAreaChange(data.id, i, e.target.value)}
                        className="nodrag"
                    />
                    <Button
                        className="removeBtn"
                        onClick={() => handleRemoveButton(i)}
                    >
                        <CloseOutlined />
                    </Button>
                    <Handle type="source" position={Position.Right} id={`${data.id}${i}`} />
                </div>
            );
        }
        return inputs;
    };

    return (
        <>
            <Handle type="target" position={Position.Top} />
            <div className='nodes'>
                <div className='header'>
                    <p><MessageOutlined /> {data.label}</p>
                    <CloseOutlined
                        className="closeIcon"
                        onClick={onCloseIconClick}
                    />
                </div>
                <div className='body'>
                    <div className='inputMainBox mediaInputBox'>
                        <Upload {...props} style={{width:'100%'}}>
                            <Button style={{width:'100%'}} icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                        {renderAdditionalInputs()}
                        <Button
                            className='addMoreBtn'
                            onClick={handleAddButton}
                        >
                            Add Button <AddOutlined />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MediaButtonNode;