import $ from "jquery";
import React, { Component, createRef, useEffect, useState } from "react";
import 'jquery-ui-sortable';
import { Row, Col } from "antd";
import { FormOutlined } from '@ant-design/icons';
import { redirect } from "react-router-dom";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const userData = 'userdata';
const insertFormJson = async () => {
  const whatsappaccesstoken = userData?.userData[0]?.whatsappaccesstoken;
  const catalogueid = userData?.userData[0]?.catalogueid;
  const formData = new FormData();
  try {
    const response = await fetch(`https://connectby.io:3001/api/formjson`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'whatsappaccesstoken': whatsappaccesstoken,
        'catalogueid': catalogueid
      },
      body: formData
    });
    const data = await response.json();
    if (data.success) {
      console.log(data);
      if(data.data.length !== 0){

      }else{
        console.log('No data found.');
      }
    } else {
      console.error('Failed to insert data:', data.error);
    }
  } catch (error) {
    console.error('Error inserting form JSON data', error);
  } finally {
    console.log('completed');
  }
};


class FormBuilder extends Component {
  fb = createRef();
  componentDidMount() {
    $(this.fb.current).formBuilder();
  }

  render() {
    return (
      <Row>
        <Col span={24}>
          <p><FormOutlined /> <span>Form Builder</span></p>
          <div id="fb-editor" ref={this.fb} />
        </Col>
      </Row>
    );
  }
}

export default FormBuilder;