import React, { useState, useEffect } from 'react';
import { Typography, Modal, Button, Card, Row, Col, Tooltip } from 'antd';
import helpimg from '../assets/images/helpimg.png';
import { InfoCircleOutlined, NotificationFilled } from '@ant-design/icons';
import { DoneAll, Send } from '@mui/icons-material';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { LineChart } from '@mui/x-charts/LineChart';
const { Text, Title } = Typography;
const style = {
  fontSize: '16px',
  fontWeight: 'bold',
};
const FacebookLoginButton = (userData) => {
  const [usAccessToken, setUsAccessToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [phoneNumberId, setPhoneNumberId] = useState('');
  const [waBaId, setWaBaId] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleOne, setModalVisibleOne] = useState(false);
  const [sData, setSData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [dData, setDData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [rData, setRData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [fData, setFData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [countUsers, setCountUsers] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [countActiveUsers, setCountActiveUsers] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [xLabels, setXLabels] = useState([0, 0, 0, 0, 0, 0, 0]);

  const [dashboardData, setDashboardData] = useState({
    sentCount: 0,
    readCount: 0,
    deliveredCount: 0,
    failedCount: 0
  });
  const showModal = (content) => {
    setModalVisible(true);
  };

  const showModalOne = () => {
    setModalVisibleOne(true);
  };

  const handleModalCloseOne = () => {
    setModalVisibleOne(false);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const series = [
    {
      type: 'bar',
      stack: '',
      yAxisKey: 'eco',
      data: sData,
    },
    {
      type: 'bar',
      stack: '',
      yAxisKey: 'eco',
      data: rData,
    },
    {
      type: 'bar',
      stack: '',
      yAxisKey: 'eco',
      data: dData,
    },
    {
      type: 'bar',
      stack: '',
      yAxisKey: 'eco',
      data: fData,
    },
  ];
  // const today = new Date();
  // const xLabels = [];

  // for (let i = 6; i >= 0; i--) {
  //   const date = new Date(today);
  //   date.setDate(today.getDate() - i);
  //   const dateString = `${date.getDate()} ${getMonthName(date.getMonth())}`;
  //   xLabels.push(dateString);
  // }

  // function getMonthName(monthIndex) {
  //   const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  //   return months[monthIndex];
  // }


  useEffect(() => {
    if (!userData) return;
    fetch(`https://connectby.io:3001/api/getdashborddetails?phone_number_id=${userData?.userData[0]?.phonenumberid}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.whatsapp_counts !== null) {
          setDashboardData({
            sentCount: data.whatsapp_counts.sent_count || 0,
            readCount: data.whatsapp_counts.read_count || 0,
            deliveredCount: data.whatsapp_counts.delivered_count || 0,
            failedCount: data.whatsapp_counts.failed_count || 0
          });
        }
      })
      .catch(error => console.error('Error fetching WhatsApp data:', error));

    fetch(`https://connectby.io:3001/api/getdashboardgraphdetails?phone_number_id=${userData?.userData[0]?.phonenumberid}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data && data.daily_counts.length !== 0) {
          const sdatas = [];
          const rdatas = [];
          const ddatas = [];
          const fdatas = [];
          const labels = [];

          data.daily_counts.forEach((counts) => {
            labels.push(counts.date);
            sdatas.push(counts.sent_count + counts.delivered_count + counts.read_count);
            rdatas.push(counts.read_count);
            ddatas.push(counts.delivered_count + counts.read_count);
            fdatas.push(counts.failed_count);
          });
          setXLabels(labels);
          setSData(sdatas);
          setRData(rdatas);
          setDData(ddatas);
          setFData(fdatas);
        }
      })
      .catch(error => console.error('Error fetching WhatsApp data:', error));
  }, [userData]);

  useEffect(() => {
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '715393607349643',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v18.0'
        });
        checkLoginStatus();
      };

      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };

    const fetchUserInfo = () => {
      window.FB.api('/me', { fields: 'id,name,email' }, function (response) {
        if (response && !response.error) {
          console.log(response);
          // setFbUserData(response);
        }
      });

      if (usAccessToken && phoneNumberId && waBaId) {
        const whatsappbody = {
          "messaging_product": "whatsapp",
          "pin": "560001"
        };

        fetch(`https://graph.facebook.com/v19.0/${phoneNumberId}/register`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${usAccessToken}`
          },
          body: JSON.stringify(whatsappbody),
        })
          .then(response => response.json())
          .then(data => {
            console.log('data:', data);
          })
          .catch(error => {
            console.error('Error fetching register phone no.:', error);
          });
        fetch(`https://graph.facebook.com/v19.0/${waBaId}/subscribed_apps`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${usAccessToken}`
          },
          body: JSON.stringify(whatsappbody),
        })
          .then(response => response.json())
          .then(data => {
            console.log('data:', data);
          })
          .catch(error => {
            console.error('Error fetching subscribed app:', error);
          });
        fetch(`https://connectby.io:3001/api/update-wadata`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: userData?.userData[0]?.user_id,
            whatsappAccessToken: usAccessToken,
            wabaid: waBaId,
            waappid: '715393607349643',
            phonenumberid: phoneNumberId,
          }),
        })
          .then(response => response.json())
          .then(data => {
            console.log('data:', data);
          })
          .catch(error => {
            console.error('Error updating data:', error);
          });
      }
    };

    const checkLoginStatus = () => {
      window.FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
          console.log(response);
          fetchUserInfo();
        } else {
          console.log("Not Login");
        }
      });
    };

    loadFacebookSDK();

    if (usAccessToken) {
      fetchUserInfo();
      checkLoginStatus();
    }
  }, [usAccessToken]);

  const handleLogout = () => {
    window.FB.logout(function (response) {
      console.log(response);
      setUsAccessToken(null);
    });
  };

  const launchWhatsAppSignup = () => {
    window.FB.login(function (response) {
      if (response.authResponse) {
        const accessToken = response.authResponse.code;
        fetch(`https://graph.facebook.com/v18.0/oauth/access_token?client_id=715393607349643&client_secret=26b1cd9490f42eeb01bd5170fbe6090f&code=${accessToken}`)
          .then(response => response.json())
          .then(data => {
            if (data.access_token) {
              const userAccessToken = data.access_token;
              setUsAccessToken(userAccessToken);
              console.log('User Access Token:', userAccessToken);
            } else {
              console.log('User Access Token is not valid.');
            }
          })
          .catch(error => {
            console.error('Error fetching user access token:', error);
          });
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: '2376733659194399',
      // config_id: '1674927826601874',
      scope: 'public_profile,email',
      return_scopes: true,
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        sessionInfoVersion: 2,
      }
    });
  };
  
  const sessionInfoListener = (event) => {
    if (event.origin !== "https://www.facebook.com") return;
    try {
      const data = JSON.parse(event.data);
      console.log(data);
      console.log(usAccessToken);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          console.log('Session finished. WABA ID:', waba_id);
          setWaBaId(waba_id);
          setPhoneNumberId(phone_number_id);
        } else {
          const { current_step } = data.data;
          console.log('Session cancelled. Current step:', current_step);
        }
      }
    } catch {
      console.log('Non JSON Response', event.data);
    }
  };
  window.addEventListener('message', sessionInfoListener);

  return (
    <div style={{
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      // height: '100vh',
      // textAlign: 'center'
    }}>
      <Card style={{ margin: 0, position: 'relative' }}>
        <Button style={{
          backgroundColor: 'transparent',
          border: 0,
          borderRadius: '100%',
          boxShadow: '0 0 0 rgba(5, 145, 255, 0.1)',
          color: '#aaa',
          cursor: 'pointer',
          fontSize: '16px',
          height: '30px',
          width: '30px',
          padding: '0 0',
          position: 'absolute',
          top: 5,
          right: 5
        }} onClick={() => showModal("Your modal content goes here")}><Tooltip title="Get Started With WhatsApp">
            <span><InfoCircleOutlined /></span>
          </Tooltip></Button>
        <Title style={{ padding: 0, marginTop: 0 }} level={3}>Hey, {userData && userData.teamData ? userData.teamData[0]?.team_name : userData.userData && userData.userData[0]?.user_name} Welcome to Connectby.io</Title>
        <button onClick={launchWhatsAppSignup}
          style={{
            backgroundColor: '#075E54',
            border: 0,
            borderRadius: '6px',
            boxShadow: '0 2px 0 rgba(5, 145, 255, 0.1)',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '16px',
            height: '40px',
            padding: '0 24px',
            position: 'absolute',
            bottom: 15,
            right: 15
          }}>
          Connect your WhatsApp Number
        </button>
        {userData && userData.userData && userData.userData[0].waappid !== "" ? (
          <button onClick={launchWhatsAppSignup}
            style={{
              backgroundColor: 'green',
              border: 0,
              borderRadius: '6px',
              boxShadow: '0 2px 0 rgba(5, 145, 255, 0.1)',
              color: '#fff',
              cursor: 'pointer',
              fontSize: '16px',
              height: '40px',
              padding: '0 24px',
              position: 'absolute',
              bottom: 15,
              right: 15,
              display: 'none'
            }}>
            Connected
          </button>
        ) : (
          <>
            <Text>Connect your WhatsApp business account with Connectby.io to send and receive messages from your WhatsApp business account</Text>
            <button onClick={launchWhatsAppSignup}
              style={{
                backgroundColor: '#075E54',
                border: 0,
                borderRadius: '6px',
                boxShadow: '0 2px 0 rgba(5, 145, 255, 0.1)',
                color: '#fff',
                cursor: 'pointer',
                fontSize: '16px',
                height: '40px',
                padding: '0 24px',
                position: 'absolute',
                bottom: 15,
                right: 15
              }}>
              Connect your WhatsApp Number
            </button>
          </>
        )}
      </Card>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
      <Col className="gutter-row" span={6}>
          <Card hoverable style={{ marginTop: 15, marginBottom: 15, position: 'relative' }}>
            <div style={{ color: '#60009B', fontSize: '20px', fontWeight: 'bold' }}>{dashboardData?.sentCount + dashboardData?.readCount + dashboardData?.deliveredCount + dashboardData?.failedCount}</div>
            <div style={style}>Attempted Messages</div>
            <div style={{ position: 'absolute', bottom: 15, right: 15 }}><NotificationFilled style={{ fontSize: '60px', color: '#ccc' }} /></div>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card hoverable style={{ marginTop: 15, marginBottom: 15, position: 'relative' }}>
            <div style={{ color: '#02B2AF', fontSize: '20px', fontWeight: 'bold' }}>{dashboardData?.sentCount + dashboardData?.readCount + dashboardData?.deliveredCount}</div>
            <div style={style}>Sent Messages</div>
            <div style={{ position: 'absolute', bottom: 15, right: 15 }}><Send style={{ fontSize: '60px', color: '#ccc' }} /></div>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card hoverable style={{ marginTop: 15, marginBottom: 15, position: 'relative' }}>
            <div style={{ color: '#2E96FF', fontSize: '20px', fontWeight: 'bold' }}>{dashboardData?.readCount + dashboardData?.deliveredCount}</div>
            <div style={style}>Delivered Messages</div>
            <div style={{ position: 'absolute', bottom: 15, right: 15 }}><DoneAll style={{ fontSize: '60px', color: '#ccc' }} /></div>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card hoverable style={{ marginTop: 15, marginBottom: 15, position: 'relative' }}>
            <div style={{ color: '#B800D8', fontSize: '20px', fontWeight: 'bold' }}>{dashboardData?.readCount}</div>
            <div style={style}>Read Messages</div>
            <div style={{ position: 'absolute', bottom: 15, right: 15 }}><DoneAll style={{ fontSize: '60px', color: 'blue' }} /></div>
          </Card>
        </Col>
      </Row>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col className="gutter-row" span={12}>
          <Card hoverable style={{ marginTop: 15, marginBottom: 15, position: 'relative' }}>
            <LineChart
              width={500}
              height={305}
              series={[
                { data: sData, label: 'Sent' },
                { data: dData, label: 'Delivered' },
                { data: rData, label: 'Read' }
              ]}
              xAxis={[{ scaleType: 'point', data: xLabels }]}
            />
          </Card>
        </Col>
        <Col className="gutter-row" span={12}>
          <Card hoverable style={{ marginTop: 15, marginBottom: 15, position: 'relative' }}>
            <ChartContainer
              series={series}
              width={500}
              height={300}
              xAxis={[
                {
                  id: 'dates',
                  data: xLabels,
                  scaleType: 'band',
                  valueFormatter: (value) => value.toString(),
                },
              ]}
              yAxis={[
                {
                  id: 'eco',
                  scaleType: 'linear',
                },
              ]}
            >
              <BarPlot />
              <LinePlot />
              <ChartsXAxis label="Dates" position="bottom" axisId="dates" />
              <ChartsYAxis label="Report" position="left" axisId="eco" />
            </ChartContainer>
          </Card>
        </Col>
      </Row>
      {/* {userData && userData.userData && userData.userData[0].waappid !== "" ? ( */}
      {/* < button onClick={handleLogout}
          style={{
            backgroundColor: '#1877f2',
            border: 0,
            borderRadius: '4px',
            color: '#fff',
            cursor: 'pointer',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontSize: '16px',
            fontWeight: 'bold',
            height: '40px',
            padding: '0 24px',
            marginTop: '20px'
          }}>
          Logout
        </button> */}
      {/* ) : ( */}
      <Modal
        title="Get Started with whatsApp"
        visible={modalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalClose} type="primary">Ok</Button>,
        ]}
        width={800}
      >
        <div>
          <p>
            <strong>Pre-requisite:</strong>
          </p>
          <ol>
            <li>
              <strong>WhatsApp Phone Number</strong> (Should not have a WhatsApp account)<br />
              If you want to delete the WhatsApp account with your number,{' '}
              <a href="#" target="_blank" rel="noopener noreferrer">
                follow these steps
              </a>
              .
            </li>
            <li>
              <strong>Business Display Name</strong> (Should have a clear relationship with Business)<br />
              To know about the Display Name Guide,{' '}
              <a href="#" target="_blank" rel="noopener noreferrer">
                please click here
              </a>
              .
            </li>
            <li>
              <strong>Facebook Business Manager ID</strong><br />
              If you do not have a Facebook Business, please create one by{' '}
              <a href="https://business.facebook.com/" target="_blank" rel="noopener noreferrer">
                clicking here
              </a>
              .<br />
              To verify your Facebook Business, please follow{' '}
              <a href="#" target="_blank" rel="noopener noreferrer">
                these steps
              </a>
              .<br />
              If your "start verification" button is greyed, please{' '}
              <a href="#" target="_blank" rel="noopener noreferrer">
                click here
              </a>
              .
            </li>
            <li>
              <strong>Working website of your business</strong><br />
              META requires all companies to have an active public website as this will be used to verify the products/services traded by your business.<br />
              You can go to any free website builder and create a basic 1-page website that clearly mentions your Display name and products/services offered by your business. You can also host it anywhere with free hosting/blogs for Meta to review. This is mandatory to proceed. The website should preferably have the contact details and address of your organization. This is mandatory to get your Facebook business verification completed after onboarding.
            </li>
          </ol>
          <div className="note-callout">
            Depending on the Business verification progress by <strong>Facebook Official Team</strong>, it usually takes about a week to get your Connectby.io account without any limitations (mentioned below).
          </div>
          <p></p>
          <h3>What if your Facebook Business is not verified?</h3>
          <p>You can still get access to Connectby.io & WhatsApp Business API, but with limited access.</p>
          <p>When your Facebook Business is not verified, you're subject to a few limitations -</p>
          <ul>
            <li>You can send business-initiated messages to upto 50 contacts per 24 hours</li>
            <li>You can add up to 2 numbers on WhatsApp Business API</li>
          </ul>
          <p>If the conversation is initiated by the user, you can send unlimited replies to those conversations without any limitations.</p>
          <h3>How to increase your messaging limit?</h3>
          <p>If you want to increase your messaging limit, you must complete your Facebook Business Verification process and get your WhatsApp account approved.</p>
          <p>The below flowchart explains the process for WhatsApp Business account creation:</p>
          <figure>
            <img src={helpimg} alt="Screenshot_2020-06-09_at-3.00.52_PM.png" style={{ width: '100%' }} />
          </figure>
          <p></p>
        </div>
      </Modal>
      <Modal
        title="Why Failed?"
        visible={modalVisibleOne}
        onOk={handleModalCloseOne}
        onCancel={handleModalCloseOne}
        footer={[
          <Button key="cancel" onClick={handleModalCloseOne} type="primary">Ok</Button>,
        ]}
        width={800}
      >
        <div>
          <p>1) Message failed to send because there were too many messages sent from this phone number in a short period of time.</p>
          <p>2) Failed to download media successfully due to rate-limiting (rate-limiting is 200 message pr hour) </p>
          <p>3) Message failed to send because there are restrictions on how many messages can be sent from this phone number. This may be because too many previous messages were blocked or flagged as spam.</p>
          <p><a href='https://developers.facebook.com/docs/whatsapp/business-management-api/get-started/#rate-limits' target='blank'>Learn more</a></p>
        </div>
      </Modal>
    </div >
  );
};

export default FacebookLoginButton;