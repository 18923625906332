import { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { AddOutlined } from '@mui/icons-material';
import { MessageOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

const { TextArea } = Input;

function TemplateNode({ data }) {
  const [inputCount, setInputCount] = useState(0);

  const handleAddButton = () => {
    if (inputCount < 3) {
      setInputCount(inputCount + 1);
    }
  };

  const onCloseIconClick = () => {
    if (typeof data.onClose === 'function') {
      data.onClose(data.id); 
    }
  };

  const handleRemoveButton = (index) => {
    const newInputs = [...Array(inputCount).keys()].filter((i) => i !== index);
    setInputCount(newInputs.length);
  };

  const renderAdditionalInputs = () => {
    const inputs = [];
    for (let i = 0; i < inputCount; i++) {
      inputs.push(
        <div key={i} className="additionalInput">
          <TextArea
            maxLength={20}
            rows={1}
            placeholder={`Type Button Name ${i + 1}`}
            count={{ show: true, max: 20 }}
            onChange={(e) => data.onAdditionalTextAreaChange(data.id, i, e.target.value)}
            className="nodrag"
          />
          <Button
            className="removeBtn"
            onClick={() => handleRemoveButton(i)}
          >
            <CloseOutlined />
          </Button>
          <Handle type="source" position={Position.Right} id={`${data.id}${i}`} />
        </div>
      );
    }
    return inputs;
  };

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div className='nodes'>
        <div className='header'>
          <p><MessageOutlined /> {data.label}</p>
          <CloseOutlined
            className="closeIcon"
            onClick={onCloseIconClick}
          />
        </div>
        <div className='body'>
          <div className='inputMainBox'>
            <Button
              className='addMoreBtn'
              onClick={() => data.onTemplatesPreview(data.id)}
            >
              Choose Template <AddOutlined />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TemplateNode;