import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Typography, Divider, Switch, message } from 'antd';
import { LockOutlined, UserOutlined, InboxOutlined, NotificationOutlined, SettingOutlined, RobotOutlined, ShopOutlined, FundOutlined } from '@ant-design/icons';
const { Title, Paragraph, Text, Link } = Typography;
const onChange = (key) => {
    console.log(key);
};

const Permissions = (userData) => {
    const [roles, setRoles] = useState([]);
    const [owner_role_id, set_owner_role_id] = useState(0);
    const [admin_role_id, set_admin_role_id] = useState(0);
    const [teammate_role_id, set_teammate_role_id] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getRoles(userData);
    }, [userData]);

    const getRoles = async (userData) => {
        const userId = userData?.userData[0]?.user_id;
        fetch('https://connectby.io:3001/api/get-roles', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {
            const ownerRole = data.find(role => role?.role_name === "Owner")?.role_id || 0;
            const adminRole = data.find(role => role?.role_name === "Admin")?.role_id || 0;
            const teammateRole = data.find(role => role?.role_name === "Teammate")?.role_id || 0;
            set_owner_role_id(ownerRole);
            set_admin_role_id(adminRole);
            set_teammate_role_id(teammateRole);
            setRoles(data);
        }).catch(error => {
            console.error('Error fetching notes:', error);
        });
    };

    const handleRoleCheckbox = async (role_name, role_id, status, field) => {
        const crm_user_id = userData?.userData[0]?.user_id;
        console.log(role_name, role_id, status, field);
        try {
            setLoading(true);
            const response = await fetch(`https://connectby.io:3001/api/update-settings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: crm_user_id,
                    role_name: role_name,
                    role_id: role_id,
                    status: status,
                    field: field,
                }),
            });
            if (response.ok) {
                if (role_name === 'Owner') {
                    message.error('Owner permissions can not be changed');
                    let updated = false;
                    setRoles(prevRoles =>
                        prevRoles.map(role => {
                            if (role.role_id === role_id) {
                                updated = true;
                                return { ...role, [field]: status };
                            }
                            return role;
                        })
                    );
                    if (!updated) {
                        getRoles(userData);
                    }
                } else {
                    let updated = false;
                    setRoles(prevRoles =>
                        prevRoles.map(role => {
                            if (role.role_id === role_id) {
                                updated = true;
                                return { ...role, [field]: status };
                            }
                            return role;
                        })
                    );
                    if (!updated) {
                        getRoles(userData);
                    }
                    message.success('Updated successfully');
                }
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }
        } catch (error) {
            console.log(error);
            console.error('Error updating:', error);
        } finally {
            setLoading(false);
        }
    };

    const items = [
        {
            key: '1',
            label: 'Owner',
            children: <Row>
                <Col span={24}>
                    <Typography>
                        <Title level={4}> <UserOutlined /> All Users</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to view All Users page</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_user_page_view ? roles.find(role => role?.role_name === "Owner")?.allow_user_page_view === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_user_page_view')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Export Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_user_export ? roles.find(role => role?.role_name === "Owner")?.allow_user_export === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_user_export')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Add Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_user_add ? roles.find(role => role?.role_name === "Owner")?.allow_user_add === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_user_add')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Delete Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_user_delete ? roles.find(role => role?.role_name === "Owner")?.allow_user_delete === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_user_delete')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to bulk tag Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_to_bulk_tag ? roles.find(role => role?.role_name === "Owner")?.allow_to_bulk_tag === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_to_bulk_tag')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <LockOutlined /> Access to user details</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Hide user traits data<br />
                                    <span style={{ fontSize: 11, fontStyle: 'italic' }}>Note: Enabling this will hide Templates, Notifications, All Users and Analytics module. As all these modules have various places where the user contact number can be seen.</span>
                                </Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.hide_user_traits_data ? roles.find(role => role?.role_name === "Owner")?.hide_user_traits_data === '1' : false} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '2', 'hide_user_traits_data')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Hide user contact number<br />
                                    <span style={{ fontSize: 11, fontStyle: 'italic' }}>Note: Enabling this will hide all user traits data (including Contact Number) from all places on the Interakt interface. This won’t disable exports of various reports containing user data. Those disablements can be done separately.</span>
                                </Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.hide_user_contact_number ? roles.find(role => role?.role_name === "Owner")?.hide_user_contact_number === '1' : false} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '2', 'hide_user_contact_number')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <InboxOutlined /> Inbox</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to All section</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_access_to_all_section ? roles.find(role => role?.role_name === "Owner")?.allow_access_to_all_section === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '2', 'allow_access_to_all_section')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow access to Unassigned section</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_access_to_unassigned_section ? roles.find(role => role?.role_name === "Owner")?.allow_access_to_unassigned_section === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_access_to_unassigned_section')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <NotificationOutlined /> Campaigns</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Create New Campaign</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_to_create_campaign ? roles.find(role => role?.role_name === "Owner")?.allow_to_create_campaign === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_to_create_campaign')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow export of Campaign Report</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_to_export_campaign ? roles.find(role => role?.role_name === "Owner")?.allow_to_export_campaign === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_to_export_campaign')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <SettingOutlined /> Settings</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Billing Section</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_billing_section ? roles.find(role => role?.role_name === "Owner")?.allow_billing_section === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_billing_section')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Team Settings</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_team_settings ? roles.find(role => role?.role_name === "Owner")?.allow_team_settings === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_team_settings')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to API Key</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_api_keys ? roles.find(role => role?.role_name === "Owner")?.allow_api_keys === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_api_keys')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Whatsapp Business Setup</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_whatsapp_business_setup ? roles.find(role => role?.role_name === "Owner")?.allow_whatsapp_business_setup === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_whatsapp_business_setup')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Invoice History</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_invoice_history ? roles.find(role => role?.role_name === "Owner")?.allow_invoice_history === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_invoice_history')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Subscription Details</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_subscription_detail ? roles.find(role => role?.role_name === "Owner")?.allow_subscription_detail === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_subscription_detail')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to manage Tags</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_manage_tags ? roles.find(role => role?.role_name === "Owner")?.allow_manage_tags === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_manage_tags')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <FundOutlined /> Analytics</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to view Analytics page</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_analytics_page ? roles.find(role => role?.role_name === "Owner")?.allow_analytics_page === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_analytics_page')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Export Data in Analytics</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_export_analytics ? roles.find(role => role?.role_name === "Owner")?.allow_export_analytics === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_export_analytics')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <ShopOutlined /> Commerce</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow export of Order Panel</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_order_panel ? roles.find(role => role?.role_name === "Owner")?.allow_order_panel === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_order_panel')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <RobotOutlined /> Automation</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow export of Workflow Report</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Owner")?.allow_workflow ? roles.find(role => role?.role_name === "Owner")?.allow_workflow === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Owner', owner_role_id, '1', 'allow_workflow')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                </Col>
            </Row >,
        },
        {
            key: '2',
            label: 'Admin',
            children: <Row>
                <Col span={24}>
                    <Typography>
                        <Title level={4}> <UserOutlined /> All Users</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to view All Users page</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_user_page_view ? roles.find(role => role?.role_name === "Admin")?.allow_user_page_view === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_user_page_view ? roles.find(role => role?.role_name === "Admin")?.allow_user_page_view === '1' ? '2' : '1' : '2', 'allow_user_page_view')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Export Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_user_export ? roles.find(role => role?.role_name === "Admin")?.allow_user_export === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_user_export ? roles.find(role => role?.role_name === "Admin")?.allow_user_export === '1' ? '2' : '1' : '2', 'allow_user_export')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Add Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_user_add ? roles.find(role => role?.role_name === "Admin")?.allow_user_add === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_user_add ? roles.find(role => role?.role_name === "Admin")?.allow_user_add === '1' ? '2' : '1' : '2', 'allow_user_add')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Delete Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_user_delete ? roles.find(role => role?.role_name === "Admin")?.allow_user_delete === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_user_delete ? roles.find(role => role?.role_name === "Admin")?.allow_user_delete === '1' ? '2' : '1' : '2', 'allow_user_delete')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to bulk tag Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_to_bulk_tag ? roles.find(role => role?.role_name === "Admin")?.allow_to_bulk_tag === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_to_bulk_tag ? roles.find(role => role?.role_name === "Admin")?.allow_to_bulk_tag === '1' ? '2' : '1' : '2', 'allow_to_bulk_tag')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <LockOutlined /> Access to user details</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Hide user traits data<br />
                                    <span style={{ fontSize: 11, fontStyle: 'italic' }}>Note: Enabling this will hide Templates, Notifications, All Users and Analytics module. As all these modules have various places where the user contact number can be seen.</span>
                                </Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.hide_user_traits_data ? roles.find(role => role?.role_name === "Admin")?.hide_user_traits_data === '1' : false} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.hide_user_traits_data ? roles.find(role => role?.role_name === "Admin")?.hide_user_traits_data === '1' ? '2' : '1' : '1', 'hide_user_traits_data')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Hide user contact number<br />
                                    <span style={{ fontSize: 11, fontStyle: 'italic' }}>Note: Enabling this will hide all user traits data (including Contact Number) from all places on the Interakt interface. This won’t disable exports of various reports containing user data. Those disablements can be done separately.</span>
                                </Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.hide_user_contact_number ? roles.find(role => role?.role_name === "Admin")?.hide_user_contact_number === '1' : false} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.hide_user_contact_number ? roles.find(role => role?.role_name === "Admin")?.hide_user_contact_number === '1' ? '2' : '1' : '1', 'hide_user_contact_number')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <InboxOutlined /> Inbox</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to All section</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_access_to_all_section ? roles.find(role => role?.role_name === "Admin")?.allow_access_to_all_section === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_access_to_all_section ? roles.find(role => role?.role_name === "Admin")?.allow_access_to_all_section === '1' ? '2' : '1' : '2', 'allow_access_to_all_section')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow access to Unassigned section</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_access_to_unassigned_section ? roles.find(role => role?.role_name === "Admin")?.allow_access_to_unassigned_section === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_access_to_unassigned_section ? roles.find(role => role?.role_name === "Admin")?.allow_access_to_unassigned_section === '1' ? '2' : '1' : '2', 'allow_access_to_unassigned_section')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <NotificationOutlined /> Campaigns</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Create New Campaign</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_to_create_campaign ? roles.find(role => role?.role_name === "Admin")?.allow_to_create_campaign === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_to_create_campaign ? roles.find(role => role?.role_name === "Admin")?.allow_to_create_campaign === '1' ? '2' : '1' : '2', 'allow_to_create_campaign')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow export of Campaign Report</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_to_export_campaign ? roles.find(role => role?.role_name === "Admin")?.allow_to_export_campaign === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_to_export_campaign ? roles.find(role => role?.role_name === "Admin")?.allow_to_export_campaign === '1' ? '2' : '1' : '2', 'allow_to_export_campaign')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <SettingOutlined /> Settings</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Billing Section</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_billing_section ? roles.find(role => role?.role_name === "Admin")?.allow_billing_section === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_billing_section ? roles.find(role => role?.role_name === "Admin")?.allow_billing_section === '1' ? '2' : '1' : '2', 'allow_billing_section')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Team Settings</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_team_settings ? roles.find(role => role?.role_name === "Admin")?.allow_team_settings === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_team_settings ? roles.find(role => role?.role_name === "Admin")?.allow_team_settings === '1' ? '2' : '1' : '2', 'allow_team_settings')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to API Key</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_api_keys ? roles.find(role => role?.role_name === "Admin")?.allow_api_keys === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_api_keys ? roles.find(role => role?.role_name === "Admin")?.allow_api_keys === '1' ? '2' : '1' : '2', 'allow_api_keys')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Whatsapp Business Setup</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_whatsapp_business_setup ? roles.find(role => role?.role_name === "Admin")?.allow_whatsapp_business_setup === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_whatsapp_business_setup ? roles.find(role => role?.role_name === "Admin")?.allow_whatsapp_business_setup === '1' ? '2' : '1' : '2', 'allow_whatsapp_business_setup')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Invoice History</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_invoice_history ? roles.find(role => role?.role_name === "Admin")?.allow_invoice_history === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_invoice_history ? roles.find(role => role?.role_name === "Admin")?.allow_invoice_history === '1' ? '2' : '1' : '2', 'allow_invoice_history')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Subscription Details</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_subscription_detail ? roles.find(role => role?.role_name === "Admin")?.allow_subscription_detail === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_subscription_detail ? roles.find(role => role?.role_name === "Admin")?.allow_subscription_detail === '1' ? '2' : '1' : '2', 'allow_subscription_detail')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to manage Tags</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_manage_tags ? roles.find(role => role?.role_name === "Admin")?.allow_manage_tags === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_manage_tags ? roles.find(role => role?.role_name === "Admin")?.allow_manage_tags === '1' ? '2' : '1' : '2', 'allow_manage_tags')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <FundOutlined /> Analytics</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to view Analytics page</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_analytics_page ? roles.find(role => role?.role_name === "Admin")?.allow_analytics_page === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_analytics_page ? roles.find(role => role?.role_name === "Admin")?.allow_analytics_page === '1' ? '2' : '1' : '2', 'allow_analytics_page')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Export Data in Analytics</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_export_analytics ? roles.find(role => role?.role_name === "Admin")?.allow_export_analytics === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_export_analytics ? roles.find(role => role?.role_name === "Admin")?.allow_export_analytics === '1' ? '2' : '1' : '2', 'allow_export_analytics')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <ShopOutlined /> Commerce</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow export of Order Panel</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_order_panel ? roles.find(role => role?.role_name === "Admin")?.allow_order_panel === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_order_panel ? roles.find(role => role?.role_name === "Admin")?.allow_order_panel === '1' ? '2' : '1' : '2', 'allow_order_panel')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <RobotOutlined /> Automation</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow export of Workflow Report</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Admin")?.allow_workflow ? roles.find(role => role?.role_name === "Admin")?.allow_workflow === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Admin', admin_role_id, roles.find(role => role?.role_name === "Admin")?.allow_workflow ? roles.find(role => role?.role_name === "Admin")?.allow_workflow === '1' ? '2' : '1' : '2', 'allow_workflow')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                </Col>
            </Row >,
        },
        {
            key: '3',
            label: 'Teammate',
            children: <Row>
                <Col span={24}>
                    <Typography>
                        <Title level={4}> <UserOutlined /> All Users</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to view All Users page</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_user_page_view ? roles.find(role => role?.role_name === "Teammate")?.allow_user_page_view === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_user_page_view ? roles.find(role => role?.role_name === "Teammate")?.allow_user_page_view === '1' ? '2' : '1' : '2', 'allow_user_page_view')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Export Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_user_export ? roles.find(role => role?.role_name === "Teammate")?.allow_user_export === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_user_export ? roles.find(role => role?.role_name === "Teammate")?.allow_user_export === '1' ? '2' : '1' : '2', 'allow_user_export')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Add Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_user_add ? roles.find(role => role?.role_name === "Teammate")?.allow_user_add === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_user_add ? roles.find(role => role?.role_name === "Teammate")?.allow_user_add === '1' ? '2' : '1' : '2', 'allow_user_add')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Delete Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_user_delete ? roles.find(role => role?.role_name === "Teammate")?.allow_user_delete === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_user_delete ? roles.find(role => role?.role_name === "Teammate")?.allow_user_delete === '1' ? '2' : '1' : '2', 'allow_user_delete')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to bulk tag Users</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_to_bulk_tag ? roles.find(role => role?.role_name === "Teammate")?.allow_to_bulk_tag === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_to_bulk_tag ? roles.find(role => role?.role_name === "Teammate")?.allow_to_bulk_tag === '1' ? '2' : '1' : '2', 'allow_to_bulk_tag')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <LockOutlined /> Access to user details</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Hide user traits data<br />
                                    <span style={{ fontSize: 11, fontStyle: 'italic' }}>Note: Enabling this will hide Templates, Notifications, All Users and Analytics module. As all these modules have various places where the user contact number can be seen.</span>
                                </Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.hide_user_traits_data ? roles.find(role => role?.role_name === "Teammate")?.hide_user_traits_data === '1' : false} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.hide_user_traits_data ? roles.find(role => role?.role_name === "Teammate")?.hide_user_traits_data === '1' ? '2' : '1' : '1', 'hide_user_traits_data')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Hide user contact number<br />
                                    <span style={{ fontSize: 11, fontStyle: 'italic' }}>Note: Enabling this will hide all user traits data (including Contact Number) from all places on the Interakt interface. This won’t disable exports of various reports containing user data. Those disablements can be done separately.</span>
                                </Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.hide_user_contact_number ? roles.find(role => role?.role_name === "Teammate")?.hide_user_contact_number === '1' : false} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.hide_user_contact_number ? roles.find(role => role?.role_name === "Teammate")?.hide_user_contact_number === '1' ? '2' : '1' : '1', 'hide_user_contact_number')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <InboxOutlined /> Inbox</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to All section</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_access_to_all_section ? roles.find(role => role?.role_name === "Teammate")?.allow_access_to_all_section === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_access_to_all_section ? roles.find(role => role?.role_name === "Teammate")?.allow_access_to_all_section === '1' ? '2' : '1' : '2', 'allow_access_to_all_section')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow access to Unassigned section</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_access_to_unassigned_section ? roles.find(role => role?.role_name === "Teammate")?.allow_access_to_unassigned_section === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_access_to_unassigned_section ? roles.find(role => role?.role_name === "Teammate")?.allow_access_to_unassigned_section === '1' ? '2' : '1' : '2', 'allow_access_to_unassigned_section')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <NotificationOutlined /> Campaigns</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Create New Campaign</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_to_create_campaign ? roles.find(role => role?.role_name === "Teammate")?.allow_to_create_campaign === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_to_create_campaign ? roles.find(role => role?.role_name === "Teammate")?.allow_to_create_campaign === '1' ? '2' : '1' : '2', 'allow_to_create_campaign')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow export of Campaign Report</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_to_export_campaign ? roles.find(role => role?.role_name === "Teammate")?.allow_to_export_campaign === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_to_export_campaign ? roles.find(role => role?.role_name === "Teammate")?.allow_to_export_campaign === '1' ? '2' : '1' : '2', 'allow_to_export_campaign')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <SettingOutlined /> Settings</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Billing Section</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_billing_section ? roles.find(role => role?.role_name === "Teammate")?.allow_billing_section === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_billing_section ? roles.find(role => role?.role_name === "Teammate")?.allow_billing_section === '1' ? '2' : '1' : '2', 'allow_billing_section')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Team Settings</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_team_settings ? roles.find(role => role?.role_name === "Teammate")?.allow_team_settings === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_team_settings ? roles.find(role => role?.role_name === "Teammate")?.allow_team_settings === '1' ? '2' : '1' : '2', 'allow_team_settings')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to API Key</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_api_keys ? roles.find(role => role?.role_name === "Teammate")?.allow_api_keys === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_api_keys ? roles.find(role => role?.role_name === "Teammate")?.allow_api_keys === '1' ? '2' : '1' : '2', 'allow_api_keys')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Whatsapp Business Setup</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_whatsapp_business_setup ? roles.find(role => role?.role_name === "Teammate")?.allow_whatsapp_business_setup === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_whatsapp_business_setup ? roles.find(role => role?.role_name === "Teammate")?.allow_whatsapp_business_setup === '1' ? '2' : '1' : '2', 'allow_whatsapp_business_setup')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Invoice History</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_invoice_history ? roles.find(role => role?.role_name === "Teammate")?.allow_invoice_history === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_invoice_history ? roles.find(role => role?.role_name === "Teammate")?.allow_invoice_history === '1' ? '2' : '1' : '2', 'allow_invoice_history')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow access to Subscription Details</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_subscription_detail ? roles.find(role => role?.role_name === "Teammate")?.allow_subscription_detail === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_subscription_detail ? roles.find(role => role?.role_name === "Teammate")?.allow_subscription_detail === '1' ? '2' : '1' : '2', 'allow_subscription_detail')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to manage Tags</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_manage_tags ? roles.find(role => role?.role_name === "Teammate")?.allow_manage_tags === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_manage_tags ? roles.find(role => role?.role_name === "Teammate")?.allow_manage_tags === '1' ? '2' : '1' : '2', 'allow_manage_tags')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <FundOutlined /> Analytics</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to view Analytics page</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_analytics_page ? roles.find(role => role?.role_name === "Teammate")?.allow_analytics_page === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_analytics_page ? roles.find(role => role?.role_name === "Teammate")?.allow_analytics_page === '1' ? '2' : '1' : '2', 'allow_analytics_page')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text>Allow to Export Data in Analytics</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_export_analytics ? roles.find(role => role?.role_name === "Teammate")?.allow_export_analytics === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_export_analytics ? roles.find(role => role?.role_name === "Teammate")?.allow_export_analytics === '1' ? '2' : '1' : '2', 'allow_export_analytics')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <ShopOutlined /> Commerce</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow export of Order Panel</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_order_panel ? roles.find(role => role?.role_name === "Teammate")?.allow_order_panel === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_order_panel ? roles.find(role => role?.role_name === "Teammate")?.allow_order_panel === '1' ? '2' : '1' : '2', 'allow_order_panel')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                    <Typography>
                        <Title level={4}> <RobotOutlined /> Automation</Title>
                        <Col span={24} style={{ marginLeft: '25px' }}>
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow export of Workflow Report</Text>
                                <Switch checked={roles.find(role => role?.role_name === "Teammate")?.allow_workflow ? roles.find(role => role?.role_name === "Teammate")?.allow_workflow === '1' : true} loading={loading} onClick={() => handleRoleCheckbox('Teammate', teammate_role_id, roles.find(role => role?.role_name === "Teammate")?.allow_workflow ? roles.find(role => role?.role_name === "Teammate")?.allow_workflow === '1' ? '2' : '1' : '2', 'allow_workflow')} />
                            </Paragraph>
                            <Divider className='darkDevider' />
                        </Col>
                    </Typography>
                </Col>
            </Row >,
        },
    ];

    return (
        <Row>
            <Col span={24}>
                <Typography>
                    <Title level={3}> <LockOutlined /> Manage Access Permissions</Title>
                    <Paragraph>Manage access to Connectby.io features for your team. Read here to read more about this section in detail.</Paragraph>
                </Typography>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} className='settingTabs' />
            </Col>
        </Row>
    );
};

export default Permissions;