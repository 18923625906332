import React from 'react';
import { Tooltip } from 'antd';
import {
  SnippetsOutlined,
  UserAddOutlined,
  TagOutlined,
  SmileOutlined,
  FormOutlined,
  ShoppingOutlined,
  ShopOutlined,
  PictureOutlined,
  ApiOutlined,
  EnvironmentOutlined,
  QuestionCircleOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
  AppstoreAddOutlined,
  LinkOutlined
} from '@ant-design/icons';

const iconMap = {
  'Text Buttons': <FormOutlined />,
  'Media Buttons': <PictureOutlined />,
  'List': <SnippetsOutlined />,
  'Catalogue Message': <ShoppingOutlined />,
  'Single Product': <ShopOutlined />,
  'Multi Product': <AppstoreAddOutlined />,
  'Template': <FileTextOutlined />,
  'Request Intervention': <UserAddOutlined />,
  'Condition': <TagOutlined />,
  'Content Flow': <SmileOutlined />,
  'Ask Address': <EnvironmentOutlined />,
  'Ask Location': <EnvironmentOutlined />,
  'Ask Question': <QuestionCircleOutlined />,
  'Ask Media': <VideoCameraOutlined />,
  'Set Attribute': <LinkOutlined />,
  'Add Tag': <TagOutlined />,
  'API Request': <ApiOutlined />
};

const triggers = [
  { label: 'Text Buttons' },
  { label: 'Media Buttons' },
  { label: 'List' },
  { label: 'Catalogue Message' },
  { label: 'Single Product' },
  { label: 'Multi Product' },
  { label: 'Template' }
];

const actions = [
  { label: 'Request Intervention' },
  { label: 'Condition' },
  { label: 'Content Flow' },
  { label: 'Ask Address' },
  { label: 'Ask Location' },
  { label: 'Ask Question' },
  { label: 'Ask Media' },
  { label: 'Set Attribute' },
  { label: 'Add Tag' },
  { label: 'API Request' }
];

const AutoSide = () => {
  const onDragStart = (event, item) => {
    event.dataTransfer.setData('application/reactflow', item);
    event.dataTransfer.effectAllowed = 'move';
  };

  const renderNode = (item, type, menutype) => (
    <Tooltip title={item.label}>
    <div className={`dndnode ${type} ${menutype}`} onDragStart={(event) => onDragStart(event, JSON.stringify(item))} draggable>
      <span className='iconSpan'>{iconMap[item.label]}</span>
      <span className='itemLabel'>{item.label}</span>
    </div>
    </Tooltip>
  );

  return (
    <aside className='node-sidebar'>
      <div className='node-title'>Message types</div>
      <div className='node-menu'>
        {triggers.map((trigger) => renderNode(trigger, 'default', 'itemtrigger'))}
      </div>
      <div className='node-title'>Actions</div>
      <div className='node-menu'>
        {actions.map((action) => renderNode(action, 'default', 'itemaction'))}
      </div>
      <div className='node-title'>Integrations</div>
    </aside>
  );
};

export default AutoSide;