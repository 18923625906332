import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CurrencyList from 'currency-list';
import { Switch, Card, TimePicker, Modal, Table, Skeleton, Dropdown, Pagination, List, Button, Menu, Flex, message, Input, Tooltip, Spin, Popconfirm, Col, Row, Drawer, Form, Select, Space, DatePicker, Typography, Checkbox, Layout, Upload } from 'antd';
import { InboxOutlined, ApartmentOutlined, BookOutlined, ShoppingCartOutlined, FacebookOutlined, PlusOutlined, EyeOutlined, EditOutlined, DeleteOutlined, CopyOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import CheckoutFlow from './CheckoutFlow.jsx';
const { Title, Text } = Typography;
const { Option } = Select;
const { Header, Content } = Layout;
const { TextArea } = Input;
const { Meta } = Card;

const currencies = CurrencyList?.default?.currencyList?.['en_US'];

const Ecom = (userData) => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = location.search.substring(1);
    const [activeMenu, setActiveMenu] = useState(query ? query : 'ecom-settings');
    const [automationData, setAutomationData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [autoData, setAutoData] = useState([]);
    const [autoLoader, setAutoLoader] = useState(false);
    const [messageLoader, setMessageLoader] = useState(false);
    const [messageLoaderOne, setMessageLoaderOne] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [blockLoading, setBlockLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [paging, setPaging] = useState({});
    const [productLoading, setProductLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedEditProduct, setSelectedEditProduct] = useState(null);
    const [editPriductform] = Form.useForm();
    const [searchValue, setSearchValue] = useState('');
    const [usAccessToken, setUsAccessToken] = useState(null);
    const [phoneNumberId, setPhoneNumberId] = useState('');
    const [waBaId, setWaBaId] = useState('');
    const [catalogues, setCatalogues] = useState([]);
    const [selectedCatalogueId, setSelectedCatalogueId] = useState('');

    const [ordreLoading, setOrderLoading] = useState('');

    useEffect(() => {
        fetchAutomationData();
        fetchWelcomeData();
        fetchCataloguesData();
        fetchOrders(userData);
    }, [userData]);

    useEffect(() => {
        fetchData();
    }, [selectedCatalogueId]);


    const fetchCataloguesData = async () => {
        setProductLoading(true);
        const url = 'https://connectby.io:3001/api/get-catalogs';
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'wabaid': userData?.userData[0]?.wabaid
                },
            };
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            if (data.success) {
                console.log(data);
                if (data.data.length !== 0) {
                    setCatalogues(data.data);
                }
            } else {
                console.error('Failed to fetch data:', data.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setProductLoading(false);
        }
    };

    const fetchOrders = async (userData) => {
        console.log('user_id', userData?.userData[0]?.user_id);
        setOrderLoading(true);
        const url = 'https://connectby.io:3001/api/get-orders';
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userData?.userData[0]?.user_id }),
            };
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            console.log('orderData', data);
            setOrderData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setOrderLoading(false);
        }
    };


    const fetchData = async (page = '') => {
        setProductLoading(true);
        const url = 'https://connectby.io:3001/api/get-catalog';
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'catalougid': selectedCatalogueId
                },
                body: JSON.stringify({ "page": page }),
            };
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            if (data.success) {
                console.log(data);
                if (data.data.length !== 0) {
                    setProducts(data.data);
                    setPaging(data.paging);
                }
            } else {
                console.error('Failed to fetch data:', data.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setProductLoading(false);
        }
    };

    const handlePreviousPage = () => {
        if (paging.previous) {
            fetchData(paging.previous);
        }
    };

    useEffect(() => {
        const loadFacebookSDK = () => {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '715393607349643',
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v18.0'
                });
                checkLoginStatus();
            };

            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        };

        const fetchUserInfo = () => {
            window.FB.api('/me', { fields: 'id,name,email' }, function (response) {
                if (response && !response.error) {
                    console.log(response);
                    // setFbUserData(response);
                }
            });

            if (usAccessToken && phoneNumberId && waBaId) {
                const whatsappbody = {
                    "messaging_product": "whatsapp",
                    "pin": "560001"
                };

                fetch(`https://graph.facebook.com/v19.0/${phoneNumberId}/register`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${usAccessToken}`
                    },
                    body: JSON.stringify(whatsappbody),
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('data:', data);
                    })
                    .catch(error => {
                        console.error('Error fetching register phone no.:', error);
                    });
                fetch(`https://graph.facebook.com/v19.0/${waBaId}/subscribed_apps`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${usAccessToken}`
                    },
                    body: JSON.stringify(whatsappbody),
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('data:', data);
                    })
                    .catch(error => {
                        console.error('Error fetching subscribed app:', error);
                    });
                fetch(`https://connectby.io:3001/api/update-wadata`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: userData?.userData[0]?.user_id,
                        whatsappAccessToken: usAccessToken,
                        wabaid: waBaId,
                        waappid: '715393607349643',
                        phonenumberid: phoneNumberId,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('data:', data);
                        fetchCataloguesData();
                    })
                    .catch(error => {
                        console.error('Error updating data:', error);
                    });
            }
        };

        const checkLoginStatus = () => {
            window.FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    console.log(response);
                    fetchUserInfo();
                } else {
                    console.log("Not Login");
                }
            });
        };

        loadFacebookSDK();

        if (usAccessToken) {
            fetchUserInfo();
            checkLoginStatus();
        }
    }, [usAccessToken]);

    const launchWhatsAppSignup = () => {
        window.FB.login(function (response) {
            if (response.authResponse) {
                const accessToken = response.authResponse.code;
                fetch(`https://graph.facebook.com/v18.0/oauth/access_token?client_id=715393607349643&client_secret=26b1cd9490f42eeb01bd5170fbe6090f&code=${accessToken}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.access_token) {
                            const userAccessToken = data.access_token;
                            setUsAccessToken(userAccessToken);
                            console.log('User Access Token:', userAccessToken);
                        } else {
                            console.log('User Access Token is not valid.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user access token:', error);
                    });
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: '1674927826601874',
            scope: 'public_profile,email,business_management, whatsapp_business_management, whatsapp_business_messaging,catalog_management',
            // return_scopes: true,
            response_type: 'code',
            override_default_response_type: true,
            extras: {
                featureType: "only_waba_sharing",
                feature: "whatsapp_embedded_signup",
                sessionInfoVersion: 3,
            }
        });
    };

    const sessionInfoListener = (event) => {
        if (event.origin !== "https://www.facebook.com") return;
        try {
            const data = JSON.parse(event.data);
            console.log(data);
            console.log(usAccessToken);
            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                if (data.event === 'FINISH') {
                    const { phone_number_id, waba_id } = data.data;
                    console.log('Session finished. WABA ID:', waba_id);
                    setWaBaId(waba_id);
                    setPhoneNumberId(phone_number_id);
                } else {
                    const { current_step } = data.data;
                    console.log('Session cancelled. Current step:', current_step);
                }
            }
        } catch {
            console.log('Non JSON Response', event.data);
        }
    };
    window.addEventListener('message', sessionInfoListener);

    const handleNextPage = () => {
        if (paging.next) {
            fetchData(paging.next);
        }
    };

    const fetchAutomationData = async () => {
        try {
            const userId = userData?.userData[0]?.user_id;
            const response = await fetch('https://connectby.io:3001/api/get-automation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_id: userId })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setAutomationData(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchWelcomeData = async () => {
        try {
            const userId = userData?.userData[0]?.user_id;
            const response = await fetch(`https://connectby.io:3001/api/get_user_data_phone/${userId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setAutoData(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleTimeChange = (time, timeString) => {
        console.log(time, timeString);
    };
    const handleDeleteFlow = (id) => {
        setDeleteLoader(true);
        fetch(`https://connectby.io:3001/api/delete_flow?automationListId=${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete user');
                }
                return response.json();
            })
            .then(data => {
                message.success('Flow deleted successfully');
                const updatedData = automationData.filter(item => item.automation_list_id !== id);
                setAutomationData(updatedData);
            })
            .catch(error => {
                console.error('Error deleting Flow:', error.message);
                message.error('Failed to delete Flow');
            }).finally(() => {
                setDeleteLoader(false);
            });
    };

    const handleBlock = async (automation_list_id, status) => {
        setBlockLoading(true);
        try {
            const response = await fetch('https://connectby.io:3001/api/active-deactive-automation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    automation_list_id: automation_list_id,
                    crm_user_id: userData?.userData[0]?.user_id,
                    status: status
                }),
            });
            if (response.ok) {
                setAutomationData(automationData.map(automation =>
                    automation.automation_list_id === automation_list_id ? { ...automation, is_active: status } : automation
                ));
                if (status === '1') {
                    message.success('Activate');
                } else {
                    message.success('De-activate');
                }
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error Creating Tag:', error);
        } finally {
            setBlockLoading(false);
        }
    };

    const handleSelectChange = (value) => {
        console.log(value);
    };

    const columns = [
        {
            title: 'Flow Name',
            dataIndex: 'flow_name',
            key: 'flow_name',
        },
        {
            title: 'Keyword',
            dataIndex: 'trigger_name',
            key: 'trigger_name',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (timestamp) => (
                <span>{dayjs.unix(timestamp).format('YYYY-MM-DD HH:mm:ss')}</span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text, record) => {
                return (
                    <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Switch
                            onClick={() => handleBlock(record.automation_list_id, record.is_active === '2' ? '1' : '2')}
                            checkedChildren="De-active"
                            unCheckedChildren="Active"
                            defaultChecked={record.is_active === '1'}
                            loading={blockLoading}
                        />
                        <p style={{ fontSize: 10 }}>({record.is_active === '2' ? 'De-activate' : 'Activate'})</p>
                    </div>
                )
            }
        },
        {
            title: 'Actions',
            key: 'automation_list_id',
            render: (text, record) => (
                <div style={{ float: 'right' }}>
                    <Link
                        to="/flow"
                        state={{ record, is_edit: 'no' }}
                    >
                        <Button icon={<CopyOutlined />} style={{ marginRight: 8 }} />
                    </Link>
                    <Link
                        to="/flow"
                        state={{ record, is_edit: 'yes' }}
                    >
                        <Button icon={<EditOutlined />} onClick={() => { }} style={{ marginRight: 8 }} />
                    </Link>
                    <Popconfirm
                        title="Delete the flow"
                        description="Are you sure to delete this flow?"
                        onConfirm={() => handleDeleteFlow(record.automation_list_id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip title="Delete Customer">
                            {deleteLoader ? (
                                <Button type="primary" danger>
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button type="primary" danger icon={<DeleteOutlined />} />
                            )}
                        </Tooltip>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const menudata = [
        {
            id: 'ecom-settings',
            name: 'E-commerce Settings',
            icon: <InboxOutlined />
        }, {
            id: 'manage-catalouge',
            name: 'Catalogues',
            icon: <BookOutlined />
        },
        {
            id: 'order-panel',
            name: 'Orders',
            icon: <ShoppingCartOutlined />
        },
        {
            id: 'checkout-flow',
            name: 'Auto Checkout flow',
            icon: <FacebookOutlined />
        }
    ];


    const handleOnChange = async (keyname, status) => {
        setAutoLoader(true);
        try {
            const userId = userData?.userData[0]?.user_id;
            const response = await fetch(`https://connectby.io:3001/api/update-usermessagedata/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ keyname, status })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            setAutoData(prevData =>
                prevData.map(item => ({
                    ...item,
                    [keyname]: status
                }))
            );
            message.success('Status Updated.');
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error('Failed to update status.');
        } finally {
            setAutoLoader(false);
        }
    };

    const handleUpdate = async (keyname) => {
        if (keyname === 'out_office_message') {
            setMessageLoader(true);
        } else {
            setMessageLoaderOne(true);
        }

        try {
            const userId = userData?.userData[0]?.user_id;
            const response = await fetch(`https://connectby.io:3001/api/update-user-message/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ welcome_message: autoData[0]?.welcome_message, out_office_message: autoData[0]?.out_office_message })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            message.success('Message Updated.');
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error('Failed to update status.');
        } finally {
            if (keyname === 'out_office_message') {
                setMessageLoader(false);
            } else {
                setMessageLoaderOne(false);
            }
        }
    };

    const handleSearch = (value) => {
        setSearchQuery(value);
    };

    const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleEditProduct = (record) => {
        setSelectedEditProduct(record);
    };

    const handleViewProduct = (record) => {
        setSelectedProduct(record);
        setIsModalOpen(true);
    };

    const productColumns = [
        {
            title: 'Product',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}><span style={{ width: 50, height: 50, display: 'inline-block' }}><img style={{ width: '100%', }} alt={record.name} src={record.image_url} /></span> <span>{record.name}</span></div>
        },
        {
            title: 'visibility',
            dataIndex: 'visibility',
            key: 'visibility',
            render: (_, record) => (
                <span style={{ backgroundColor: record.visibility === 'published' ? 'green' : 'red', color: '#fff', padding: 5, borderRadius: 10 }}>
                    {record.visibility}
                </span>
            )
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price) => <span>{price}</span>,
        }, {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => (
                <Flex gap="small" align="flex-end" vertical>
                    <Flex gap="small">
                        <Tooltip title="View Product">
                            <Button type="primary" className='viewButton' shape="circle" icon={<EyeOutlined />} onClick={() => handleViewProduct(record)} />
                        </Tooltip>
                        <Tooltip title="Edit Product">
                            <Button type="primary" className='editButton' shape="circle" icon={<EditOutlined />} onClick={() => handleEditProduct(record)} />
                        </Tooltip>
                    </Flex>
                </Flex>
            )
        }
    ];

    const handleShippingStatusChange = (newStatus, record) => {
        if (!record) {
            message.error('Record not found.');
            return;
        }

        const currentStatus = record.shipping_status;
        const paymentStatus = record.payment_status;

        if (record?.payment_type === 'online' && paymentStatus.toLowerCase() !== 'success') {
            message.error('Cannot change shipping status unless payment is successful.');
            return;
        }

        const statusTransitions = {
            pending: ['processing', 'partially_shipped', 'shipped', 'completed', 'canceled'],
            processing: ['partially_shipped', 'shipped', 'completed', 'canceled'],
            partially_shipped: ['shipped', 'completed', 'canceled'],
            shipped: ['completed', 'canceled'],
            completed: [],
            canceled: [],
        };

        if (!statusTransitions[currentStatus.toLowerCase()]?.includes(newStatus)) {
            message.error(`Cannot change status from ${formatStatus(currentStatus)} to ${formatStatus(newStatus)}`);
            return;
        }

        Modal.confirm({
            title: 'Confirm Status Change',
            content: <p>Are you sure you want to change the status from <strong style={{color:formatStatusColor(currentStatus)}}>{formatStatus(currentStatus)}</strong> to <strong style={{color:formatStatusColor(newStatus)}}>{formatStatus(newStatus)}</strong>?</p>,
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                const payload = {
                    ecom_order_id: record?.ecom_order_id,
                    payment_type: record?.payment_type,
                    reference_id: record?.paymentid,
                    username: record?.name,
                    wanumber: record?.wanumber,
                    accessToken: userData?.userData[0]?.whatsappAccessToken,
                    phone_number_id: record?.phone_no_id,
                    status: newStatus,
                    paystatus: record?.payment_status,
                };

                try {
                    const response = await fetch('https://connectby.io:3001/api/change-order-status', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();

                    if (data.success) {
                        message.success('Order status updated successfully.');
                        setOrderData((prevOrderData) =>
                            prevOrderData.map((order) =>
                                order.ecom_order_id === record.ecom_order_id
                                    ? { ...order, shipping_status: newStatus }
                                    : order
                            )
                        );
                    } else {
                        message.error(`Failed to update order status: ${data.message}`);
                    }
                } catch (error) {
                    console.error('Error sending request:', error);
                    message.error('An error occurred while updating the order status.');
                }
            },
        });
    };

    const fetchProductName = async (catalog_id, retailer_id) => {
        const url = 'https://connectby.io:3001/api/get-productdetail';
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'catalog_id': catalog_id,
                    'retailer_id': retailer_id
                },
            };
            const response = await fetch(url, requestOptions);
            const result = await response.json();
            const productName = result?.responseData?.data && result?.responseData?.data.length > 0 ? result?.responseData?.data[0].name : "Unknown Product";
            return productName;
        } catch (error) {
            console.error('Error fetching product name:', error);
            return "Unknown Product";
        }
    };

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleViewOrder = async (record) => {
        setSelectedOrder(record);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedOrder(null);
    };

    const formatStatusColor = (status) => {
        let color;
        switch (status) {
            case 'processing':
                color = 'orange';
                break;
            case 'partially_shipped':
                color = 'blue';
                break;
            case 'shipped':
                color = 'green';
                break;
            case 'completed':
                color = 'gray';
                break;
            case 'canceled':
                color = 'red';
                break;
            default:
                color = 'black';
        }
        return color;
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'orange';
            case 'failed':
                return 'red';
            case 'success':
                return 'green';
            default:
                return 'black';
        }
    };

    const formatStatus = (status) => {
        // Replace underscores with spaces and capitalize each word
        return status
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/\b\w/g, char => char.toUpperCase());
    };


    const OrderDetailsModal = ({ visible, onClose, order }) => {
        const [selectedProducts, setSelectedProducts] = useState([]);
        useEffect(() => {
            const fetchProductNames = async () => {
                const productList = JSON.parse(order.products);
                const updatedProducts = await Promise.all(
                    productList.map(async (product) => {
                        const productName = await fetchProductName(order?.cataloug_id, product?.product_retailer_id);
                        return { ...product, productName };
                    })
                );
                setSelectedProducts(updatedProducts);
            };

            if (order?.products) {
                fetchProductNames();
            }
        }, [order]);

        const productColumns = [
            {
                title: 'Product Name',
                dataIndex: 'productName',
                key: 'productName',
            },
            { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
            { title: 'Price', dataIndex: 'item_price', key: 'item_price' },
            { title: 'Currency', dataIndex: 'currency', key: 'currency' },
        ];

        return (<Modal
            title={`Order # - ${order.paymentid}`}
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <Table
                columns={productColumns}
                dataSource={selectedProducts}
                rowKey="product_retailer_id"
                pagination={false}
                size="small"
                style={{ marginTop: 16 }}
            />
            <table className='orderViewTable'>
                <tr><th>Name</th><td>{order?.name}</td></tr>
                <tr><th>Phone Number</th><td>{order?.number}</td></tr>
                <tr><th>Address</th><td>{order?.address}</td></tr>
                <tr>
                    <th>Payment Type</th>
                    <th>{order?.payment_type.toUpperCase()}</th>
                </tr>
                <tr>
                    <th>Payment Status</th>
                    <td style={{ color: getStatusColor(order?.payment_status) }}>
                        {formatStatus(order?.payment_status)}
                    </td>
                </tr>
                <tr><th>Shipping Status</th><td style={{ color: formatStatusColor(order?.shipping_status) }}>{formatStatus(order?.shipping_status)}</td></tr>
                <tr><th>Subtotal</th><td className='text-right'>₹ {order?.subtotal}</td></tr>
                <tr><th>Shipping</th><td className='text-right'>₹ {order?.shipping_charge}</td></tr>
                <tr><th>Discount</th><td className='text-right'>₹ {order?.discount}</td></tr>
                <tr><th>Total</th><th className='text-right'>₹ {order?.payment}</th></tr>
            </table>
        </Modal>
        );
    };

    const orderColumns = [
        {
            title: 'Order #',
            dataIndex: 'paymentid',
            key: 'paymentid',
            render: (text) => <span style={{ textWrap: 'nowrap', fontWeight: 'bold' }}>{text}</span>,
        },
        {
            title: 'Customer Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phone Number',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Total',
            dataIndex: 'payment',
            key: 'payment',
            render: (text) => <span style={{ textWrap: 'nowrap', fontWeight: 'bold' }}>₹ {text}</span>,
        },
        {
            title: 'Payment Method',
            dataIndex: 'payment_type',
            key: 'payment_type',
            render: (text) => <span style={{ textWrap: 'nowrap' }}>{text.toUpperCase()}</span>,
        },
        {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            render: (text) => <span style={{ textWrap: 'nowrap', color: getStatusColor(text) }}>{formatStatus(text)}</span>,
        },
        {
            title: 'Shipping Status',
            dataIndex: 'shipping_status',
            render: (text, record) => (
                <Select
                    value={text}
                    onChange={(value) => handleShippingStatusChange(value, record)}
                    style={{ width: 160 }}
                >
                    <Option value="pending">Pending</Option>
                    <Option value="processing">Processing</Option>
                    <Option value="partially_shipped">Partially Shipped</Option>
                    <Option value="shipped">Shipped</Option>
                    <Option value="completed">Completed</Option>
                    <Option value="canceled">Canceled</Option>
                </Select>
            )
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (timestamp) => new Date(parseInt(timestamp) * 1000).toLocaleString(),
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (timestamp) => new Date(parseInt(timestamp) * 1000).toLocaleString(),
        },
        {
            title: 'Action',
            dataIndex: 'ecom_order_id',
            key: 'ecom_order_id',
            render: (_, record) => (
                <Flex gap="small" align="flex-end" vertical>
                    <Flex gap="small">
                        <Tooltip title="View Product">
                            <Button type="primary" className='viewButton' shape="circle" icon={<EyeOutlined />} onClick={() => handleViewOrder(record)} />
                        </Tooltip>
                    </Flex>
                </Flex>
            )
        },
    ];

    const onSearch = (value) => {
        setSearchValue(value);
    };

    const handleCheckboxChange = (e) => {
        console.log(e.target.checked);
        setSelectedProduct(prevProduct => ({
            ...prevProduct,
            sale_price: e.target.checked
        }));
    };

    const handleOnchangeValue = (e) => {
        console.log(e.target.value);
        setSelectedEditProduct(prevEditProduct => ({
            ...prevEditProduct,
            sale_price: e.target.value
        }));
    };

    const filteredCurrencyOptions = Object.keys(currencies)
        .filter(code => {
            const name = currencies[code]?.name.toLowerCase() || '';
            return code.toLowerCase().includes(searchValue.toLowerCase()) ||
                name.includes(searchValue.toLowerCase());
        })
        .map(code => (
            <Option key={code} value={code}>
                {code} - {currencies[code]?.name}
            </Option>
        ));

    useEffect(() => {
        if (selectedEditProduct !== null) {
            editPriductform.setFieldsValue(selectedEditProduct);
            setIsEditModalOpen(true);
        }
    }, [editPriductform, selectedEditProduct]);

    return (
        <Row>
            <Col span={4}>
                <div className='flowmenudiv' style={{ position: 'sticky', top: 0 }}>
                    <List
                        size="large"
                        header={<h2 style={{ margin: 0 }}>E-commerce</h2>}
                        dataSource={menudata}
                        className='flowmenulist'
                        renderItem={(item) => <List.Item className={`flowmenulistitem ${activeMenu === item.id && 'menuactive'}`} onClick={() => { setActiveMenu(item.id); navigate(`?${item.id}`); }}>{item.icon} {item.name}</List.Item>}
                    />
                </div>
            </Col>
            <Col span={20}>
                {activeMenu === 'ecom-settings' ? (
                    <div className='itemPreviewDiv'>
                        <button onClick={launchWhatsAppSignup}
                            style={{
                                backgroundColor: '#075E54',
                                border: 0,
                                borderRadius: '6px',
                                boxShadow: '0 2px 0 rgba(5, 145, 255, 0.1)',
                                color: '#fff',
                                cursor: 'pointer',
                                fontSize: '16px',
                                height: '40px',
                                padding: '0 24px',
                                position: 'absolute',
                                top: 15,
                                right: 15
                            }}>
                            Connect your Catalogues
                        </button>
                    </div>
                ) : activeMenu === 'checkout-flow' ? (
                    <CheckoutFlow userData={userData?.userData} teamData={userData?.teamData} />
                ) : activeMenu === 'order-panel' ? (
                    <div className='itemPreviewDiv'>
                        <h2><ShoppingCartOutlined /> Orders</h2>
                        <br />
                        <Table
                            dataSource={orderData.map((order, index) => ({ ...order, key: index }))}
                            columns={orderColumns}
                            pagination={{ pageSize: 20 }}
                        />
                    </div>
                ) : activeMenu === 'manage-catalouge' && (
                    <div className='itemPreviewDiv'>
                        <h2><ShoppingCartOutlined /> Catalogues</h2>
                        <br />
                        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Select
                                        placeholder="Select Catalogue"
                                        showSearch
                                        filterOption={false}
                                        onSearch={handleSearch}
                                        style={{ width: '100%', marginRight: 10 }}
                                        onChange={(value) => setSelectedCatalogueId(value)}
                                    >
                                        {catalogues.map(catalogue => (
                                            <Option key={catalogue.id} value={catalogue.id}>
                                                {catalogue.name}
                                            </Option>
                                        ))}
                                    </Select>
                                    <Input.Search
                                        placeholder="Search by flow name"
                                        style={{ marginBottom: '20px', maxWidth: '300px' }}
                                        onSearch={handleSearch}
                                        allowClear
                                    />
                                </div>
                                <Button type="primary" icon={<SyncOutlined />} onClick={() => fetchData()} style={{ marginBottom: '20px' }}>
                                    Sync
                                </Button>
                            </div>
                            <div style={{ padding: '20px' }}>
                                {productLoading ? (
                                    <div>
                                        <Spin />
                                    </div>
                                ) : (
                                    <>
                                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                            <Table
                                                dataSource={products.filter(product => product.name.toLowerCase().includes(searchQuery.toLowerCase()))}
                                                columns={productColumns}
                                                rowKey="id"
                                                pagination={false}
                                            />
                                        </div>
                                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                            <Button
                                                type="primary"
                                                onClick={handlePreviousPage}
                                                disabled={!paging?.previous || loading}
                                                style={{ marginRight: '8px' }}
                                            >
                                                Previous
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={handleNextPage}
                                                disabled={!paging?.next || loading}
                                            >
                                                Next
                                            </Button>
                                        </div>

                                    </>
                                )}
                            </div>
                        </Content>
                    </div>
                )}
            </Col>
            <Modal title={selectedProduct?.name} open={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={() => setIsModalOpen(false)}>
                <img src={selectedProduct?.image_url} className='productImage' alt={selectedProduct?.name} style={{ width: '100%' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p><b>{selectedProduct?.name}</b></p>
                    <p><b>{selectedProduct?.price}</b></p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p><b>Availability</b> {selectedProduct?.availability} ({selectedProduct?.inventory})</p>
                    <p><b>Brand</b> {selectedProduct?.brand}</p>
                </div>
                <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: selectedProduct?.description?.replace(/\n/g, '<br>') }} />
                <a href={selectedProduct?.url}>{selectedProduct?.url}</a>
            </Modal>
            <Drawer
                title="Edit Product"
                width={720}
                onClose={() => setIsEditModalOpen(false)}
                open={isEditModalOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
                            <Button onClick={() => setIsEditModalOpen(false)} type="primary">
                                Update
                            </Button>
                        </Space>
                    </div>
                }
            >
                {selectedProduct && (
                    <Form layout="vertical" form={editPriductform}>
                        <Row gutter={16}>
                            <Col span={12}>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="Product Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter product name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter product name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label="Description"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'please enter url description',
                                        },
                                    ]}
                                >
                                    <Input.TextArea rows={4} placeholder="please enter url description" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={4}>
                                <Form.Item
                                    name="currency"
                                    label="Currency"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a currency',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Select currency" showSearch onSearch={onSearch} filterOption={false}>
                                        {filteredCurrencyOptions}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="price"
                                    label="Price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the price',
                                        }
                                    ]}
                                >
                                    <Input type="text" placeholder="Enter price" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="url"
                                    label="Website link"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter product website link',
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please enter product url"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="type"
                                    label="Type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please choose the type',
                                        },
                                    ]}
                                >
                                    <Checkbox checked={selectedProduct?.sale_price ? true : false} onChange={handleCheckboxChange} />
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please enter Sales Price"
                                        disabled={selectedProduct?.sale_price ? false : true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="category"
                                    label="Facebook product category "
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please choose the approver',
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please select Facebook product Category"
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="condition"
                                    label="Condition"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Condition',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder='Select product Condition'
                                        optionFilterProp='label' >
                                        <Option value='new'>New</Option>
                                        <Option value='refurbished'>Refurbished</Option>
                                        <Option value='used_like_new'>Used (like new)</Option>
                                        <Option value='used_good'>Used (like good)</Option>
                                        <Option value='used_fair'>Used (like fair)</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Drawer>
            {selectedOrder && (
                <OrderDetailsModal
                    visible={isModalVisible}
                    onClose={handleCloseModal}
                    order={selectedOrder}
                />
            )}
        </Row>
    );
};

export default Ecom;