import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row, Table, Input, Skeleton, Checkbox, message, Tooltip, Button, Popconfirm, Dropdown, Flex, Spin, Pagination, Modal } from 'antd';
import { DeleteOutlined, ExportOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

const { Search } = Input;

const ViewCampaignDetails = (userData) => {
    const location = useLocation();
    const { campaign_id } = location.state || {};

    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState('');
    const [sortField, setSortField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [messageStatus, setMessageStatus] = useState('');

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    useEffect(() => {
        const fetchCampaignData = async () => {
            setIsLoading(true);
            try {
                const url = `https://connectby.io:3001/api/getcampaigndata/?crm_user_id=${userData?.userData[0]?.user_id}&campaign_id=${campaign_id}&msgstatus=${messageStatus}&result=${pageSize}&page=${pageNumber}&searchTerm=${searchTerm}&sortorder=${sortOrder}&sortfield=${sortField}`;
                console.log(url);
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Failed to fetch customer data');
                }
                const responseData = await response.json();
                const { data, totalItems } = responseData;
                setFilteredData(data);
                setTotalItems(totalItems);
            } catch (error) {
                console.error('Error fetching customer data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCampaignData();
    }, [userData, campaign_id, pageNumber, pageSize, sortOrder, sortField, searchTerm, messageStatus]);

    const handleDeleteAll = () => {
        Modal.confirm({
            title: 'Confirm Delete Contacts',
            content: (
                <p>
                    Are you sure you want to delete all{' '}
                    {messageStatus && (
                        <strong>
                            {messageStatus.charAt(0).toUpperCase() + messageStatus.slice(1)}
                        </strong>
                    )}{' '}
                    Contacts?
                </p>
            ),
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                const crm_user_id = userData?.userData[0]?.user_id;
                const status = messageStatus;
                const url = new URL('https://connectby.io:3001/api/deletecampaigndata');
                url.searchParams.append('crm_user_id', crm_user_id);
                url.searchParams.append('campaign_id', campaign_id);
                if (status) {
                    url.searchParams.append('msgstatus', status);
                }

                try {
                    const response = await fetch(url, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();

                    if (data.message) {
                        message.success('Contacts deleted successfully.');
                    } else {
                        message.error(`Failed to delete contacts: ${data.message || 'Unknown error'}`);
                    }
                } catch (error) {
                    console.error('Error sending request:', error);
                    message.error(`An error occurred while deleting the contacts: ${error.message}`);
                }
            },
        });
    };

    const handleDeleteSelected = () => {
        Modal.confirm({
            title: 'Confirm Delete Selected Contacts',
            content: (
                <p>
                    Are you sure you want to delete the {selectedCustomers.length} selected Contacts?
                </p>
            ),
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                const crm_user_id = userData?.userData[0]?.user_id;
                const url = new URL('https://connectby.io:3001/api/deleteselectedcampaigndata');
                url.searchParams.append('crm_user_id', crm_user_id);
                selectedCustomers.forEach(customerId => {
                    url.searchParams.append('customer_ids', customerId);
                });

                try {
                    const response = await fetch(url, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();

                    if (data.message) {
                        message.success('Selected contacts deleted successfully.');
                        setSelectedCustomers([]);
                    } else {
                        message.error(`Failed to delete contacts: ${data.message || 'Unknown error'}`);
                    }
                } catch (error) {
                    console.error('Error sending request:', error);
                    message.error(`An error occurred while deleting the contacts: ${error.message}`);
                }
            },
        });
    };

    const handleDelete = (id) => {
        fetch(`https://connectby.io:3001/api/delete_user?customerId=${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete user');
                }
                return response.json();
            })
            .then(() => {
                message.success('Customer deleted successfully');
                const updatedData = filteredData.filter(item => item.customer_id !== id);
                setFilteredData(updatedData);
                setTotalItems(totalItems - 1);
            })
            .catch(error => {
                console.error('Error deleting user:', error.message);
                message.error('Failed to delete user');
            });
    };

    const handleSelectAllCustomers = () => {
        const allCustomerIds = filteredData.map(customer => customer.customer_id);
        const selectedIds = selectedCustomers.length === allCustomerIds.length ? [] : allCustomerIds;
        setSelectedCustomers(selectedIds);
    };

    const handleCustomerSelect = (customerId) => {
        const isSelected = selectedCustomers.includes(customerId);
        if (isSelected) {
            setSelectedCustomers(selectedCustomers.filter(id => id !== customerId));
        } else {
            setSelectedCustomers([...selectedCustomers, customerId]);
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
        if (sorter.order) {
            setSortOrder(sorter.order);
            setSortField(sorter.field);
        } else {
            setSortOrder('');
            setSortField('');
        }
    };

    const exportXLSXDataByStatus = async () => {
        setExportLoading(true);
        try {
            const url = `https://connectby.io:3001/api/exportcampaigndata/?crm_user_id=${userData?.userData[0]?.user_id}&campaign_id=${campaign_id}&msgstatus=${messageStatus}`;
            console.log(url);
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch all customer data for export');
            }
            const responseData = await response.json();
            const allExportData = responseData.data;
            exportToXLSX(allExportData);
        } catch (error) {
            console.error('Error fetching data for export:', error);
        } finally {
            setExportLoading(false);
        }
    };

    const exportCSVDataByStatus = async () => {
        setExportLoading(true);
        try {
            const url = `https://connectby.io:3001/api/exportcampaigndata/?crm_user_id=${userData?.userData[0]?.user_id}&campaign_id=${campaign_id}&msgstatus=${messageStatus}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch all customer data for export');
            }
            const responseData = await response.json();
            const allExportData = responseData.data;
            exportToCSV(allExportData);
        } catch (error) {
            console.error('Error fetching data for export:', error);
        } finally {
            setExportLoading(false);
        }
    };

    const exportToXLSX = (data) => {
        const now = new Date();
        const formattedDate = now.toISOString().split('T')[0];
        const formattedTime = now.toTimeString().split(' ')[0].replace(/:/g, '-');
        const formattedData = data.map(item => ({
            'Client Name': item.customer_name || '',
            'Country Code': item.country_code || '',
            'Phone Number': item.customer_phone || '',
            'Email Address': item.customer_email || '',
            'Notes': item.note || '',
        }));
        const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: ['Client Name', 'Country Code', 'Phone Number', 'Email Address', 'Notes'] });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Campaign Data');
        const filename = `campaign_data_${formattedDate}_${formattedTime}.xlsx`;
        XLSX.writeFile(workbook, filename);
    };

    const exportToCSV = (data) => {
        const now = new Date();
        const formattedDate = now.toISOString().split('T')[0];
        const formattedTime = now.toTimeString().split(' ')[0].replace(/:/g, '-');
        const formattedData = data.map(item => ({
            'Client Name': item.customer_name,
            'Country Code': item.country_code || '',
            'Phone Number': item.customer_phone,
            'Email Address': item.email || '',
            'Notes': item.notes || '',
        }));

        const csv = Papa.unparse(formattedData, { header: true });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        const filename = `campaign_data_${formattedDate}_${formattedTime}.xlsx`;
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'delivered':
                return 'purple';
            case 'read':
                return 'teal';
            case 'failed':
                return 'darkred';
            case 'sent':
                return 'dodgerblue';
            default:
                return 'black';
        }
    };

    const columns = [
        {
            title: (<Checkbox
                onChange={handleSelectAllCustomers}
                checked={selectedCustomers.length === filteredData.length}
                indeterminate={selectedCustomers.length > 0 && selectedCustomers.length < filteredData.length}
            />),
            dataIndex: 'customer_id',
            width: '5%',
            render: (_, record) => (
                <Checkbox
                    onChange={() => handleCustomerSelect(record.customer_id)}
                    checked={selectedCustomers.includes(record.customer_id)}
                />
            ),
        },
        {
            title: '#',
            dataIndex: 'wid',
            width: '10%',
            render: (_, __, index) => ((pageNumber - 1) * pageSize) + index + 1,
            sorter: true,
            sortOrder: sortField === 'wid' && sortOrder,
        },
        {
            title: 'Name',
            dataIndex: 'customer_name',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'customer_name' && sortOrder,
        },
        {
            title: 'Phone No.',
            dataIndex: 'wanumber',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'wanumber' && sortOrder,
        },
        {
            title: 'Template',
            dataIndex: 'message',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'message' && sortOrder,
        },
        {
            title: 'Attempted',
            dataIndex: 'reattempted',
            width: '10%',
            sorter: true,
            sortOrder: sortField === 'message' && sortOrder,
            render: (status) => (
                status === '4' ? '1st time': status === '3' ? '2nd time' : status === '2' ? '3rd time' : status === '1' ? '4th time' : ''
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '10%',
            sorter: true,
            sortOrder: sortField === 'status' && sortOrder,
            render: (status) => (
                <span style={{ color: getStatusColor(status) }} >{status && status.charAt(0).toUpperCase() + status.slice(1)}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            width: '10%',
            render: (_, record) => (
                <Popconfirm
                    title="Delete the Contact"
                    description="Are you sure to delete this Contact?"
                    onConfirm={() => handleDelete(record.customer_id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Tooltip title="Delete Customer">
                        <Button type="primary" className="deleteButton" danger shape="circle" icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>
            ),
        },
    ];

    const handleChangeStatus = ({ key }) => {
        setMessageStatus(key);
    };

    const items = [
        { key: '', label: 'All' },
        { key: 'sent', label: 'Sent' },
        { key: 'delivered', label: 'Delivered' },
        { key: 'read', label: 'Read' },
        { key: 'failed', label: 'Failed' },
    ];

    return (
        <Row>
            {exportLoading && (
                <div className='csvloader'>
                    <Flex align="center"
                        justify="center"
                        style={{ minHeight: '100vh' }}>
                        <div style={{ textAlign: 'center' }}>
                            <Spin size="large" />
                            <p>Seat relex and please do not refresh the page! We are fetching the details...</p>
                        </div>
                    </Flex>
                </div>
            )}
            <Col span={7}>
                <div style={{ display: 'flex', justifyContent: 'left', marginBottom: 15 }}>
                    <div>
                        <div>
                            <label><b>&nbsp;</b></label>
                        </div>
                        <Search placeholder="Search contacts by name or phone no." onChange={(e) => handleSearch(e.target.value)} style={{ marginBottom: '16px' }} />
                    </div>
                </div>
            </Col>
            <Col span={17}>
                <div style={{ display: 'flex', justifyContent: 'right', marginBottom: 15 }}>
                    <div>
                        <div>
                            <label><b>Filter By</b></label>
                        </div>
                        <Dropdown
                            menu={{
                                items,
                                onClick: handleChangeStatus,
                            }}
                            placement="bottomLeft"
                        >
                            <Button style={{ width: '120px', marginRight: '8px' }}>{messageStatus ? messageStatus.charAt(0).toUpperCase() + messageStatus.slice(1) : 'All'}</Button>
                        </Dropdown>
                    </div>
                    <div>
                        <div>
                            <label><b>Action</b></label>
                        </div>
                        <Button onClick={exportXLSXDataByStatus} icon={<ExportOutlined />} style={{ marginRight: '8px' }}>
                            Export to XLSX
                        </Button>
                    </div>
                    <div>
                        <div>
                            <label><b>&nbsp;</b></label>
                        </div>
                        <Button onClick={exportCSVDataByStatus} icon={<ExportOutlined />} style={{ marginRight: '8px' }}>
                            Export to CSV
                        </Button>
                    </div>
                    <div>
                        <div>
                            <label><b>&nbsp;</b></label>
                        </div>
                        {selectedCustomers.length !== 0 ? (
                            <Button type='primary' danger onClick={handleDeleteSelected} icon={<DeleteOutlined />} style={{ marginRight: '8px' }}>
                                Delete {selectedCustomers.length} Selected Contacts
                            </Button>
                        ) : (
                            <Button type='primary' danger onClick={handleDeleteAll} icon={<DeleteOutlined />} style={{ marginRight: '8px' }}>
                                Delete All {messageStatus && messageStatus.charAt(0).toUpperCase() + messageStatus.slice(1)} Contacts
                            </Button>
                        )}
                    </div>
                </div>
            </Col>
            <Col span={24}>
                {isLoading ? (
                    <Skeleton active />
                ) : (
                    <div style={{ position: 'relative' }}>
                        <Table
                            columns={columns}
                            dataSource={filteredData}
                            rowKey="customer_id"
                            pagination={false}
                            onChange={handleTableChange}
                            footer={() => (
                                <div style={{ textAlign: 'right', padding: '10px' }}>
                                    Total Items: {totalItems}
                                </div>
                            )}
                        />
                        <div style={{ position: 'sticky', background: 'white', bottom: '-24px', paddingBottom: 15, paddingTop: 15, zIndex: 1, display: 'flex', justifyContent: 'right' }}>
                            <Pagination
                                total={totalItems}
                                current={pageNumber}
                                pageSize={pageSize}
                                onChange={(page, pageSize) => {
                                    setPageNumber(page);
                                    setPageSize(pageSize);
                                }}
                                showSizeChanger
                            />
                        </div>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default ViewCampaignDetails;