import { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { MessageOutlined, CloseOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';

const { TextArea } = Input;

function AskQuestionNode({ data }) {
    const onCloseIconClick = () => {
        if (typeof data.onClose === 'function') {
            data.onClose(data.id);
        }
    };

    return (
        <>
            <Handle type="target" position={Position.Top} />
            <div className='nodes'>
                <div className='header'>
                    <p><MessageOutlined /> {data.label}</p>
                    <CloseOutlined
                        className="closeIcon"
                        onClick={onCloseIconClick}
                    />
                </div>
                <div className='body'>
                    <div className='inputMainBox' draggable="false">
                        <TextArea
                            maxLength={1024}
                            rows={5}
                            placeholder="Type Message"
                            allowClear
                            onChange={(e) => data.onMainTextAreaChange(data.id, e.target.value)}
                            className="nodrag"
                            value={data.nodeData?.mainText}
                        />
                        <br />
                        <label>Attribute format</label>
                        <select style={{ width: '100%', border: '1px solid #ddd', height: '35px', borderRadius: '6px', marginBottom:10 }} value={data.nodeData?.attributeFormat} onChange={(e) => data.onAttributeFormatChange(data.id, e.target.value)}>
                            <option value="any">Any</option>
                            <option value="text">Text</option>
                            <option value="number">Number</option>
                            <option value="date">Date</option>
                            <option value="truefalse">True/False</option>
                            <option value="email">Email</option>
                            <option value="regex">Regex</option>
                        </select>
                        <TextArea
                            maxLength={1024}
                            rows={5}
                            placeholder="Enter Validation Error"
                            allowClear
                            onChange={(e) => data.onMainTextAreaErrorChange(data.id, e.target.value)}
                            className="nodrag"
                            value={data.nodeData?.questionError}
                        />
                        <Handle type="source" position={Position.Right} id={`${data.id}`} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AskQuestionNode;