import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Divider, Switch, message } from 'antd';
import { SettingOutlined, RetweetOutlined } from '@ant-design/icons';
const { Title, Paragraph, Text } = Typography;

const Profile = (userData) => {
    const {setUserData} = userData;
    const [loading, setLoading] = useState(false);
    const handleScheduleEnableCheckbox = async (status) => {
        const crm_user_id = userData?.userData[0]?.user_id;
        try {
            setLoading(true);
            const response = await fetch(`https://connectby.io:3001/api/update-schedule-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: crm_user_id,
                    status: status,
                }),
            });
            if (response.ok) {
                setUserData(prevState => {
                    return prevState.map((item, index) => 
                        index === 0 ? { ...item, schduleenable: item.schduleenable === '1' ? '2' : '1' } : item
                    );
                });
                message.success('Updated successfully');
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }
        } catch (error) {
            console.log(error);
            console.error('Error updating:', error);
        } finally {
            setLoading(false);
        }
    };



    return (
        <Row>
            <Col span={24}>
                <Typography>
                    <Title level={3}> <SettingOutlined /> General Settings</Title>
                    <Paragraph>Manage access to Connectby.io features. Read here to read more about this section in detail.</Paragraph>
                </Typography>
                <Row>
                    <Col span={24}>
                        <Typography>
                            <Title level={4}> <RetweetOutlined /> Enable/Disable Re-attempt of failed messages</Title>
                            <Col span={24} style={{ marginLeft: '25px' }}>
                                <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Text>By enabling this re-attempt of failed messages will occur after 24 hours.</Text>
                                    <Switch checked={userData?.userData[0]?.schduleenable === '1' && true} loading={loading} onClick={() => handleScheduleEnableCheckbox(userData?.userData[0]?.schduleenable === '1' ? '2' : '1')} />
                                </Paragraph>
                                <Divider className='darkDevider' />
                            </Col>
                        </Typography>
                    </Col>
                </Row >
            </Col>
        </Row>
    );
};

export default Profile;