import React, {useState} from 'react';
import { Handle, Position } from 'reactflow';
import { AddOutlined, DeleteOutline } from '@mui/icons-material';
import { ApartmentOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';

function InitialFlow({ data }) {
    const { handleInputChange, handleAddInputBox, handleDelete, inputBoxes, inFlow } = data;
    // const [inputBoxes, setInputBoxes] = useState([{ id: 1, value: '' }]);
    // const handleInputChange = (e, id) => {
    //     const newValue = e.target.value;
    //     setInputBoxes((prevInputBoxes) =>
    //       prevInputBoxes.map((box) => (box.id === id ? { ...box, value: newValue } : box))
    //     );
    //   };

    //   const handleAddInputBox = () => {
    //     const lastId = inputBoxes[inputBoxes.length - 1].id;
    //     setInputBoxes((prevInputBoxes) => [
    //       ...prevInputBoxes,
    //       { id: lastId + 1, value: '' }
    //     ]);
    //   };

    //   const handleDelete = (id) => {
    //     setInputBoxes((prevInputBoxes) => prevInputBoxes.filter((box) => box.id !== id));
    //   };

    return (
        <div className='nodes'>
            <div className='header'>
                <p><ApartmentOutlined /> {data.label}</p>
            </div>
            <div className='body'>
                <div className='inputMainBox'>
                    <div className='inBox'>
                        {inputBoxes.map((box, i) => (
                            <div key={box.id} className='inputBox'>
                                <input
                                    type='text'
                                    placeholder='Type Message'
                                    maxLength='200'
                                    value={box.value}
                                    onChange={(e) => handleInputChange(e, box.id)}
                                />
                                <Button onClick={() => handleDelete(box.id)}>
                                    <DeleteOutline />
                                </Button>
                            </div>
                        ))}
                        <Checkbox>Partial match</Checkbox>
                    </div>
                    <Button className='addMoreBtn' onClick={handleAddInputBox}>Add Keyword <AddOutlined /></Button>
                </div>
            </div>
            <Handle type="source" position={Position.Bottom} id={`${data.id}`} />
        </div>
    );
}

export default InitialFlow;