import {
    WhatsAppOutlined,
    DashboardOutlined,
    InboxOutlined,
    NotificationOutlined,
    ContactsOutlined,
    AppstoreOutlined,
    ApiOutlined,
    RobotOutlined,
    SettingOutlined,
    InteractionOutlined,
    DollarOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    DownloadOutlined,
    LogoutOutlined,
    SwapOutlined,
    TagsOutlined,
    ShopOutlined
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/blogo.png';
import collapsedLogo from '../assets/favicon.png';
const { SubMenu } = Menu;
const { Sider } = Layout;
const Sidebar = ({ collapsed, userData }) => {
    console.log(userData);
    const location = useLocation();
    const logoSrc = collapsed ? collapsedLogo : logo;
    const defaultOpenKeys = ['/', '/dashboard', '/inbox', '/campaign', '/templates', '/contacts', '/tags'].some(path => location.pathname.startsWith(path)) ? ['sub1'] : [];
    const {
        token: { colorBgContainer },
    } = theme.useToken();


    const userId = sessionStorage.getItem('redirectId');
    const handleLogout = () => {
        sessionStorage.removeItem('redirectId');
        window.location.href = 'https://connectby.io/login';
    };

    const handleSwitch = () => {
        window.location.href = `https://crm.connectby.io/crm_module/redirect/${userId}`;
    };

    const handleDownload = () => {
        // window.open(`https://app.connectby.io/app/connectbyio01.apk`, '_blank');
    };

    const menuItems = [
        {
            key: 'sub1',
            label: '',
            icon: '',
            onClick: handleDownload,
        },
        {
            key: 'sub2',
            label: 'Logout',
            icon: <LogoutOutlined className='menuIcons' />,
            onClick: handleLogout,
        },

        // {
        //   key: 'sub2',
        //   label: userData[0]?.user_name,
        //   icon: <UserOutlined className='menuIcons' />,
        //   children: [
        //     {
        //       key: '5',
        //       label: 'Profile',
        //       icon: <UserOutlined className='menuIcons' />
        //     },
        //     {
        //       key: '6',
        //       label: 'Settings',
        //       icon: <SettingOutlined className='menuIcons' />
        //     },
        //     {
        //       key: 'sub3',
        //       label: 'Logout',
        //       icon: <LogoutOutlined className='menuIcons' />,
        //       onClick: handleLogout
        //     },
        //   ],
        // }
    ];

    if (userData[0]?.subscribe_for === 'both') {
        menuItems.splice(1, 0, {
            key: 'sub4',
            label: 'Switch to CRM',
            icon: <SwapOutlined className='menuIcons' />,
            onClick: handleSwitch,
        });
    }

    return (
        <Sider style={{ background: colorBgContainer }} trigger={null} collapsible collapsed={collapsed}>
            <div className="logo-vertical">
                <img src={logoSrc} alt="Logo" style={{ width: '100%' }} />
            </div>
            <Menu theme="light" mode="inline" className={(userData[0]?.subscribe_for === 'both') ? 'sidebarmenuboth mainsidebarmenu' : 'sidebarmenu mainsidebarmenu'}>
                <Menu.Item key="/dashboard" icon={<DashboardOutlined className='menuIcons' />}>
                    <Link to='/dashboard'>Dashboard</Link>
                </Menu.Item>
                <Menu.Item key="/inbox" icon={<InboxOutlined className='menuIcons' />}>
                    <Link to='/inbox'>Inbox</Link>
                </Menu.Item>
                <Menu.Item key="/campaign" icon={<NotificationOutlined className='menuIcons' />}>
                    <Link to='/campaign'>Campaign</Link>
                </Menu.Item>
                <Menu.Item key="/templates" icon={<AppstoreOutlined className='menuIcons' />}>
                    <Link to='/templates'>Template</Link>
                </Menu.Item>
                <Menu.Item key="/contacts" icon={<ContactsOutlined className='menuIcons' />}>
                    <Link to='/contacts'>Contacts</Link>
                </Menu.Item>
                <Menu.Item key="/automation" icon={<RobotOutlined className='menuIcons' />}>
                    <Link to='/automation'>Automation</Link>
                </Menu.Item>
                {/* <Menu.Item key="/tags" icon={<TagsOutlined className='menuIcons' />}>
                    <Link to='/tags'>Tags</Link>
                </Menu.Item>
                <Menu.Item key="/team" icon={<UsergroupAddOutlined className='menuIcons' />}>
                    <Link to='/team'>Team</Link>
                </Menu.Item> */}
                <Menu.Item key="/payments" icon={<DollarOutlined className='menuIcons' />}>
                    <Link to='/payments'>Payments</Link>
                </Menu.Item>
                <Menu.Item key="/ecommerce" icon={<ShopOutlined className='menuIcons' />}>
                    <Link to="/ecommerce">E-commerce</Link>
                </Menu.Item>
                <Menu.Item key="/settings" icon={<SettingOutlined className='menuIcons' />}>
                    <Link to='/settings'>Settings</Link>
                </Menu.Item>
                <Menu.Item onClick={handleLogout} key="" icon={<LogoutOutlined className='menuIcons' />}>
                    <span>Logout</span>
                </Menu.Item>
            </Menu>
            {/* <Menu theme="light" mode="vertical" className='bottomMenu' items={menuItems} /> */}
        </Sider>
    );
};
export default Sidebar;