import { useEffect } from 'react';
import MobileDetect from 'mobile-detect';

const RedirectMobile = () => {
  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile()) {
      window.location.href = 'https://m.connectby.io';
    }
  }, []);

  return null;
};

export default RedirectMobile;