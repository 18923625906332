import { useState, useRef, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { AddOutlined } from '@mui/icons-material';
import { MessageOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

const { TextArea } = Input;

function ListNode({ data }) {
    console.log('listnode', data);
    const [inputCount, setInputCount] = useState(0);
    const [itemInputCounts, setItemInputCounts] = useState({});
    const textAreaRef = useRef(null);
    const textAreaRefOne = useRef(null);
    const textAreaRefTwo = useRef(null);
    const textAreaRefThree = useRef(null);
    const textAreaRefs = useRef([useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]);
    useEffect(() => {
        const count = data && data.nodeData && data.nodeData.additionalTexts ? Object.keys(data.nodeData.additionalTexts).length : 0;
        setInputCount(count);
        if (data?.nodeData && data?.nodeData?.additionalTexts) {
            const initialCounts = {};
            Object.keys(data.nodeData.additionalTexts).forEach(key => {
                initialCounts[key] = Object.keys(data.nodeData.additionalTexts[key]).length - 1;
            });
            setItemInputCounts(initialCounts);
        }
    }, [data]);

    const handleAddButton = () => {
        if (inputCount < 10) {
            setInputCount(inputCount + 1);
            setItemInputCounts({
                ...itemInputCounts,
                [inputCount]: 0
            });
        }
    };

    const onCloseIconClick = () => {
        if (typeof data.onClose === 'function') {
            data.onClose(data.id);
        }
    };

    const handleRemoveButton = (index) => {
        const newInputs = [...Array(inputCount).keys()].filter((i) => i !== index);
        setInputCount(newInputs.length);

        const newItemInputCounts = { ...itemInputCounts };
        delete newItemInputCounts[index];
        setItemInputCounts(newItemInputCounts);
    };

    const handleRemoveItemButton = (key, index) => {
        const newItemInputCounts = { ...itemInputCounts };
        newItemInputCounts[key] = [...Array(itemInputCounts[key]).keys()].filter((i) => i !== index).length;
        setItemInputCounts(newItemInputCounts);
    };

    const handleAddItemButton = (key) => {
        if (itemInputCounts[key] < 10) {
            setItemInputCounts({
                ...itemInputCounts,
                [key]: itemInputCounts[key] + 1
            });
        }
    };

    const handleAdditionalTextAreaChangeListTitle = (id, i, key, title, e, index) => {
        const textAreaElement = textAreaRefs.current[index + 1]?.current?.resizableTextArea?.textArea;
        if (textAreaElement) {
            const cursorPosition = textAreaElement.selectionStart;
            data.onAdditionalTextAreaChangeTitle(id, i, key, title, e.target.value);
            setTimeout(() => {
                textAreaElement.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
        }
    }
    
    const renderAdditionalItemInputs = (key) => {
        const inputs = [];
        for (let i = 0; i < itemInputCounts[key]; i++) {
            inputs.push(
                <div key={`${key}-${i}`} className='itemdiv'>
                    <Button
                        className="removeButtonOne"
                        onClick={() => handleRemoveItemButton(key, i)}
                    >
                        <CloseOutlined />
                    </Button>
                    <TextArea
                        maxLength={24}
                        rows={2}
                        placeholder={`List Title ${i + 1}`}
                        count={{ show: true, max: 24 }}
                        // onChange={(e) => handleAdditionalTextAreaChangeListTitle(data.id, i, key, 'title', e, textAreaRefs.current[i])}
                        onChange={(e) => data.onAdditionalTextAreaChangeTitle(data.id, i, key, 'title', e.target.value)}
                        className="nodrag listtitlebox"
                        defaultValue={data?.nodeData?.additionalTexts?.[key]?.[i]?.title}
                        // ref={textAreaRefs}
                    />
                    <TextArea
                        maxLength={24}
                        rows={2}
                        placeholder={`Description ${i + 1}`}
                        count={{ show: true, max: 24 }}
                        // onChange={(e) => data.onAdditionalTextAreaChangeDescription(data.id, i, key, 'desc', e.target.value)}
                        className="nodrag"
                        defaultValue={data?.nodeData?.additionalTexts?.[key]?.[i]?.desc}
                        style={{ display: 'none' }}
                    />
                    <Handle type="source" position={Position.Right} id={`${key}${data.id}${i}`} />
                </div>
            );
        }
        return inputs;
    };


    const handleMainTextAreaChange = (e) => {
        const textAreaElement = textAreaRef.current.resizableTextArea?.textArea;
        if (textAreaElement) {
            const cursorPosition = textAreaElement.selectionStart;
            data.onMainTextAreaHeaderChange(data.id, e.target.value);
            setTimeout(() => {
                textAreaElement.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
        }
    };
    const handleMainTextAreaChangeOne = (e) => {
        const textAreaElement = textAreaRefOne.current.resizableTextArea?.textArea;
        if (textAreaElement) {
            const cursorPosition = textAreaElement.selectionStart;
            data.onMainTextAreaChange(data.id, e.target.value);
            setTimeout(() => {
                textAreaElement.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
        }
    };
    const handleMainTextAreaChangeTwo = (e) => {
        const textAreaElement = textAreaRefTwo.current.resizableTextArea?.textArea;
        if (textAreaElement) {
            const cursorPosition = textAreaElement.selectionStart;
            data.onMainTextAreaFooterChange(data.id, e.target.value);
            setTimeout(() => {
                textAreaElement.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
        }
    };
    const handleMainTextAreaChangeThree = (e) => {
        const textAreaElement = textAreaRefThree.current.resizableTextArea?.textArea;
        if (textAreaElement) {
            const cursorPosition = textAreaElement.selectionStart;
            data.onMainTextAreaBtnChange(data.id, e.target.value);
            setTimeout(() => {
                textAreaElement.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
        }
    };

    const handleAdditionalTextAreaChangeSecTitle = (id, i, sectitle, e) => {
        const textAreaElement = textAreaRefs.current[i + 1]?.current?.resizableTextArea?.textArea;
        if (textAreaElement) {
            const cursorPosition = textAreaElement.selectionStart;
            data.onAdditionalTextAreaChangeOne(id, i, sectitle, e.target.value);
            setTimeout(() => {
                textAreaElement.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
        }
    }

    const renderAdditionalInputs = () => {
        const inputs = [];
        for (let i = 0; i < inputCount; i++) {
            inputs.push(
                <div key={i} className="additionalInputList">
                    <div style={{ position: 'relative', marginBottom: '5px' }}>
                        <Button
                            className="removeButtonOne"
                            onClick={() => handleRemoveButton(i)}
                        >
                            <CloseOutlined />
                        </Button>
                        <TextArea
                            maxLength={24}
                            rows={2}
                            placeholder={`Type Section Name ${i + 1} (Required)`}
                            count={{ show: true, max: 24 }}
                            // onChange={(e) => handleAdditionalTextAreaChangeSecTitle(data.id, i, 'sectitle', e.target.value)}
                            onChange={(e) => data.onAdditionalTextAreaChangeOne(data.id, i, 'sectitle', e.target.value)}
                            className="nodrag"
                            defaultValue={data?.nodeData?.additionalTexts?.[i]?.sectitle}
                            // ref={textAreaRefs}
                        />
                    </div>
                    <div>
                        {renderAdditionalItemInputs(i)}
                        <Button
                            className='addMoreItemBtn'
                            onClick={() => handleAddItemButton(i)}
                        >
                            Add Item <AddOutlined />
                        </Button>
                    </div>
                </div>
            );
        }
        return inputs;
    };

    return (
        <>
            <Handle type="target" position={Position.Top} />
            <div className='nodes'>
                <div className='header'>
                    <p><MessageOutlined /> {data.label}</p>
                    <CloseOutlined
                        className="closeIcon"
                        onClick={onCloseIconClick}
                    />
                </div>
                <div className='body'>
                    <div className='inputMainBox'>
                        <TextArea
                            maxLength={20}
                            rows={1}
                            placeholder="Header Text (Optional)"
                            count={{ show: true, max: 20 }}
                            allowClear
                            onChange={(e) => data.onMainTextAreaHeaderChange(data.id, e.target.value)}
                            // onChange={handleMainTextAreaChange}
                            className="nodrag"
                            defaultValue={data.nodeData?.headerText}
                            // ref={textAreaRef}
                        />
                        <TextArea
                            maxLength={1024}
                            rows={3}
                            placeholder="Body text (Required)"
                            count={{ show: true, max: 1024 }}
                            allowClear
                            onChange={(e) => data.onMainTextAreaChange(data.id, e.target.value)}
                            // onChange={handleMainTextAreaChangeOne}
                            className="nodrag"
                            defaultValue={data.nodeData?.mainText}
                            // ref={textAreaRefOne}
                        />
                        <TextArea
                            maxLength={60}
                            rows={1}
                            placeholder="Footer Text (Optional)"
                            count={{ show: true, max: 60 }}
                            allowClear
                            onChange={(e) => data.onMainTextAreaFooterChange(data.id, e.target.value)}
                            // onChange={handleMainTextAreaChangeTwo}
                            className="nodrag"
                            defaultValue={data.nodeData?.footerText}
                            // ref={textAreaRefTwo}
                        />
                        <TextArea
                            maxLength={20}
                            rows={1}
                            placeholder="Button Title (Required)"
                            count={{ show: true, max: 20 }}
                            allowClear
                            onChange={(e) => data.onMainTextAreaBtnChange(data.id, e.target.value)}
                            // onChange={handleMainTextAreaChangeThree}
                            className="nodrag"
                            defaultValue={data.nodeData?.btnText}
                            style={{ marginTop: 10 }}
                            // ref={textAreaRefThree}
                        />
                        {renderAdditionalInputs()}
                        <Button
                            className='addMoreBtn'
                            onClick={handleAddButton}
                        >
                            Add Section <AddOutlined />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListNode;