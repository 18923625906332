import React, { useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    SettingOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { Layout, Button, theme, Avatar, Space, Dropdown } from 'antd';
const { Header } = Layout;
const HeaderComponent = ({ onToggleCollapse, userData }) => {
    console.log(userData);
    const userId = sessionStorage.getItem('redirectId');
    const handleLogout = () => {
        sessionStorage.removeItem('redirectId');
        window.location.href = 'https://connectby.io/login';
        // Perform other logout actions if needed
    };
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const items = [
        {
            key: '1',
            label: (
                <Button type="link"><UserOutlined /> Profile</Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button type="link"><SettingOutlined /> Settings</Button>
            ),
        },
        {
            key: '3',
            label: (
                <Button type="link" onClick={handleLogout}><LogoutOutlined /> Logout</Button>
            ),
        },
    ];

    return (
        <>
            {userData[0]?.start_date === "" ? (
                <Header
                    style={{
                        padding: 0,
                        background: 'linear-gradient(135deg, #075e54, #04befe)',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff'
                    }}
                >
                    <p><span dangerouslySetInnerHTML={{ __html: userData[0]?.plan_expire_bar }}></span> {userData[0]?.renew_link && <a style={{ background: '#fff', padding: '10px', borderRadius: '5px', fontSize: '15px', fontWeight: 'bold', marginLeft: '10px' }} href={userData[0]?.renew_link || '#'}>Renew Now</a>}</p>
                </Header>
            ) : userData[0]?.end_date && userData[0]?.end_date <= (Math.floor(Date.now() / 1000) + (7 * 24 * 60 * 60)) && (
                <>
                    {userData[0]?.end_date <= Math.floor(Date.now() / 1000) ? (
                        <Header
                            style={{
                                padding: 0,
                                background: 'linear-gradient(135deg, #075e54, #04befe)',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#fff'
                            }}
                        >
                            <p><span dangerouslySetInnerHTML={{ __html: userData[0]?.plan_expire_bar }}></span> {userData[0]?.renew_link && <a style={{ background: '#fff', padding: '10px', borderRadius: '5px', fontSize: '15px', fontWeight: 'bold', marginLeft: '10px' }} href={userData[0]?.renew_link || '#'}>Renew Now</a>}</p>
                        </Header>
                    ) : (
                        <Header
                            style={{
                                padding: 0,
                                background: 'linear-gradient(135deg, #075e54, #04befe)',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#fff'
                            }}
                        >
                            <p><span dangerouslySetInnerHTML={{ __html: userData[0]?.plan_expire_bar }}></span> {userData[0]?.renew_link && <a style={{ background: '#fff', padding: '10px', borderRadius: '5px', fontSize: '15px', fontWeight: 'bold', marginLeft: '10px' }} href={userData[0]?.renew_link || '#'}>Renew Now</a>}</p>
                            {/* <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => {
                    setCollapsed(!collapsed);
                    onToggleCollapse();
                }}
                style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                }}
            />
        {(userData[0]?.subscribe_for === 'both') ?(
            <a href={`https://crm.connectby.io/crm_module/redirect/${userId}`}>
            <Button
                type="primary"
                onClick={() => {
                }}
                style={{position:'absolute', right:80, top:15}}
            >
                Switch to CRM
        </Button>
        </a>
            ):(null)}
            <Dropdown menu={{ items }} placement="bottomRight">
                <Button className="profilemenu" type="text" style={{ margin: '15px' }} size="small">
                    <Space wrap size={16}>
                        <Avatar icon={<UserOutlined />} />
                    </Space>
                </Button>
            </Dropdown> */}
                        </Header>
                    )}
                </>
            )}
        </>
    );
};
export default HeaderComponent;