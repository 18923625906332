// Footer.js
import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterComponent = () => {
    return (
        <Footer
            style={{
                textAlign: 'center',
            }}
        >
            ©{new Date().getFullYear()} Created by Connectby.io
        </Footer>
    );
};

export default FooterComponent;