import React, { useEffect, useState } from 'react';
import { Switch, Radio, Card, InputNumber, Modal, Table, Skeleton, Dropdown, Pagination, List, Button, Menu, Flex, message, Image, Input, Tooltip, Spin, Popconfirm, Col, Row, Drawer, Form, Select, Space, DatePicker, Typography, Checkbox, Layout, Upload } from 'antd';
import { ShoppingCartOutlined, BranchesOutlined, SettingOutlined, RightCircleFilled } from '@ant-design/icons';
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { Header, Content } = Layout;
const { TextArea } = Input;
const { Meta } = Card;

const ProductImage = require('../assets/product.jpeg');


const CheckoutFlow = (userData) => {
    const total_order_value = '{total_order_value}';
    const address = '{address}';
    const [isShippingCost, setIsShippingCost] = useState(1);
    const [isDiscount, setIsDiscount] = useState(2);
    const [appliesShippingTo, setAppliesShippingTo] = useState(1);
    const [shippingCost, setShippingCost] = useState(0);
    const [shippingCartValueBelow, setShippingCartValueBelow] = useState(0);
    const [proceedFlow, setProceedFlow] = useState('yes');
    const [proceedAddressFlow, setProceedAddressFlow] = useState('yes');
    const [paymentOption, setPaymentOption] = useState('cod');
    const [yesText, setYesText] = useState('Yes');
    const [noText, setNoText] = useState('No');
    const [yesAddressText, setYesAddressText] = useState('Yes');
    const [noAddressText, setNoAddressText] = useState('No');
    const [changeAddressText, setChangeAddressText] = useState('Change Address');
    const [codBtnText, setCodBtnText] = useState('COD');
    const [onlineBtnText, setOnlineBtnText] = useState('Online');
    const [firstFlowHeader, setFirstFlowHeader] = useState('Thanks for your cart!');
    const [firstFlowBodyLine, setFirstFlowBodyLine] = useState('We currently deliver for free all over India.<br/>Your total Order Value =');
    const [secondFlowBodyLine, setSecondFlowBodyLine] = useState('<br />Would you like to proceed?');
    const [addressFlowHeader, setAddressFlowHeader] = useState('Great! We will require some details to ship the order.');
    const [addressFlowBody, setAddressFlowBody] = useState('Please provide your Shipping address.');
    const [changeAddressFlowHeader, setChangeAddressFlowHeader] = useState('Great! We will require some details to ship the order.');
    const [changeAddressFlowBody, setChangeAddressFlowBody] = useState('Please provide your Shipping address.');
    const [confirmAddressFlowHeader, setConfirmAddressFlowHeader] = useState('Thanks for providing the details!');
    const [confirmPaymentOptionHeader, setConfirmPaymentOptionHeader] = useState('Great! Please Select Payment option.');
    const [confirmPaymentOptionBody, setConfirmPaymentOptionBody] = useState('How would you like to pay?');
    const [confirmAddressFlowBodyLineOne, setConfirmAddressFlowBodyLineOne] = useState('We have noted your address as: ');
    const [confirmAddressFlowBodyLineTwo, setConfirmAddressFlowBodyLineTwo] = useState('Would you like to confirm the order?');
    const [orderConfirmFlowHeader, setOrderConfirmFlowHeader] = useState('Your Order is Placed!');
    const [paymentFailedFlowHeader, setPaymentFailedFlowHeader] = useState('Oops! 😔');
    const [paymentFailedFlowBodyLineOne, setPaymentFailedFlowBodyLineOne] = useState('Payment failed, Try again using above buttons.');
    const [orderConfirmFlowBodyLineOne, setOrderConfirmFlowBodyLineOne] = useState('Hey ');
    const [orderConfirmFlowBodyLineTwo, setOrderConfirmFlowBodyLineTwo] = useState('<br/>Thanks for confirming.<br/>We are getting your order ready and will send you further updates soon.');
    const [questionOneonNo, setQuestionOneonNo] = useState('Sure, how can we help you?');
    const [customerReplyonNo, setCustomerReplyonNo] = useState('Noted! One of our representatives will take a look at this and get back to you at the earliest.');
    const [discountValue, setDiscountValue] = useState(0);
    const [discountType, setDiscountType] = useState(1);
    const [discountAppliesTo, setDiscountAppliesTo] = useState(1);
    const [discountOnMinAmt, setDiscountOnMinAmt] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState(['cod']);
    const [isActive, setisActive] = useState(2);
    const [loading, setLoading] = useState(false);
    const [ecomFlowId, setEcomFlowId] = useState(0);
    const [dataLoading, setDataLoading] = useState(true);
    const onChangeShippingCost = (e) => {
        console.log('radio checked', e.target.value);
        setIsShippingCost(e.target.value);
    };

    const onChangeDiscount = (e) => {
        console.log('radio checked', e.target.value);
        setIsDiscount(e.target.value);
    };

    const onChangeDiscountAppliesTo = (e) => {
        setDiscountAppliesTo(e.target.value);
    };

    const onChangeDiscountType = (value) => {
        setDiscountType(value);
    };

    const onChangeAppliesShippingTo = (e) => {
        console.log('radio checked', e.target.value);
        setAppliesShippingTo(e.target.value);
    };

    const paymentOptions = [
        {
            label: 'Cash on Delivery',
            value: 'cod',
        },
        {
            label: 'Online Payment',
            value: 'online',
        }
    ];

    const onChangePaymentOptions = (checkedValues) => {
        setSelectedOptions(checkedValues);
        console.log('Selected Payment Options:', checkedValues);
    };

    useEffect(() => {
        getEcomSettings(userData);
    }, [userData]);

    const getEcomSettings = async (userData) => {
        const userId = userData?.userData[0]?.user_id;
        fetch('https://connectby.io:3001/api/get-ecom-setting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {
            if (data && data?.[0]) {
                setEcomFlowId(data?.[0]?.ecom_flow_id);
                setFirstFlowHeader(data?.[0]?.first_flow_header);
                setFirstFlowBodyLine(data?.[0]?.first_flow_body_line);
                setSecondFlowBodyLine(data?.[0]?.second_flow_body_line);
                setAddressFlowHeader(data?.[0]?.address_flow_header);
                setAddressFlowBody(data?.[0]?.address_flow_body);
                setChangeAddressFlowHeader(data?.[0]?.change_address_flow_header);
                setChangeAddressFlowBody(data?.[0]?.change_address_flow_body);
                setConfirmAddressFlowHeader(data?.[0]?.confirm_address_flow_headr);
                setConfirmAddressFlowBodyLineOne(data?.[0]?.confirm_address_flow_body_line_one);
                setConfirmAddressFlowBodyLineTwo(data?.[0]?.confirm_address_flow_body_line_two);
                setOrderConfirmFlowHeader(data?.[0]?.order_confirm_flow_header);
                setOrderConfirmFlowBodyLineOne(data?.[0]?.order_confirm_flow_body_line_one);
                setOrderConfirmFlowBodyLineTwo(data?.[0]?.order_confirm_flow_body_line_two);
                setQuestionOneonNo(data?.[0]?.question_one_on_no);
                setCustomerReplyonNo(data?.[0]?.customer_reply_on_no);
                setIsShippingCost(data?.[0]?.is_shipping_cost);
                setIsDiscount(data?.[0]?.is_discount);
                setAppliesShippingTo(data?.[0]?.is_shipping_applies_to);
                setShippingCost(data?.[0]?.shipping_cost);
                setShippingCartValueBelow(data?.[0]?.min_cart_amt_shipping);
                setDiscountType(data?.[0]?.discount_type);
                setDiscountAppliesTo(data?.[0]?.discount_applies_to);
                setDiscountOnMinAmt(data?.[0]?.min_cart_value_discount);
                setSelectedOptions(data?.[0]?.paymentoptions && JSON.parse(data?.[0]?.paymentoptions));
                setisActive(data?.[0]?.is_active);
                setPaymentOption(data?.[0]?.paymentoptions && JSON.parse(data?.[0]?.paymentoptions)?.[0]);
                setDiscountValue(data?.[0]?.discount);
            }
        }).catch(error => {
            console.error('Error fetching notes:', error);
        }).finally(() => {
            setDataLoading(false);
        });
    };

    const handleEcomFlow = async () => {
        const crm_user_id = userData?.userData[0]?.user_id;
        const data = {
            'crm_user_id': crm_user_id,
            'ecom_flow_id': ecomFlowId,
            'first_flow_header': firstFlowHeader,
            'first_flow_body_line': firstFlowBodyLine,
            'second_flow_body_line': secondFlowBodyLine,
            'address_flow_header': addressFlowHeader,
            'address_flow_body': addressFlowBody,
            'change_address_flow_header': changeAddressFlowHeader,
            'change_address_flow_body': changeAddressFlowBody,
            'confirm_address_flow_headr': confirmAddressFlowHeader,
            'confirm_address_flow_body_line_one': confirmAddressFlowBodyLineOne,
            'confirm_address_flow_body_line_two': confirmAddressFlowBodyLineTwo,
            'order_confirm_flow_header': orderConfirmFlowHeader,
            'order_confirm_flow_body_line_one': orderConfirmFlowBodyLineOne,
            'order_confirm_flow_body_line_two': orderConfirmFlowBodyLineTwo,
            'question_one_on_no': questionOneonNo,
            'customer_reply_on_no': customerReplyonNo,
            'is_shipping_cost': isShippingCost,
            'is_discount': isDiscount,
            'is_shipping_applies_to': appliesShippingTo,
            'shipping_cost': shippingCost,
            'min_cart_amt_shipping': shippingCartValueBelow,
            'discount_type': discountType,
            'discount_applies_to': discountAppliesTo,
            'discount': discountValue,
            'min_cart_value_discount': discountOnMinAmt,
            'paymentoptions': selectedOptions,
            'payment_failed_body_line' : paymentFailedFlowBodyLineOne,
            'payment_failed_header': paymentFailedFlowHeader,
            'confirm_payment_option_body': confirmPaymentOptionBody,
            'confirm_payment_option_header': confirmPaymentOptionHeader,
            'is_active': isActive,
        };
        try {
            setLoading(true);
            const response = await fetch(`https://connectby.io:3001/api/update-ecom-settings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                message.success('Updated successfully');
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }
        } catch (error) {
            console.log(error);
            console.error('Error updating:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {dataLoading ? (
                <Flex align="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}>
                    <Spin size="large" />
                </Flex>
            ) : (
                <Row>
                    <Col span={16}>
                        <div className=''>
                            <div className='from-flow'>
                                <Card className='product-flow-cart'>
                                    <span className='title'><BranchesOutlined /> Flow Starting point</span>
                                    <div className='cartdet'>
                                        <div className='prodcut'>
                                            <Image src={ProductImage} alt="Product" className='product-image' />
                                            <Text><ShoppingCartOutlined /> 3 items <br />₹ 10,000.00 (estimated total)</Text>
                                        </div>
                                        <p>Hey I love these products. Would like to buy them</p>
                                    </div>
                                    <div className='cartBtn'>
                                        <p>View Sent Cart</p>
                                    </div>
                                </Card>
                            </div>
                            <div className='to-flow'>
                                <Card className='product-flow-cart'>
                                    <div className='cartdet'>
                                        <div><p><b className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setFirstFlowHeader(e.currentTarget.innerHTML)} >Thanks for your cart!</b></p><br /><span className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setFirstFlowBodyLine(e.currentTarget.innerHTML)}>We currently deliver for free all over India. <br /> Your total Order Value = </span> <strong>{total_order_value}</strong><span className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setSecondFlowBodyLine(e.currentTarget.innerHTML)}>.<br />Would you like to proceed?</span></div>
                                    </div>
                                    <div className='BottomBtnsDiv'>
                                        <div className='BottomBtns BtnOne' style={{ backgroundColor: proceedFlow === 'yes' ? '#000' : '#eee', color: proceedFlow === 'yes' ? '#fff' : '#000' }} onClick={() => setProceedFlow('yes')}>
                                            <p className="editable-container" contentEditable={false} suppressContentEditableWarning={false} onInput={(e) => setYesText(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Yes</p>
                                        </div>
                                        <div className='BottomBtns BtnTwo' style={{ backgroundColor: proceedFlow === 'no' ? '#000' : '#eee', color: proceedFlow === 'no' ? '#fff' : '#000' }} onClick={() => setProceedFlow('no')}>
                                            <p className="editable-container" contentEditable={false} suppressContentEditableWarning={false} onInput={(e) => setNoText(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>No</p>
                                        </div>
                                    </div>
                                    {/* <div className='cartConfBtn'>
                                <p><SettingOutlined /> Confirm Shipping Costs & Discounts <span style={{ float: 'right' }}><RightCircleFilled /></span></p>
                            </div> */}
                                </Card>
                            </div>
                            {proceedFlow === 'yes' ? (
                                <>
                                    <div className='from-flow'>
                                        <Card className='product-flow-cart'>
                                            <div className='BottomBtnDiv'>
                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                    <p>{yesText}</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className='to-flow'>
                                        <Card className='product-flow-cart'>
                                            <div className='BottomBtnDiv'>
                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                    <p><b className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setAddressFlowHeader(e.currentTarget.innerHTML)} >Great! We will require some details to ship the order.</b></p><br />
                                                    <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setAddressFlowBody(e.currentTarget.innerHTML)}>Please provide your Shipping address.</p>
                                                </div>
                                                <div className='BottomBtnDiv' style={{ marginTop: 10 }}>
                                                    <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                        <p>Provide Address</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className='from-flow'>
                                        <Card className='product-flow-cart'>
                                            <div className='BottomBtnDiv'>
                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                    <p>&lt;Customer enters street address, building name/number, flat number, floor etc.&gt;</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className='to-flow'>
                                        <Card className='product-flow-cart'>
                                            <div className='BottomBtnDiv'>
                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                    <p><b className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setConfirmAddressFlowHeader(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Thanks for providing the details!</b></p>
                                                    <p><span className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setConfirmAddressFlowBodyLineOne(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>We have noted your address as: </span>{address}</p>
                                                    <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setConfirmAddressFlowBodyLineTwo(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}><b>Would you like to confirm the order?</b></p>
                                                </div>
                                                <div className='BottomBtnsDiv'>
                                                    <div className='BottomBtns BtnOne' style={{ backgroundColor: proceedAddressFlow === 'yes' ? '#000' : '#eee', color: proceedAddressFlow === 'yes' ? '#fff' : '#000' }} onClick={() => setProceedAddressFlow('yes')}>
                                                        <p className="editable-container" contentEditable={false} suppressContentEditableWarning={true} onInput={(e) => setYesAddressText(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Yes</p>
                                                    </div>
                                                    <div className='BottomBtns BtnTwo' style={{ backgroundColor: proceedAddressFlow === 'no' ? '#000' : '#eee', color: proceedAddressFlow === 'no' ? '#fff' : '#000' }} onClick={() => setProceedAddressFlow('no')}>
                                                        <p className="editable-container" contentEditable={false} suppressContentEditableWarning={true} onInput={(e) => setNoAddressText(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>No</p>
                                                    </div>
                                                </div>
                                                <div className='BottomBtnsDiv'>
                                                    <div className='BottomBtns BtnThree' style={{ backgroundColor: proceedAddressFlow === 'changeaddress' ? '#000' : '#eee', color: proceedAddressFlow === 'changeaddress' ? '#fff' : '#000' }} onClick={() => setProceedAddressFlow('changeaddress')}>
                                                        <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setChangeAddressText(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Change Address</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    {proceedAddressFlow === 'yes' ? (
                                        <>
                                            <div className='from-flow'>
                                                <Card className='product-flow-cart'>
                                                    <div className='BottomBtnDiv'>
                                                        <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                            <p>{yesAddressText}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className='to-flow'>
                                                <Card className='product-flow-cart'>
                                                    <div className='BottomBtnDiv'>
                                                        <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                            <p><b className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setConfirmPaymentOptionHeader(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Great! Please Select Payment option.</b></p>
                                                            <p><span className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setConfirmPaymentOptionBody(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>How would you like to pay? </span></p>
                                                        </div>
                                                        <div className='BottomBtnsDiv'>
                                                            {selectedOptions.includes('cod') && (
                                                                <div className={`BottomBtns ${selectedOptions.includes('cod') && selectedOptions.includes('online') ? 'BtnOne' : ''}`} style={{ backgroundColor: paymentOption === 'cod' ? '#000' : '#eee', color: paymentOption === 'cod' ? '#fff' : '#000' }} onClick={() => setPaymentOption('cod')}>
                                                                    <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setCodBtnText(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>COD</p>
                                                                </div>
                                                            )}
                                                            {selectedOptions.includes('online') && (
                                                                <div className={`BottomBtns ${selectedOptions.includes('cod') && selectedOptions.includes('online') ? 'BtnTwo' : ''}`} style={{ backgroundColor: paymentOption === 'online' ? '#000' : '#eee', color: paymentOption === 'online' ? '#fff' : '#000' }} onClick={() => setPaymentOption('online')}>
                                                                    <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setOnlineBtnText(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Online</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                            {paymentOption === 'cod' && (
                                                <>
                                                    <div className='from-flow'>
                                                        <Card className='product-flow-cart'>
                                                            <div className='BottomBtnDiv'>
                                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                                    <p>{codBtnText}</p>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                    <div className='to-flow'>
                                                        <Card className='product-flow-cart'>
                                                            <div className='BottomBtnDiv'>
                                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                                    <p><b className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setOrderConfirmFlowHeader(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Your Order is Placed!</b></p><br />
                                                                    <p><span className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setOrderConfirmFlowBodyLineOne(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Hey </span>{'{{1}}'}</p><br />
                                                                    <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setOrderConfirmFlowBodyLineTwo(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Thanks for confirming.<br />We are getting your order ready and will send you further updates soon.</p>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </>
                                            )}
                                            {paymentOption === 'online' && (
                                                <>
                                                    <div className='from-flow'>
                                                        <Card className='product-flow-cart'>
                                                            <div className='BottomBtnDiv'>
                                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                                    <p>{onlineBtnText}</p>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                    <div className='to-flow'>
                                                        <Card className='product-flow-cart'>
                                                            <span className='title'>On Payment Failed</span>
                                                            <div className='BottomBtnDiv'>
                                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                                    <p><b className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setPaymentFailedFlowHeader(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Oops! 😔</b></p><br />
                                                                    <p><span className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setPaymentFailedFlowBodyLineOne(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Payment failed, Try again using above buttons.</span></p><br />
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                    <div className='to-flow'>
                                                        <Card className='product-flow-cart'>
                                                            <span className='title'>On Payment Success</span>
                                                            <div className='BottomBtnDiv'>
                                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                                    <p><b className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setOrderConfirmFlowHeader(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Your Order is Placed!</b></p><br />
                                                                    <p><span className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setOrderConfirmFlowBodyLineOne(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Hey </span>{'{{1}}'}</p><br />
                                                                    <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setOrderConfirmFlowBodyLineTwo(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Thanks for confirming.<br />We are getting your order ready and will send you further updates soon.</p>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ) : proceedAddressFlow === 'no' ? (
                                        <>
                                            <div className='from-flow'>
                                                <Card className='product-flow-cart'>
                                                    <div className='BottomBtnDiv'>
                                                        <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                            <p>{noAddressText}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className='to-flow'>
                                                <Card className='product-flow-cart'>
                                                    <div className='BottomBtnDiv'>
                                                        <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                            <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setQuestionOneonNo(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Sure, how can we help you?</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className='from-flow'>
                                                <Card className='product-flow-cart'>
                                                    <div className='BottomBtnDiv'>
                                                        <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                            <p>&lt;Customer types the query&gt;</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className='to-flow'>
                                                <Card className='product-flow-cart'>
                                                    <div className='BottomBtnDiv'>
                                                        <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                            <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setCustomerReplyonNo(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Noted! One of our representatives will take a look at this and get back to you at the earliest.</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </>
                                    ) : proceedAddressFlow === 'changeaddress' && (
                                        <>
                                            <div className='from-flow'>
                                                <Card className='product-flow-cart'>
                                                    <div className='BottomBtnDiv'>
                                                        <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                            <p>{changeAddressText}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className='to-flow'>
                                                <Card className='product-flow-cart'>
                                                    <div className='BottomBtnDiv'>
                                                        <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                            <p><b className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setChangeAddressFlowHeader(e.currentTarget.innerHTML)}>Great! We will require some details to ship the order.</b></p>
                                                            <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setChangeAddressFlowBody(e.currentTarget.innerHTML)}>Please provide your Shipping address.</p>
                                                        </div>
                                                        <div className='BottomBtnDiv' style={{ marginTop: 10 }}>
                                                            <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                                <p>Provide Address</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className='from-flow'>
                                        <Card className='product-flow-cart'>
                                            <div className='BottomBtnDiv'>
                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                    <p>{noText}</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className='to-flow'>
                                        <Card className='product-flow-cart'>
                                            <div className='BottomBtnDiv'>
                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                    <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setQuestionOneonNo(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Sure, how can we help you?</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className='from-flow'>
                                        <Card className='product-flow-cart'>
                                            <div className='BottomBtnDiv'>
                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#eee', borderRadius: 8, padding: 10, textAlign: 'center' }}>
                                                    <p>&lt;Customer types the query&gt;</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className='to-flow'>
                                        <Card className='product-flow-cart'>
                                            <div className='BottomBtnDiv'>
                                                <div className='BottomBtn BtnOne' style={{ backgroundColor: '#e1f6cb', borderRadius: 8, padding: 10, textAlign: 'left' }}>
                                                    <p className="editable-container" contentEditable={true} suppressContentEditableWarning={true} onInput={(e) => setCustomerReplyonNo(e.currentTarget.textContent)} onClick={(e) => e.stopPropagation()}>Noted! One of our representatives will take a look at this and get back to you at the earliest.</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </>
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        <Card title="Mandatory Steps" style={{ position: 'sticky', top: 0 }}>
                            <p>To set the workflow live, complete these steps</p>
                            <div>
                                <Title level={5}>1 Shipping Cost</Title>
                                <Radio.Group onChange={onChangeShippingCost} value={isShippingCost}>
                                    <Radio value={1}>Free</Radio>
                                    <Radio value={2}>Add Shipping</Radio>
                                </Radio.Group>
                                {isShippingCost === 2 && (
                                    <div>
                                        <p>Shipping Cost</p>
                                        <InputNumber
                                            min={0}
                                            step={0.01}
                                            precision={2}
                                            value={shippingCost}
                                            onChange={(value) => setShippingCost(value)}
                                            placeholder="Enter a number"
                                            style={{ width: '100%' }}
                                        />
                                        <p>Applies to</p>
                                        <Radio.Group onChange={onChangeAppliesShippingTo} value={appliesShippingTo}>
                                            <Radio value={1}>All carts</Radio>
                                            <Radio value={2}>Carts of value below</Radio>
                                        </Radio.Group>
                                        {appliesShippingTo === 2 && (
                                            <InputNumber
                                                min={0}
                                                step={0.01}
                                                precision={2}
                                                value={shippingCartValueBelow}
                                                onChange={(value) => setShippingCartValueBelow(value)}
                                                placeholder="Enter a number"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <br />
                            <hr />
                            <div>
                                <Title level={5}>2 Discounts</Title>
                                <Radio.Group onChange={onChangeDiscount} value={isDiscount}>
                                    <Radio value={1}>Provide Discount</Radio>
                                    <Radio value={2}>No Discount</Radio>
                                </Radio.Group>
                                {isDiscount === 1 && (
                                    <div>
                                        <p>Discount Value</p>
                                        <div style={{ display: 'flex' }}>
                                            <InputNumber
                                                min={0}
                                                step={0.01}
                                                precision={2}
                                                value={discountValue}
                                                onChange={(value) => setDiscountValue(value)}
                                                placeholder="Enter a number"
                                                style={{ width: '100%', marginRight: 10 }}
                                            />
                                            <Select
                                                value={discountType}
                                                onChange={onChangeDiscountType}
                                                style={{ width: 200 }}
                                            >
                                                <Option value={1}>Percentage</Option>
                                                <Option value={2}>Fixed Amount</Option>
                                            </Select>
                                        </div>
                                        <p>Applies to</p>
                                        <Radio.Group onChange={onChangeDiscountAppliesTo} value={discountAppliesTo}>
                                            <Radio value={1}>All carts</Radio>
                                            <Radio value={2}>On minimum cart value of</Radio>
                                        </Radio.Group>
                                        {discountAppliesTo === 2 && (
                                            <InputNumber
                                                min={0}
                                                step={0.01}
                                                precision={2}
                                                value={discountOnMinAmt}
                                                onChange={(value) => setDiscountOnMinAmt(value)}
                                                placeholder="Enter a number"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <br />
                            <hr />
                            <div>
                                <Title level={5}>3 Payment Options</Title>
                                <Checkbox.Group
                                    options={paymentOptions}
                                    defaultValue={selectedOptions}
                                    onChange={onChangePaymentOptions}
                                />
                                <p><b>Note:</b> To Collect payment Online need to activate whatsapp payment options.</p>
                            </div>
                            <br />
                            <hr />
                            <Paragraph style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text>Allow to view All Users page</Text>
                                <Switch checked={isActive === 1} loading={loading} onClick={() => setisActive(isActive === 1 ? 2 : 1)} />
                            </Paragraph>
                            <Button type='primary' onClick={handleEcomFlow}>Update</Button>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default CheckoutFlow;