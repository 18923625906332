import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Layout, theme, Flex, Spin, notification, Modal } from 'antd';

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import HeaderComponent from './include/Header.js';
import Sidebar from './include/Sidebar.js';
import FooterComponent from './include/Footer.js';
import Notfound from './pages/Notfound.js';
import FacebookLoginButton from './pages/Facebook.js';
import Inbox from './pages/Chatbox.js';
import Templates from './pages/Templates.js';
import Contacts from './pages/Contacts.js';
import Team from './pages/Team.js';
import Groups from './pages/Groups.js';
import Tags from './pages/Tags.js';
import Campaign from './pages/Campaign.js';
import CreateTemplate from './pages/CreateTemplate.js';
import ModifyTemplate from './pages/ModifyTemplate.js';
import Payments from './pages/Payments.js';
import Automation from './pages/Automation.js';
import Flow from './pages/Flow.js';
import RedirectMobile from './pages/RedirectMobile.js';
import FormBuilder from './pages/FormBuilder.jsx';
// import Permissions from './pages/Permissions.js';
import Ecom from './pages/Ecom.js';
import Settings from './pages/Settings.js';
import ViewCampaignDetails from './pages/ViewCampaignDetail.js';

const firebaseConfig = {
  apiKey: "AIzaSyC9nEqeXy7b003YBvNuMuAzQPxwuj9sL_I",
  authDomain: "connectby-a9e11.firebaseapp.com",
  projectId: "connectby-a9e11",
  storageBucket: "connectby-a9e11.appspot.com",
  messagingSenderId: "440390115547",
  appId: "1:440390115547:web:51b96c76dcc973f306ecfe",
  measurementId: "G-0031WMRBPK"
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

const { Content } = Layout;

const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.log('Unable to get permission to notify.');
    }
  } catch (error) {
    console.error('Error requesting notification permission:', error);
  }
};

const Context = React.createContext({
  name: 'Default',
});

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [userData, setUserData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  const location = useLocation();
  const placement = 'bottomRight';

  useEffect(() => {
    requestNotificationPermission();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received while app is in foreground:', payload);
      api.info({
        message: payload.notification.title,
        description: payload.notification.body,
        placement,
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const getFCMToken = async (userData) => {
    try {
      const currentToken = await getToken(messaging, { vapidKey: 'BFZ9T0-aSrrSa1WPWdHPGsSExQTaPJe_ul0r9-RXlEm_i6sIujy9lKSBX5d5G4DeWyWE3bohe22pHRep8Ko10tA' });
      if (currentToken) {
        try {
          const response = await fetch('https://connectby.io:3001/api/update-notification-token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: userData[0]?.user_id, web_notification_token: currentToken }),
          });

          if (!response.ok) {
            throw new Error('Failed to update notification token');
          }

          console.log('Token updated successfully');
        } catch (error) {
          console.error('Error updating notification token:', error.message);
        }

        console.log('current token for client: ', currentToken);
        // Perform any other necessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (error) {
      console.error('Error getting FCM token:', error);
    }
  };

  const redirectId = new URLSearchParams(location.search).get("redirectid");
  const redirect = new URLSearchParams(location.search).get("redirect");
  if (redirectId !== null) {
    sessionStorage.setItem('redirectId', redirectId);
    const domainName = window.location.hostname;
    if (domainName === 'localhost') {
      window.location.href = 'https://localhost:3000/dashboard';
    } else {
      window.location.href = 'https://app.connectby.io/dashboard';
      // window.location.href = 'https://test.smartfnb.ai/dashboard';
    }
  } else if (redirect !== null) {
    sessionStorage.setItem('redirect', redirect);
    console.log(redirect);
    const domainName = window.location.hostname;
    if (domainName === 'localhost') {
      window.location.href = 'https://localhost:3000/dashboard';
    } else {
      window.location.href = 'https://app.connectby.io/dashboard';
      // window.location.href = 'https://test.smartfnb.ai/dashboard';
    }
  } else {
    if (sessionStorage.getItem('redirectId') === null) {
      if (sessionStorage.getItem('redirect') === null) {
        window.location.href = 'https://connectby.io/login';
      }
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (sessionStorage.getItem('redirectId')) {
          const redirectId = sessionStorage.getItem('redirectId');
          const response = await fetch(`https://connectby.io:3001/api/get_user_data/${redirectId}`);
          if (response.ok) {
            const userData = await response.json();
            console.log(userData);
            getFCMToken(userData);
            setUserData(userData);
          } else {
            console.error('Failed to fetch user data');
          }
        } else {
          const redirect = sessionStorage.getItem('redirect');
          const response = await fetch(`https://connectby.io:3001/api/get_users_data/${redirect}`);
          if (response.ok) {
            const userData = await response.json();
            getFCMToken(userData?.userdata);
            setUserData(userData?.userdata);
            setTeamData(userData?.teamData);
          } else {
            console.error('Failed to fetch user data');
          }
        }

      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []); // Empty dependency array to fetch data only once when the component mounts

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [isExpirePopup, setIsExpirePopup] = useState(true);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {contextHolder}
      {loading ? (
        <Flex align="center"
          justify="center"
          style={{ minHeight: '100vh' }}>
          <Spin size="large" />
        </Flex>
      ) : (
        <>
          <Sidebar collapsed={!collapsed} userData={userData} teamData={teamData} />
          {userData[0]?.start_date === "" ? (
            <Layout>
              <Content
                style={{
                  margin: '24px 16px',
                  padding: location.pathname !== "/inbox" ? 24 : 0,
                  minHeight: location.pathname !== '/inbox' ? 'calc(100vh - 114px)' : 280,
                  maxHeight: location.pathname !== '/inbox' ? 'calc(100vh - 114px)' : undefined,
                  overflowY: location.pathname !== '/inbox' ? 'auto' : undefined,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <Modal
                  title=""
                  centered
                  open={isExpirePopup}
                  onCancel={() => setIsExpirePopup(false)}
                  footer={null}
                  className='planexpirepopup'
                >
                  <p>Hey {userData[0]?.user_name},</p>
                  <div dangerouslySetInnerHTML={{ __html: userData[0]?.plan_expire_popup }}></div>
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    <a style={{ background: '#fff', padding: '10px', borderRadius: '5px', fontSize: '15px', fontWeight: 'bold', marginLeft: '10px' }} href={userData[0]?.renew_link || '#'}>Renew Now</a>
                  </div>
                </Modal>
                <Routes>
                  <Route path="/*" element={<Payments userData={userData} teamData={teamData} />} />
                </Routes>
              </Content>
            </Layout>
          ) : userData[0]?.end_date <= Math.floor(Date.now() / 1000) ? (
            <Layout>
              <Content
                style={{
                  margin: '24px 16px',
                  padding: location.pathname !== "/inbox" ? 24 : 0,
                  minHeight: location.pathname !== '/inbox' ? 'calc(100vh - 114px)' : 280,
                  maxHeight: location.pathname !== '/inbox' ? 'calc(100vh - 114px)' : undefined,
                  overflowY: location.pathname !== '/inbox' ? 'auto' : undefined,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <Modal
                  title=""
                  centered
                  open={isExpirePopup}
                  onCancel={() => setIsExpirePopup(false)}
                  footer={null}
                  className='planexpirepopup'
                >
                  <p>Hey {userData[0]?.user_name},</p>
                  <div dangerouslySetInnerHTML={{ __html: userData[0]?.plan_expire_popup }}></div>
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    <a style={{ background: '#fff', padding: '10px', borderRadius: '5px', fontSize: '15px', fontWeight: 'bold', marginLeft: '10px' }} href={userData[0]?.renew_link || '#'}>Renew Now</a>
                  </div>
                </Modal>
                <Routes>
                  <Route path="/*" element={<Payments userData={userData} teamData={teamData} />} />
                </Routes>
              </Content>
            </Layout>
          ) : (
            <Layout>
              <HeaderComponent onToggleCollapse={handleToggleCollapse} userData={userData} teamData={teamData} />
              <Content
                style={{
                  margin: '24px 16px',
                  padding: location.pathname !== "/inbox" ? 24 : 0,
                  minHeight: location.pathname !== '/inbox' ? 'calc(100vh - 114px)' : 280,
                  maxHeight: location.pathname !== '/inbox' ? 'calc(100vh - 114px)' : undefined,
                  overflowY: location.pathname !== '/inbox' ? 'auto' : undefined,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <Routes>
                  <Route index path="/" element={<FacebookLoginButton userData={userData} teamData={teamData} />} />
                  <Route path="/dashboard" element={<FacebookLoginButton userData={userData} teamData={teamData} />} />
                  <Route path="/facebook" element={<FacebookLoginButton userData={userData} teamData={teamData} />} />
                  <Route path="/inbox" element={<Inbox userData={userData} teamData={teamData} />} />
                  <Route path="/templates" element={<Templates userData={userData} teamData={teamData} />} />
                  <Route path="/contacts" element={<Contacts userData={userData} teamData={teamData} />} />
                  <Route path="/automation" element={<Automation userData={userData} teamData={teamData} />} />
                  <Route path="/groups" element={<Groups userData={userData} teamData={teamData} />} />
                  {/* <Route path="/tags" element={<Tags userData={userData} teamData={teamData} />} /> */}
                  <Route path="/campaign" element={<Campaign userData={userData} teamData={teamData} />} />
                  <Route path="/create_template" element={<CreateTemplate userData={userData} teamData={teamData} />} />
                  <Route path="/flow" element={<Flow userData={userData} teamData={teamData} />} />
                  <Route path="/modify_template" element={<ModifyTemplate userData={userData} teamData={teamData} />} />
                  {/* <Route path="/team" element={<Team userData={userData} teamData={teamData} />} /> */}
                  <Route path="/payments" element={<Payments userData={userData} teamData={teamData} />} />
                  <Route path='/formbuilder' element={<FormBuilder userData={userData} teamData={teamData} />} />
                  {/* <Route path="/permissions" element={<Permissions userData={userData} teamData={teamData} />} /> */}
                  <Route path='/ecommerce' element={<Ecom userData={userData} teamData={teamData} />} />
                  <Route path='/settings' element={<Settings userData={userData} setUserData={setUserData} teamData={teamData} />} />
                  <Route path='/viewcampaigndetails' element={<ViewCampaignDetails userData={userData} teamData={teamData} />} />
                  <Route path="/*" element={<Notfound userData={userData} teamData={teamData} />} />
                </Routes>
              </Content>
              {location.pathname !== "/inbox" && <FooterComponent />}
            </Layout>
          )}
        </>
      )
      }
    </Layout >
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <RedirectMobile />
      <App />
    </Router>
  );
}