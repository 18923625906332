import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Table, Skeleton, Button, Flex, message, Input, Tooltip, Spin, Popconfirm, Col, Row, Drawer, Form, Select, Space, Typography, ColorPicker, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, UserAddOutlined, UsergroupAddOutlined, LoadingOutlined } from '@ant-design/icons';
const { Search } = Input;

const Groups = (userData) => {
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [sortOrder, setSortOrder] = useState('');
    const [sortField, setSortField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [openNewUser, setOpenNewUser] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [editUser, setEditUser] = useState(null);
    
    const [form] = Form.useForm();
    const [editform] = Form.useForm();

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await fetch('https://connectby.io:3001/countrycode');
                if (!response.ok) {
                    throw new Error('Failed to fetch country codes');
                }
                const data = await response.json();
                const filteredData = data.filter(country => country.dial_code);
                const uniqueCountryCodes = [];
                const map = new Map();
                for (const country of filteredData) {
                    if (!map.has(country.dial_code)) {
                        map.set(country.dial_code, true);
                        uniqueCountryCodes.push(country);
                    }
                }
                setCountryCodes(uniqueCountryCodes);
                setFilteredCodes(uniqueCountryCodes);

            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };
        fetchCountryCodes();
    }, []);

    const handleEdit = async (record) => {
        const updatedEditUser = {
            ...record,
            customer_dob: record && record.customer_dob && record.customer_dob !== '' ? dayjs(record.customer_dob, "YYYY-MM-DD") : null,
        };
        setEditUser(updatedEditUser);
    };

    useEffect(() => {
        if (editUser !== null) {
            editform.setFieldsValue(editUser);
            setOpenEditUser(true);
        }
    }, [editform, editUser]);

    const fetchCustomerData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`https://connectby.io:3001/api/getgroups/?customerId=${userData?.userData[0]?.user_id}&result=${pageSize}&page=${pageNumber}&searchTerm=${searchTerm}&sortorder=${sortOrder}&sortfield=${sortField}`,);
            if (!response.ok) {
                throw new Error('Failed to fetch customer data');
            }
            const responseData = await response.json();
            const { data, totalItems } = responseData;
            // console.log(data);
            setFilteredData(data);
            setTotalItems(totalItems);
        } catch (error) {
            console.log(error);
            console.error('Error fetching group data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomerData();
    }, [userData, pageNumber, pageSize, sortOrder, sortField, searchTerm]);

    const handleDelete = (id) => {

    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
        // console.log(sorter.order);
        // console.log(sorter.field);
        if (sorter.order) {
            setSortOrder(sorter.order);
            setSortField(sorter.field);
        } else {
            setSortOrder('');
            setSortField('');
        }
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'group_id',
            width: '10%',
            render: (_, __, index) => ((pageNumber - 1) * pageSize) + index + 1,
            sorter: true,
            sortOrder: sortField === 'group_id' && sortOrder,
        },
        {
            title: 'Group Color',
            dataIndex: 'group_color',
            width: '10%',
            sorter: false,
            render: (color) => (
                <Tag color={color} style={{ width: '24px', height: '24px', display: 'inline-block' }} />
            ),
        },
        {
            title: 'Group Name',
            dataIndex: 'group_name',
            width: '30%',
            sorter: true,
            sortOrder: sortField === 'group_name' && sortOrder,
        },
        {
            title: 'Contacts',
            dataIndex: 'customer_count',
            width: '30%',
            sorter: true,
            sortOrder: sortField === 'customer_count' && sortOrder,
        },
        {
            title: 'Created at ',
            dataIndex: 'created_at',
            width: '20%',
            render: (timestamp) => (
                <span>{dayjs.unix(timestamp).format('YYYY-MM-DD HH:mm:ss')}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            width: '10%',
            render: (_, record) => (
                <Flex gap="small" align="flex-end" vertical>
                    <Flex gap="small">
                        <Tooltip title="Edit Group">
                            <Button type="primary" className='editButton' shape="circle" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
                        </Tooltip>
                        {/* <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => handleDelete(record.group_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title="Delete Group">
                                <Button type="primary" className="deleteButton" danger shape="circle" icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm> */}
                    </Flex>
                </Flex>
            )
        },
    ];
    const onCloseNewUser = () => {
        setOpenNewUser(false);
    };


    const onCloseEditUser = () => {
        setOpenEditUser(false);
        setEditUser(null);
        editform.resetFields();
    };

    useEffect(() => {
        if (countryCodes && countryCodes.length > 0) {
            const filtered = countryCodes.filter(country => {
                return (
                    country.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    country.dial_code.includes(searchText) ||
                    country.code.includes(searchText)
                );
            });
            setFilteredCodes(filtered);
        }
    }, [searchText, countryCodes]);

    const handleAddNewGroup = () => {
        setOpenNewUser(true);
    };

    const handleUpdateUserSubmit = async () => {
        try {
            setLoading(true);
            const formData = await editform.validateFields();
            const response = await fetch(`https://connectby.io:3001/api/update-whatsapp-group/${formData.group_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.userData[0]?.user_id,
                    group_name: formData.group_name || '',
                    group_color: formData.group_color || '',
                }),
            });
            if (response.ok) {
                message.success('Group Updated successfully');
                const updatedData = filteredData.map(item => {
                    if (item.group_id === formData.group_id) {
                        // Update the edited contact
                        return {
                            ...item,
                            group_name: formData.group_name,
                            group_color: formData.group_color
                        };
                    }
                    return item;
                });
                setFilteredData(updatedData);
                onCloseEditUser();
                editform.resetFields();
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error updating group:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNewUserSubmit = async () => {
        try {
            setLoading(true);
            const formData = await form.validateFields();
            console.log(formData);
            const response = await fetch('https://connectby.io:3001/api/create-group', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.userData[0]?.user_id,
                    group_name: formData.group_name || '',
                    group_color: formData.group_color || ''
                }),
            });
            if (response.ok) {
                message.success('Group added successfully');
                fetchCustomerData();
                onCloseNewUser();
                form.resetFields();
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error creating group:', error);
        } finally {
            setLoading(false);
        }
    };

    const truncateNotes = (notes) => {
        if (notes.length > 25) {
            return notes.substring(0, 25) + '...';
        }
        return notes;
    };

    return (
        <Row>
            <Col span={6}>
                <Search placeholder="Search groups by name" onChange={(e) => handleSearch(e.target.value)} style={{ marginBottom: '16px' }} />
            </Col>
            <Col span={18}>
                <Flex gap="small" align="flex-end" vertical>
                    <Button type='primary' onClick={handleAddNewGroup}><UsergroupAddOutlined /> Add New Group</Button>
                </Flex>
            </Col>
            <Col span={24}>
                {isLoading ? (
                    <Skeleton active />
                ) : (
                    <Table
                        pagination={{
                            total: totalItems,
                            current: pageNumber,
                            pageSize: pageSize,
                            onChange: (page, pageSize) => {
                                setPageNumber(page);
                                setPageSize(pageSize);
                            },
                            showTotal: (total, range) => (
                                <span className="totaldata">Showing {range[0]} to {range[1]} of {total} entries</span>
                            )
                        }}
                        columns={columns}
                        dataSource={filteredData.map((item, index) => ({ ...item, key: index }))}
                        onChange={handleTableChange}
                    />
                )}
            </Col>
            <Drawer
                title={<><UserAddOutlined /> Add New Group</>}
                width={720}
                onClose={onCloseNewUser}
                open={openNewUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseNewUser}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleNewUserSubmit} type="primary">
                                    <span>Submit</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={form}>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item
                                name="group_color"
                                label="Group color"
                                getValueFromEvent={(color) => {
                                    return "#" + color.toHex();
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select group color',
                                    },
                                ]}
                            >
                                <ColorPicker format="hex" />
                            </Form.Item>
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                name="group_name"
                                label="Group Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter group name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter group name" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <Drawer
                title={<><UserAddOutlined /> Edit Group</>}
                width={720}
                onClose={onCloseEditUser}
                open={openEditUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseEditUser}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleUpdateUserSubmit} type="primary">
                                    <span>Submit</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={editform} >
                    <Form.Item name="group_id" hidden>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item
                                name="group_color"
                                label="Group color"
                                getValueFromEvent={(color) => {
                                    return "#" + color.toHex();
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select group color',
                                    },
                                ]}
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                name="group_name"
                                label="Group Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter group name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter group name" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </Row>
    );
}

export default Groups;