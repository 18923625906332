import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { Modal, Table, Skeleton, Dropdown, Button, Flex, message, Input, Tooltip, Spin, Popconfirm, Col, Row, Drawer, Form, Select, Switch, Space, DatePicker, Typography, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined, UserAddOutlined, UsergroupAddOutlined, LoadingOutlined } from '@ant-design/icons';
const { Search } = Input;
const { Option } = Select;
const { Title, Text, Link } = Typography;

const Team = (userData) => {
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAssignLoading, setIsAssignLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [sortOrder, setSortOrder] = useState('');
    const [sortField, setSortField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [openNewUser, setOpenNewUser] = useState(false);
    const [openBulkNewUser, setOpenBulkNewUser] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [existUserCount, setExistUserCount] = useState(0);
    const [bulkLoader, setBulkLoader] = useState(false);
    const [uploadBulkLoader, setUploadBulkLoader] = useState(false);
    const [newUserCount, setNewUserCount] = useState(0);
    const [form] = Form.useForm();
    const [editform] = Form.useForm();
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [inviteLoading, setInviteLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    useEffect(() => {
        const fetchGroupList = async () => {
            try {
                const response = await fetch(`https://connectby.io:3001/api/getgrouplist/?customerId=${userData?.userData[0]?.user_id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch group list');
                }
                const responseData = await response.json();
                setGroupList(responseData.data);
            } catch (error) {
                console.error('Error fetching group list:', error);
            }
        };
        // const fetchGroupListOne = async () => {
        //     const imageUrl = "https://localhost:3000/static/media/blogo.38b8050c3825501ac8ac.png";
        //     const imgResponse = await fetch(imageUrl);
        //     if (!imgResponse.ok) {
        //       throw new Error('Failed to fetch image');
        //     }
        //     const imageBlob = await imgResponse.blob();
        //     const file = new File([imageBlob], 'product_image.png', {
        //         type: imageBlob.type,
        //     });

        //     const formdata = new FormData();
        //     formdata.append('menu_name', 'Test');
        //     formdata.append('internal_menu_name', 'test');
        //     formdata.append('menu_description', 'djkfbgm,g,fd');
        //     formdata.append('menu_type', 'aas');
        //     formdata.append('use_product_from', 'asd');
        //     formdata.append('product_restriction', 'asd');
        //     formdata.append('menu_icon', file);
        //     try {
        //         const response = await fetch(`https://app.smartfnb.ai/api/appapi/menus`, {
        //             method: 'POST',
        //             body: formdata,
        //             headers: {
        //                 "Authorization": "Basic c21hcnRhc3BvczpBZHN0aHJpdmVAMTIz",
        //             },
        //         });

        //         if (!response.ok) {
        //             throw new Error('Failed to fetch group list');
        //         }

        //         const responseData = await response.json();
        //         console.log(responseData);
        //     } catch (error) {
        //         console.error('Error fetching group list:', error);
        //     }
        // };
        fetchGroupList();
        // fetchGroupListOne();
    }, []);

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await fetch('https://connectby.io:3001/countrycode');
                if (!response.ok) {
                    throw new Error('Failed to fetch country codes');
                }
                const data = await response.json();
                const filteredData = data.filter(country => country.dial_code);
                const uniqueCountryCodes = [];
                const map = new Map();
                for (const country of filteredData) {
                    if (!map.has(country.dial_code)) {
                        map.set(country.dial_code, true);
                        uniqueCountryCodes.push(country);
                    }
                }
                setCountryCodes(uniqueCountryCodes);
                setFilteredCodes(uniqueCountryCodes);

            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };
        fetchCountryCodes();
    }, []);

    const handleCustomerSelect = (customerId) => {
        const isSelected = selectedCustomers.includes(customerId);
        if (isSelected) {
            setSelectedCustomers(selectedCustomers.filter(id => id !== customerId));
        } else {
            setSelectedCustomers([...selectedCustomers, customerId]);
        }
    };

    // Function to handle selecting all customers
    const handleSelectAllCustomers = () => {
        const allCustomerIds = filteredData.map(customer => customer.customer_id);
        const selectedIds = selectedCustomers.length === allCustomerIds.length ? [] : allCustomerIds;
        setSelectedCustomers(selectedIds);
    };

    const handleEdit = async (record) => {
        const updatedEditUser = {
            ...record,
            customer_dob: record && record.customer_dob && record.customer_dob !== '' ? dayjs(record.customer_dob, "YYYY-MM-DD") : null,
        };
        setEditUser(updatedEditUser);
    };

    useEffect(() => {
        if (editUser !== null) {
            editform.setFieldsValue(editUser);
            setOpenEditUser(true);
        }
    }, [editform, editUser]);

    useEffect(() => {
        const fetchCustomerData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`https://connectby.io:3001/api/getteam/?customerId=${userData?.userData[0]?.user_id}&result=${pageSize}&page=${pageNumber}&searchTerm=${searchTerm}&sortorder=${sortOrder}&sortfield=${sortField}`,);
                if (!response.ok) {
                    throw new Error('Failed to fetch customer data');
                }
                const responseData = await response.json();
                const { data, totalItems } = responseData;
                // console.log(data);
                setFilteredData(data);
                setTotalItems(totalItems);
            } catch (error) {
                console.log(error);
                console.error('Error fetching customer data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCustomerData();
    }, [userData, pageNumber, pageSize, sortOrder, sortField, searchTerm]);

    const handleDelete = (id) => {
        fetch(`https://connectby.io:3001/api/delete_user?customerId=${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete user');
                }
                return response.json();
            })
            .then(data => {
                message.success('Customer deleted successfully');
                const updatedData = filteredData.filter(item => item.customer_id !== id);
                setFilteredData(updatedData);
                setTotalItems(totalItems - 1);
            })
            .catch(error => {
                console.error('Error deleting user:', error.message);
                message.error('Failed to delete user');
            });
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
        // console.log(sorter.order);
        // console.log(sorter.field);
        if (sorter.order) {
            setSortOrder(sorter.order);
            setSortField(sorter.field);
        } else {
            setSortOrder('');
            setSortField('');
        }
    };

    const handleSendInvite = async (record) => {
        try {
            setInviteLoading(true);
            const response = await fetch('https://connectby.io:3001/api/resend-invitation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: record?.crm_user_id,
                    team_name: record?.team_name || '',
                    team_email: record?.team_email || '',
                    team_id: record?.team_id || ''
                }),
            });
            if (response.ok) {
                message.success('Invitation sent successfully');
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error while adding team member:', error);
        } finally {
            setInviteLoading(false);
        }
    };

    const handleUpdateTeam = async (record) => {
        try {
            setUpdateLoading(true);
            const response = await fetch('https://connectby.io:3001/api/update-team', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: record?.active_status === '1' ? '2' : '1',
                    team_id: record?.team_id || ''
                }),
            });
            if (response.ok) {
                if (record?.active_status === '1') {
                    message.success('De-activated.');
                } else {
                    message.success('Activated.');
                }

                const updatedData = filteredData.map(item => {
                    if (item.team_id === record?.team_id) {
                        return {
                            ...item,
                            active_status: record?.active_status === '1' ? '2' : '1',
                        };
                    }
                    return item;
                });
                setFilteredData(updatedData);

            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error while adding team member:', error);
        } finally {
            setUpdateLoading(false);
        }
    };

    const dataColumns = [
        {
            title: '#',
            dataIndex: 'index',
            width: '5%',
            render: (_, __, index) => ((pageNumber - 1) * pageSize) + index + 1,
        },
        {
            title: 'Customer Name',
            dataIndex: 'Client Name',
            width: '20%'
        },
        {
            title: 'Phone No.',
            dataIndex: 'Phone Number',
            width: '20%',
            render: (_, record) => `${record['Country Code']} ${record['Phone Number']}`
        },
        {
            title: 'Email',
            dataIndex: 'Email Address',
            width: '20%',
        },
        {
            title: 'Notes',
            dataIndex: 'Notes',
            width: '35%',
            render: (_, record) => truncateNotes(record['Notes'])
        }
    ];



    const columns = [
        {
            title: '#',
            dataIndex: 'team_id',
            width: '10%',
            render: (_, __, index) => ((pageNumber - 1) * pageSize) + index + 1,
            sorter: true,
            sortOrder: sortField === 'team_id' && sortOrder,
        },
        {
            title: 'Name',
            dataIndex: 'team_name',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'team_name' && sortOrder,
        },
        {
            title: 'Phone No.',
            dataIndex: 'team_phone',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'team_phone' && sortOrder,
        },
        {
            title: 'Email ',
            dataIndex: 'team_email',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'team_email' && sortOrder,
        },
        {
            title: 'Invite ',
            dataIndex: 'team_id',
            width: '20%',
            sorter: false,
            render: (_, record) => (
                inviteLoading ? (
                    <Button type='primary' style={{ backgroundColor: 'green' }}><Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} /></Button>
                ) : (
                    <Button type='primary' style={{ backgroundColor: 'green' }} onClick={() => handleSendInvite(record)}>Re-send</Button>
                )

            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            width: '10%',
            render: (_, record) => (
                <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <Switch
                        onClick={() => handleUpdateTeam(record)}
                        checkedChildren="Active"
                        unCheckedChildren="De-active"
                        defaultChecked={record.active_status === '1'}
                        loading={updateLoading}
                    />
                </div>
            )
        },
    ];
    const onCloseNewUser = () => {
        setOpenNewUser(false);
    };

    const onCloseBulkNewUser = () => {
        setOpenBulkNewUser(false);
        setFileData(null);
        setExistUserCount(0);
        setNewUserCount(0);
    };

    const handleFileUpload = async (event) => {
        setBulkLoader(true);
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            const reader = new FileReader();
            reader.onload = async () => { // Make the onload callback async
                const fileData = reader.result;
                if (uploadedFile.name.endsWith('.csv')) {
                    // Parse CSV
                    Papa.parse(fileData, {
                        header: true,
                        complete: async (result) => { // Make the complete callback async
                            const parsedData = result.data.filter(row => {
                                return row["Client Name"] !== undefined && row["Client Name"].trim() !== '';
                            });
                            const checkedData = [];
                            const uniqueParsedData = parsedData.reduce((acc, curr) => {
                                if (!acc.find(item => item['Phone Number'] === curr['Phone Number'])) {
                                    acc.push(curr);
                                }
                                return acc;
                            }, []);
                            for (const customer of uniqueParsedData) {
                                const customer_phone = customer['Phone Number'];
                                console.log(customer_phone);
                                const checkResponse = await checkCustomer(userData?.userData[0]?.user_id, customer_phone);
                                console.log(checkResponse);
                                if (!checkResponse.error) {
                                    setNewUserCount(prevCount => prevCount + 1);
                                    customer['is_exist'] = 'no';
                                    checkedData.push(customer);
                                } else {
                                    setExistUserCount(prevCount => prevCount + 1);
                                    customer['is_exist'] = 'yes';
                                    checkedData.push(customer);
                                    // If phone number already exists, handle it here (e.g., show an error message)
                                    console.log(`Skipping customer due to existing phone number: ${customer['Client Name']}`);
                                }
                            }
                            setFileData(checkedData);
                            event.target.value = '';
                            setBulkLoader(false);
                        },
                        error: (error) => {
                            console.error('Error parsing CSV:', error);
                        }
                    });
                } else if (uploadedFile.name.endsWith('.xlsx')) {
                    const workbook = XLSX.read(fileData, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    const headerRow = jsonData[0];
                    const parsedData = [];
                    for (let i = 1; i < jsonData.length; i++) {
                        const row = jsonData[i];
                        const rowData = {};
                        for (let j = 0; j < headerRow.length; j++) {
                            rowData[headerRow[j]] = row[j];
                        }
                        parsedData.push(rowData);
                    }
                    const checkedData = [];
                    const uniqueParsedData = parsedData.reduce((acc, curr) => {
                        if (!acc.find(item => item['Phone Number'] === curr['Phone Number'])) {
                            acc.push(curr);
                        }
                        return acc;
                    }, []);
                    for (const customer of uniqueParsedData) {
                        const customer_phone = customer['Phone Number'];
                        console.log(customer_phone);
                        const checkResponse = await checkCustomer(userData?.userData[0]?.user_id, customer_phone);
                        console.log(checkResponse);
                        if (!checkResponse.error) {
                            setNewUserCount(prevCount => prevCount + 1);
                            customer['is_exist'] = 'no';
                            checkedData.push(customer);
                        } else {
                            setExistUserCount(prevCount => prevCount + 1);
                            customer['is_exist'] = 'yes';
                            checkedData.push(customer);
                            // If phone number already exists, handle it here (e.g., show an error message)
                            console.log(`Skipping customer due to existing phone number: ${customer['Client Name']}`);
                        }
                    }
                    setFileData(checkedData);
                    event.target.value = '';
                    setBulkLoader(false);
                } else {
                    console.error('Unsupported file format');
                    setBulkLoader(false);
                }
            };
            reader.readAsBinaryString(uploadedFile);
        }
    };

    const checkCustomer = async (crm_user_id, customer_phone) => {
        try {
            const response = await fetch('https://connectby.io:3001/api/check-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ crm_user_id, customer_phone }),
            });
            return await response.json();
        } catch (error) {
            console.error('Error checking customer:', error);
            return { error: 'Internal Server Error' };
        }
    };

    const onCloseEditUser = () => {
        setOpenEditUser(false);
        setEditUser(null);
        editform.resetFields();
    };

    useEffect(() => {
        if (countryCodes && countryCodes.length > 0) {
            const filtered = countryCodes.filter(country => {
                return (
                    country.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    country.dial_code.includes(searchText) ||
                    country.code.includes(searchText)
                );
            });
            setFilteredCodes(filtered);
        }
    }, [searchText, countryCodes]);

    const handleCountryCodeSearch = searchText => {
        setSearchText(searchText);
    };

    const handleAddNewTeamMember = (e) => {
        setOpenNewUser(true);
    };
    const items = [
        {
            key: 'add_new',
            label: 'Add New Customer',
            icon: <UserAddOutlined />
        },
        {
            key: 'bulk_add_new',
            label: 'Bulk Add New Customers',
            icon: <UsergroupAddOutlined />
        },
    ];

    const handleAssignToGroup = () => {
        if (selectedCustomers.length === 0) {
            message.error('Please select customers to assign group');
            return;
        }
        setShowPopup(true);
    }

    const handleGroupSelect = (groupId) => {
        setSelectedGroupId(groupId);
    };

    const handleAssignSubmit = () => {
        if (selectedGroupId === null) {
            message.error('Please select group');
            return;
        }
        setIsAssignLoading(true);
        const group_id = selectedGroupId;
        const customer_ids = selectedCustomers;
        fetch('https://connectby.io:3001/api/assign-group', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                group_id: group_id,
                customer_ids: customer_ids
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Assign group response:', data);
            })
            .catch(error => {
                console.error('Error assigning group:', error);
            }).finally(() => {
                message.success('Group Assigned successfully');
                setShowPopup(false);
                setSelectedCustomers([]);
                setSelectedGroupId(null);
                setIsAssignLoading(false);
            });
    };

    const handleUpdateUserSubmit = async () => {
        try {
            setLoading(true);
            const formData = await editform.validateFields();
            const dobDate = formData.customer_dob ? new Date(formData.customer_dob) : null;
            const formattedDOB = dobDate ? `${dobDate.getFullYear()}-${(dobDate.getMonth() + 1).toString().padStart(2, '0')}-${dobDate.getDate().toString().padStart(2, '0')}` : '';
            const response = await fetch(`https://connectby.io:3001/api/update-customer/${formData.customer_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.userData[0]?.user_id,
                    country_code: formData.country_code ? formData.country_code.replace('+', '') : '',
                    customer_name: formData.customer_name || '',
                    customer_phone: formData.customer_phone || '',
                    customer_email: formData.customer_email || '',
                    customer_gender: formData.customer_gender || '',
                    customer_dob: formattedDOB,
                    note: formData.note || ''
                }),
            });
            if (response.ok) {
                message.success('Customer Updated successfully');
                // console.log('Customer Updated successfully');
                const updatedData = filteredData.map(item => {
                    if (item.customer_id === formData.customer_id) {
                        // Update the edited contact
                        return {
                            ...item,
                            customer_name: formData.customer_name,
                            customer_phone: formData.customer_phone,
                            customer_email: formData.customer_email,
                            customer_gender: formData.customer_gender,
                            customer_dob: formattedDOB,
                            note: formData.note
                        };
                    }
                    return item;
                });
                setFilteredData(updatedData);
                onCloseEditUser();
                editform.resetFields();
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error updating customer:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setFileData(null);
        setExistUserCount(0);
        setNewUserCount(0);
    }

    const handleUpload = async () => {
        setUploadBulkLoader(true);
        try {
            const crm_user_id = userData?.userData[0]?.user_id;
            const dataWithCrmUserId = { crm_user_id, customers: fileData };
            const response = await fetch('https://connectby.io:3001/api/upload-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataWithCrmUserId),
            });
            setUploadBulkLoader(false);
            console.log('Upload success:', response);
            setFileData(null);
            setExistUserCount(0);
            setNewUserCount(0);
            message.success('Customers uploaded successfully');
            setOpenBulkNewUser(false);
        } catch (error) {
            console.error('Error uploading data:', error);
            message.error('An error occurred while uploading data. Please try again.');
            setUploadBulkLoader(false);
        }
    };

    const handleNewUserSubmit = async () => {
        try {
            setLoading(true);
            const formData = await form.validateFields();
            // const dobDate = formData.team_dob ? new Date(formData.team_dob) : null;
            // const formattedDOB = dobDate ? `${dobDate.getFullYear()}-${(dobDate.getMonth() + 1).toString().padStart(2, '0')}-${dobDate.getDate().toString().padStart(2, '0')}` : '';
            const response = await fetch('https://connectby.io:3001/api/create-team', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.userData[0]?.user_id,
                    team_country_code: formData.team_country_code ? formData.team_country_code.replace('+', '') : '',
                    team_name: formData.team_name || '',
                    team_phone: formData.team_phone || '',
                    team_email: formData.team_email || '',
                    team_gender: formData.team_gender || '',
                    team_role: formData.team_role || '',
                    team_dob: ''
                }),
            });
            if (response.ok) {
                message.success('Invitation sent successfully');
                onCloseNewUser();
                form.resetFields();
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error while adding team member:', error);
        } finally {
            setLoading(false);
        }
    };

    const truncateNotes = (notes) => {
        if (notes.length > 25) {
            return notes.substring(0, 25) + '...';
        }
        return notes;
    };

    return (
        <Row>
            <Col span={7}>
                <Search placeholder="Search team member by name, email or phone no." onChange={(e) => handleSearch(e.target.value)} style={{ marginBottom: '16px' }} />
            </Col>
            <Col span={17}>
                <Flex gap="small" align="flex-end" vertical>
                    <Button className="dropdownButton" type="primary" onClick={handleAddNewTeamMember}><UserAddOutlined /> Add New Team Member</Button>
                </Flex>
            </Col>
            <Col span={24}>
                {isLoading ? (
                    <Skeleton active />
                ) : (
                    <Table
                        pagination={{
                            total: totalItems,
                            current: pageNumber,
                            pageSize: pageSize,
                            onChange: (page, pageSize) => {
                                setPageNumber(page);
                                setPageSize(pageSize);
                            },
                            showTotal: (total, range) => (
                                <span className="totaldata">Showing {range[0]} to {range[1]} of {total} entries</span>
                            )
                        }}
                        columns={columns}
                        dataSource={filteredData.map((item, index) => ({ ...item, key: index }))}
                        onChange={handleTableChange}
                    />
                )}
            </Col>
            <Drawer
                title={<><UserAddOutlined /> Add New Team Member</>}
                width={720}
                onClose={onCloseNewUser}
                open={openNewUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseNewUser}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleNewUserSubmit} type="primary">
                                    <span>Sent Invitation</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={form}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="team_name"
                                label="Team Member Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter team member name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter team member name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Team member Phone No."
                            >
                                <Input.Group compact>
                                    <Form.Item
                                        style={{ width: '35%' }}
                                        name="team_country_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}>
                                        <Select
                                            showSearch
                                            onSearch={handleCountryCodeSearch}
                                            optionFilterProp="children"
                                            filterOption={false}
                                            placeholder="Select country code"
                                            dropdownMatchSelectWidth={false}
                                            rules={[
                                                {
                                                    required: true, // Make the field required
                                                    message: 'Please select country code',
                                                },
                                            ]}
                                        >
                                            {filteredCodes.map(country => (
                                                <Option key={`${country.dial_code}-${country.name}`} value={country.dial_code}>
                                                    <img src={country.image} alt={country.name} style={{ width: '1.5em', marginRight: '0.5em', position: 'relative', top: 5 }} /> {country.dial_code} - {country.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: '65%' }} name="team_phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter phone number',
                                            },
                                        ]}>
                                        <Input placeholder="Please enter phone number" />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="team_email"
                                label="Team Member Email"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter email',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="team_gender"
                                label="Gender"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select gender',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select gender">
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                    <Option value="other">Other</Option>
                                    <Option value="notdeclared">Don't declared</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="team_role"
                                label="Role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select role',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select role">
                                    <Option value="owner">Owner</Option>
                                    <Option value="admin">Admin</Option>
                                    <Option value="teammate">Teammate</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={`team_department`}
                                label="Assign Department"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select department',
                                    },
                                ]}
                            >
                                <Select mode="tags" placeholder="Select Department" maxTagCount={1} onChange={(value) => {if (value.length > 1) {value.splice(0, value.length - 1);}}}>
                                    <Option key='support' value='support'>Support</Option>
                                    <Option key='inquary' value='inquary'>Inquary</Option>
                                    <Option key='new_leads' value='new_leads'>New Leads</Option>
                                    <Option key='booking' value='booking'>Booking</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item
                                name="team_dob"
                                label="Date of Birth"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select date of birth',
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col> */}
                    </Row>
                </Form>
            </Drawer>
            <Drawer
                title={<><UserAddOutlined /> Edit Team Member</>}
                width={720}
                onClose={onCloseEditUser}
                open={openEditUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseEditUser}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleUpdateUserSubmit} type="primary">
                                    <span>Submit</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={editform} >
                    <Form.Item name="customer_id" hidden>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="customer_name"
                                label="Customer Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter customer name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter customer name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Customer Phone No."
                            >
                                <Input.Group compact>
                                    <Form.Item
                                        style={{ width: '35%' }}
                                        name="country_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}>
                                        <Select
                                            showSearch
                                            onSearch={handleCountryCodeSearch}
                                            optionFilterProp="children"
                                            filterOption={false}
                                            placeholder="Select country code"
                                            dropdownMatchSelectWidth={false}
                                            rules={[
                                                {
                                                    required: true, // Make the field required
                                                    message: 'Please select country code',
                                                },
                                            ]}
                                        >
                                            {filteredCodes.map(country => (
                                                <Option key={`${country.dial_code}-${country.name}`} value={country.dial_code}>
                                                    <img src={country.image} alt={country.name} style={{ width: '1.5em', marginRight: '0.5em', position: 'relative', top: 5 }} /> {country.dial_code} - {country.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: '65%' }} name="customer_phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter phone number',
                                            },
                                        ]}>
                                        <Input placeholder="Please enter phone number" />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="customer_email"
                                label="Customer Email"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter email',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="customer_gender"
                                label="Gender"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select gender',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select gender">
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                    <Option value="other">Other</Option>
                                    <Option value="notdeclared">Don't declared</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="customer_dob"
                                label="Date of Birth"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select date of birth',
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="note"
                                label="Note"
                            >
                                <Input.TextArea rows={4} placeholder="Please enter any additional notes" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <Modal
                title="Select Group"
                visible={showPopup}
                onCancel={() => setShowPopup(false)}
                footer={null}
            >
                <p>Total {selectedCustomers.length} Contacts selected</p>
                <Select placeholder="Select a group" style={{ width: '100%' }} onChange={handleGroupSelect} value={selectedGroupId}>
                    {groupList.map(group => (
                        <Option key={group.group_id} value={group.group_id}>{group.group_name}</Option>
                    ))}
                </Select>
                <div style={{ height: 40 }}>
                    {isAssignLoading ? (
                        <Button type="primary" style={{ marginTop: 15, float: 'right' }}>
                            <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                        </Button>
                    ) : (
                        <Button type='primary' onClick={handleAssignSubmit} style={{ marginTop: 15, float: 'right' }}>Assign</Button>
                    )}
                </div>
            </Modal>
        </Row>
    );
}

export default Team;