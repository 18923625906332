import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { Modal, Table, Skeleton, Dropdown, Button, Menu, Flex, message, Input, Tooltip, Spin, Popconfirm, Col, Row, Drawer, Form, Select, Space, DatePicker, Typography, Checkbox, Switch } from 'antd';
import { DownOutlined, DeleteOutlined, EditOutlined, UserAddOutlined, UsergroupAddOutlined, LoadingOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
const { Search } = Input;
const { Option } = Select;
const { Title, Text, Link } = Typography;

const Contacts = (userData) => {
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [blockLoading, setBlockLoading] = useState(false);
    const [isAssignLoading, setIsAssignLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [sortOrder, setSortOrder] = useState('');
    const [sortField, setSortField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [openNewUser, setOpenNewUser] = useState(false);
    const [openNewMUser, setOpenNewMUser] = useState(false);
    const [contactRows, setContactRows] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]);
    const [openBulkNewUser, setOpenBulkNewUser] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [existUserCount, setExistUserCount] = useState(0);
    const [bulkLoader, setBulkLoader] = useState(false);
    const [uploadBulkLoader, setUploadBulkLoader] = useState(false);
    const [newUserCount, setNewUserCount] = useState(0);
    const [form] = Form.useForm();
    const [multiform] = Form.useForm();
    const [editform] = Form.useForm();
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [selectedTags, setSelectedTags] = useState(null);
    const [selectedTagIds, setSelectedTagIds] = useState(null);
    const [selectedTagId, setSelectedTagId] = useState('');
    const [selectedTagName, setSelectedTagName] = useState('');

    const tagNameMap = tagsData.reduce((map, tag) => {
        map[tag.tag_id] = tag.tag_name;
        return map;
    }, {});

    const handleChange = (value) => {
        setSelectedTagId(value);
        setSelectedTagName(tagNameMap[value] || ''); // Update tag name based on selected ID
    };

    useEffect(() => {
        const fetchGroupList = async () => {
            try {
                const response = await fetch(`https://connectby.io:3001/api/getgrouplist/?customerId=${userData?.userData[0]?.user_id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch group list');
                }
                const responseData = await response.json();
                setGroupList(responseData.data);
            } catch (error) {
                console.error('Error fetching group list:', error);
            }
        };

        // const fetchGroupListOne = async () => {
        //     const imageUrl = "https://localhost:3000/static/media/blogo.38b8050c3825501ac8ac.png";
        //     const imgResponse = await fetch(imageUrl);
        //     if (!imgResponse.ok) {
        //       throw new Error('Failed to fetch image');
        //     }
        //     const imageBlob = await imgResponse.blob();
        //     const file = new File([imageBlob], 'product_image.png', {
        //         type: imageBlob.type,
        //     });

        //     const formdata = new FormData();
        //     formdata.append('menu_name', 'Test');
        //     formdata.append('internal_menu_name', 'test');
        //     formdata.append('menu_description', 'djkfbgm,g,fd');
        //     formdata.append('menu_type', 'aas');
        //     formdata.append('use_product_from', 'asd');
        //     formdata.append('product_restriction', 'asd');
        //     formdata.append('menu_icon', file);
        //     try {
        //         const response = await fetch(`https://app.smartfnb.ai/api/appapi/menus`, {
        //             method: 'POST',
        //             body: formdata,
        //             headers: {
        //                 "Authorization": "Basic c21hcnRhc3BvczpBZHN0aHJpdmVAMTIz",
        //             },
        //         });

        //         if (!response.ok) {
        //             throw new Error('Failed to fetch group list');
        //         }

        //         const responseData = await response.json();
        //         console.log(responseData);
        //     } catch (error) {
        //         console.error('Error fetching group list:', error);
        //     }
        // };
        fetchGroupList();
        getTags();
        // fetchGroupListOne();
    }, []);


    const getTags = async () => {
        const userId = userData?.userData[0]?.user_id;
        fetch('https://connectby.io:3001/api/get-tags', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTagsData(data);
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
            });
    };

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await fetch('https://connectby.io:3001/countrycode');
                if (!response.ok) {
                    throw new Error('Failed to fetch country codes');
                }
                const data = await response.json();
                const filteredData = data.filter(country => country.dial_code);
                const uniqueCountryCodes = [];
                const map = new Map();
                for (const country of filteredData) {
                    if (!map.has(country.dial_code)) {
                        map.set(country.dial_code, true);
                        uniqueCountryCodes.push(country);
                    }
                }
                setCountryCodes(uniqueCountryCodes);
                setFilteredCodes(uniqueCountryCodes);

            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };
        fetchCountryCodes();
    }, []);

    const handleCustomerSelect = (customerId) => {
        const isSelected = selectedCustomers.includes(customerId);
        if (isSelected) {
            setSelectedCustomers(selectedCustomers.filter(id => id !== customerId));
        } else {
            setSelectedCustomers([...selectedCustomers, customerId]);
        }
    };

    // Function to handle selecting all customers
    const handleSelectAllCustomers = () => {
        const allCustomerIds = filteredData.map(customer => customer.customer_id);
        const selectedIds = selectedCustomers.length === allCustomerIds.length ? [] : allCustomerIds;
        setSelectedCustomers(selectedIds);
    };

    const handleEdit = async (record) => {
        const updatedEditUser = {
            ...record,
            customer_dob: record && record.customer_dob && record.customer_dob !== '' ? dayjs(record.customer_dob, "YYYY-MM-DD") : null,
        };
        setEditUser(updatedEditUser);
    };

    useEffect(() => {
        if (editUser !== null) {
            editform.setFieldsValue(editUser);
            setOpenEditUser(true);
        }
    }, [editform, editUser]);

    useEffect(() => {
        const fetchCustomerData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`https://connectby.io:3001/api/getusersTwo/?customerId=${userData?.userData[0]?.user_id}&phoneNoId=${userData?.userData[0]?.phonenumberid}&tagId=${selectedTagId}&result=${pageSize}&page=${pageNumber}&searchTerm=${searchTerm}&sortorder=${sortOrder}&sortfield=${sortField}`,);
                if (!response.ok) {
                    throw new Error('Failed to fetch customer data');
                }
                const responseData = await response.json();
                const { data, totalItems } = responseData;
                // console.log(data);
                setFilteredData(data);
                setTotalItems(totalItems);
            } catch (error) {
                console.log(error);
                console.error('Error fetching customer data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCustomerData();
    }, [userData, pageNumber, pageSize, sortOrder, sortField, searchTerm, selectedTagId]);

    const handleDelete = (id) => {
        fetch(`https://connectby.io:3001/api/delete_user?customerId=${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete user');
                }
                return response.json();
            })
            .then(data => {
                message.success('Customer deleted successfully');
                const updatedData = filteredData.filter(item => item.customer_id !== id);
                setFilteredData(updatedData);
                setTotalItems(totalItems - 1);
            })
            .catch(error => {
                console.error('Error deleting user:', error.message);
                message.error('Failed to delete user');
            });
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
        // console.log(sorter.order);
        // console.log(sorter.field);
        if (sorter.order) {
            setSortOrder(sorter.order);
            setSortField(sorter.field);
        } else {
            setSortOrder('');
            setSortField('');
        }
    };


    const dataColumns = [
        {
            title: '#',
            dataIndex: 'index',
            width: '5%',
            render: (_, __, index) => ((pageNumber - 1) * pageSize) + index + 1,
        },
        {
            title: 'Customer Name',
            dataIndex: 'Client Name',
            width: '20%'
        },
        {
            title: 'Phone No.',
            dataIndex: 'Phone Number',
            width: '20%',
            render: (_, record) => `${record['Country Code']} ${record['Phone Number']}`
        },
        {
            title: 'Email',
            dataIndex: 'Email Address',
            width: '20%',
        },
        {
            title: 'Notes',
            dataIndex: 'Notes',
            width: '35%',
            render: (_, record) => truncateNotes(record['Notes'])
        }
    ];

    const handleBlock = async (customerId, status) => {
        setBlockLoading(true);
        try {
            const response = await fetch('https://connectby.io:3001/api/unblock-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customer_id: customerId,
                    status: status
                }),
            });
            if (response.ok) {
                setFilteredData(filteredData.map(customer =>
                    customer.customer_id === customerId ? { ...customer, block: status } : customer
                ));
                if (status === '1') {
                    message.success('Unblocked');
                } else {
                    message.success('Blocked');
                }
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error Creating Tag:', error);
        } finally {
            setBlockLoading(false);
        }
    };



    const columns = [
        {
            title: (<Checkbox
                onChange={handleSelectAllCustomers}
                checked={selectedCustomers.length === filteredData.length}
                indeterminate={selectedCustomers.length > 0 && selectedCustomers.length < filteredData.length}
            />),
            dataIndex: 'customer_id',
            width: '5%',
            render: (_, record) => (
                <Checkbox
                    onChange={() => handleCustomerSelect(record.customer_id)}
                    checked={selectedCustomers.includes(record.customer_id)}
                />
            ),
        },
        {
            title: '#',
            dataIndex: 'customer_id',
            width: '10%',
            render: (_, __, index) => ((pageNumber - 1) * pageSize) + index + 1,
            sorter: true,
            sortOrder: sortField === 'customer_id' && sortOrder,
        },
        {
            title: 'Name',
            dataIndex: 'customer_name',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'customer_name' && sortOrder,
        },
        {
            title: 'Phone No.',
            dataIndex: 'customer_phone',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'customer_phone' && sortOrder,
        },
        {
            title: 'Email ',
            dataIndex: 'customer_email',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'customer_email' && sortOrder,
        },
        {
            title: 'Status',
            dataIndex: 'block',
            width: '20%',
            render: (_, record) => {
                return (
                    <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Switch
                            onClick={() => handleBlock(record.customer_id, record.block === '2' ? '1' : '2')}
                            checkedChildren="Unblocked"
                            unCheckedChildren="Blocked"
                            defaultChecked={record.block === '1'}
                            loading={blockLoading}
                        />
                    </div>
                );
            }
        },
        {
            title: 'Action',
            dataIndex: 'id',
            width: '10%',
            render: (_, record) => (
                <Flex gap="small" align="flex-end" vertical>
                    <Flex gap="small">
                        <Tooltip title="Edit Customer">
                            <Button type="primary" className='editButton' shape="circle" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
                        </Tooltip>
                        <Popconfirm
                            title="Delete the Contact"
                            description="Are you sure to delete this Contact?"
                            onConfirm={() => handleDelete(record.customer_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title="Delete Customer">
                                <Button type="primary" className="deleteButton" danger shape="circle" icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    </Flex>
                </Flex>
            )
        },
    ];
    const onCloseNewUser = () => {
        setOpenNewUser(false);
    };

    const onCloseMNewUser = () => {
        setOpenNewMUser(false);
    };

    const onCloseBulkNewUser = () => {
        setOpenBulkNewUser(false);
        setFileData(null);
        setExistUserCount(0);
        setNewUserCount(0);
    };

    const handleDeleteAll = () => {
        Modal.confirm({
            title: 'Confirm Delete Contacts',
            content: (
                <p>
                    Are you sure you want to delete all{' '}
                    {selectedTagName && (
                        <strong>
                            {selectedTagName?.charAt(0)?.toUpperCase() + selectedTagName?.slice(1)}
                        </strong>
                    )}{' '}
                    Contacts?
                </p>
            ),
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                const crm_user_id = userData?.userData[0]?.user_id;
                const url = new URL('https://connectby.io:3001/api/deleteallcontacts');
                url.searchParams.append('crm_user_id', crm_user_id);
                url.searchParams.append('tag_id', selectedTagId);
                try {
                    const response = await fetch(url, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();

                    if (data.message) {
                        message.success('Contacts deleted successfully.');
                    } else {
                        message.error(`Failed to delete contacts: ${data.message || 'Unknown error'}`);
                    }
                } catch (error) {
                    console.error('Error sending request:', error);
                    message.error(`An error occurred while deleting the contacts: ${error.message}`);
                }
            },
        });
    };

    const handleDeleteSelected = () => {
        Modal.confirm({
            title: 'Confirm Delete Selected Contacts',
            content: (
                <p>
                    Are you sure you want to delete the {selectedCustomers.length} selected Contacts?
                </p>
            ),
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                const crm_user_id = userData?.userData[0]?.user_id;
                const url = new URL('https://connectby.io:3001/api/deleteselectedcampaigndata');
                url.searchParams.append('crm_user_id', crm_user_id);
                selectedCustomers.forEach(customerId => {
                    url.searchParams.append('customer_ids', customerId);
                });

                try {
                    const response = await fetch(url, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();

                    if (data.message) {
                        message.success('Selected contacts deleted successfully.');
                        setSelectedCustomers([]);
                    } else {
                        message.error(`Failed to delete contacts: ${data.message || 'Unknown error'}`);
                    }
                } catch (error) {
                    console.error('Error sending request:', error);
                    message.error(`An error occurred while deleting the contacts: ${error.message}`);
                }
            },
        });
    };

    const handleFileUpload = async (event) => {
        setBulkLoader(true);
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            const reader = new FileReader();
            reader.onload = async () => { // Make the onload callback async
                const fileData = reader.result;
                if (uploadedFile.name.endsWith('.csv')) {
                    // Parse CSV
                    Papa.parse(fileData, {
                        header: true,
                        complete: async (result) => { // Make the complete callback async
                            const parsedData = result.data.filter(row => {
                                return row["Client Name"] !== undefined && row["Client Name"].trim() !== '';
                            });
                            // const checkedData = [];
                            const uniqueParsedData = parsedData.reduce((acc, curr) => {
                                if (!acc.find(item => item['Phone Number'] === curr['Phone Number'])) {
                                    acc.push(curr);
                                }
                                return acc;
                            }, []);
                            const checkResponse = await checkCustomers(userData?.userData[0]?.user_id, uniqueParsedData);
                            const checkedData = checkResponse.map(response => {
                                const customer = uniqueParsedData.find(customer => customer['Phone Number'] === response.customer_phone);
                                if (response.exists) {
                                    setExistUserCount(prevCount => prevCount + 1);
                                    customer['is_exist'] = 'yes';
                                } else {
                                    setNewUserCount(prevCount => prevCount + 1);
                                    customer['is_exist'] = 'no';
                                }
                                return customer;
                            });
                            // for (const customer of uniqueParsedData) {
                            //     const customer_phone = customer['Phone Number'];
                            //     console.log(customer_phone);
                            //     const checkResponse = await checkCustomer(userData?.userData[0]?.user_id, customer_phone);
                            //     console.log(checkResponse);
                            //     if (!checkResponse.error) {
                            //         setNewUserCount(prevCount => prevCount + 1);
                            //         customer['is_exist'] = 'no';
                            //         checkedData.push(customer);
                            //     } else {
                            //         setExistUserCount(prevCount => prevCount + 1);
                            //         customer['is_exist'] = 'yes';
                            //         checkedData.push(customer);
                            //         // If phone number already exists, handle it here (e.g., show an error message)
                            //         console.log(`Skipping customer due to existing phone number: ${customer['Client Name']}`);
                            //     }
                            // }
                            setFileData(checkedData);
                            event.target.value = '';
                            setBulkLoader(false);
                        },
                        error: (error) => {
                            console.error('Error parsing CSV:', error);
                        }
                    });
                } else if (uploadedFile.name.endsWith('.xlsx')) {
                    const workbook = XLSX.read(fileData, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    const headerRow = jsonData[0];
                    const parsedData = [];
                    for (let i = 1; i < jsonData.length; i++) {
                        const row = jsonData[i];
                        const rowData = {};
                        for (let j = 0; j < headerRow.length; j++) {
                            rowData[headerRow[j]] = row[j];
                        }
                        parsedData.push(rowData);
                    }
                    // const checkedData = [];
                    const uniqueParsedData = parsedData.reduce((acc, curr) => {
                        if (!acc.find(item => item['Phone Number'] === curr['Phone Number'])) {
                            acc.push(curr);
                        }
                        return acc;
                    }, []);
                    const checkResponse = await checkCustomers(userData?.userData[0]?.user_id, uniqueParsedData);
                    const checkedData = checkResponse.map(response => {
                        const customer = uniqueParsedData.find(customer => customer['Phone Number'] === response.customer_phone);
                        if (response.exists) {
                            setExistUserCount(prevCount => prevCount + 1);
                            customer['is_exist'] = 'yes';
                        } else {
                            setNewUserCount(prevCount => prevCount + 1);
                            customer['is_exist'] = 'no';
                        }
                        return customer;
                    });

                    // for (const customer of uniqueParsedData) {
                    //     const customer_phone = customer['Phone Number'];
                    //     console.log(customer_phone);
                    //     const checkResponse = await checkCustomer(userData?.userData[0]?.user_id, customer_phone);
                    //     console.log(checkResponse);
                    //     if (!checkResponse.error) {
                    //         setNewUserCount(prevCount => prevCount + 1);
                    //         customer['is_exist'] = 'no';
                    //         checkedData.push(customer);
                    //     } else {
                    //         setExistUserCount(prevCount => prevCount + 1);
                    //         customer['is_exist'] = 'yes';
                    //         checkedData.push(customer);
                    //         // If phone number already exists, handle it here (e.g., show an error message)
                    //         console.log(`Skipping customer due to existing phone number: ${customer['Client Name']}`);
                    //     }
                    // }
                    setFileData(checkedData);
                    event.target.value = '';
                    setBulkLoader(false);
                } else {
                    console.error('Unsupported file format');
                    setBulkLoader(false);
                }
            };
            reader.readAsBinaryString(uploadedFile);
        }
    };

    const checkCustomers = async (crm_user_id, customers) => {
        try {
            const response = await fetch('https://connectby.io:3001/api/check-customers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ crm_user_id, customers }),
            });
            return await response.json();
        } catch (error) {
            console.error('Error checking customers:', error);
            return { error: 'Internal Server Error' };
        }
    };

    // const checkCustomer = async (crm_user_id, customer_phone) => {
    //     try {
    //         const response = await fetch('https://connectby.io:3001/api/check-customer', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ crm_user_id, customer_phone }),
    //         });
    //         return await response.json();
    //     } catch (error) {
    //         console.error('Error checking customer:', error);
    //         return { error: 'Internal Server Error' };
    //     }
    // };

    const onCloseEditUser = () => {
        setOpenEditUser(false);
        setEditUser(null);
        editform.resetFields();
    };

    useEffect(() => {
        if (countryCodes && countryCodes.length > 0) {
            const filtered = countryCodes.filter(country => {
                return (
                    country.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    country.dial_code.includes(searchText) ||
                    country.code.includes(searchText)
                );
            });
            setFilteredCodes(filtered);
        }
    }, [searchText, countryCodes]);

    const handleCountryCodeSearch = searchText => {
        setSearchText(searchText);
    };

    const onMenuClick: MenuProps['onClick'] = (e) => {
        // console.log(e);
        if (e.key === 'add_new') {
            setOpenNewUser(true);
        } else if (e.key === 'bulk_add_new') {
            setOpenBulkNewUser(true);
        } else if (e.key === 'add_multiple_new') {
            setOpenNewMUser(true);
        }
    };
    const items = [
        {
            key: 'add_new',
            label: 'Add New Customer',
            icon: <UserAddOutlined />
        },
        {
            key: 'add_multiple_new',
            label: 'Add Multiple Customer',
            icon: <UserAddOutlined />
        },
        {
            key: 'bulk_add_new',
            label: 'Bulk Add New Customers',
            icon: <UsergroupAddOutlined />
        },
    ];

    const handleAssignToGroup = () => {
        if (selectedCustomers.length === 0) {
            message.error('Please select customers to assign tags');
            return;
        }
        setShowPopup(true);
    }

    const handleAssignSubmit = () => {
        if (selectedTagIds === null) {
            message.error('Please select tags');
            return;
        }
        setIsAssignLoading(true);
        const tag_ids = selectedTagIds;
        const customer_ids = selectedCustomers;
        fetch('https://connectby.io:3001/api/assign-tags-multiple', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                crm_user_id: userData?.userData[0]?.user_id,
                tag_ids: tag_ids,
                customer_ids: customer_ids
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Assign tags response:', data);
            })
            .catch(error => {
                console.error('Error assigning tags:', error);
            }).finally(() => {
                message.success('Tags Assigned successfully');
                setShowPopup(false);
                setSelectedCustomers([]);
                setIsAssignLoading(false);
                setSelectedTagIds(null);
                getTags();
            });
    };

    const handleUpdateUserSubmit = async () => {
        try {
            setLoading(true);
            const formData = await editform.validateFields();
            const dobDate = formData.customer_dob ? new Date(formData.customer_dob) : null;
            const formattedDOB = dobDate ? `${dobDate.getFullYear()}-${(dobDate.getMonth() + 1).toString().padStart(2, '0')}-${dobDate.getDate().toString().padStart(2, '0')}` : '';
            const response = await fetch(`https://connectby.io:3001/api/update-customer/${formData.customer_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.userData[0]?.user_id,
                    country_code: formData.country_code ? formData.country_code.replace('+', '') : '',
                    customer_name: formData.customer_name || '',
                    customer_phone: formData.customer_phone || '',
                    customer_email: formData.customer_email || '',
                    customer_gender: formData.customer_gender || '',
                    customer_dob: formattedDOB,
                    note: formData.note || ''
                }),
            });
            if (response.ok) {
                message.success('Customer Updated successfully');
                // console.log('Customer Updated successfully');
                const updatedData = filteredData.map(item => {
                    if (item.customer_id === formData.customer_id) {
                        // Update the edited contact
                        return {
                            ...item,
                            customer_name: formData.customer_name,
                            customer_phone: formData.customer_phone,
                            customer_email: formData.customer_email,
                            customer_gender: formData.customer_gender,
                            customer_dob: formattedDOB,
                            note: formData.note
                        };
                    }
                    return item;
                });
                setFilteredData(updatedData);
                onCloseEditUser();
                editform.resetFields();
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error updating customer:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setFileData(null);
        setExistUserCount(0);
        setNewUserCount(0);
    }

    const handleTagChange = (value) => {
        setSelectedTags(value);
    };

    const handleTagsSelect = (value) => {
        setSelectedTagIds(value);
    };

    const handleRemoveRow = (index) => {
        setContactRows(contactRows.filter((_, i) => i !== index));
    };

    const handlemoreRows = () => {
        const newRows = [];
        const currentLength = contactRows.length;
        for (let i = 1; i <= 5; i++) {
            newRows.push({ id: currentLength + i });
        }
        setContactRows([...contactRows, ...newRows]);
    };

    const onTagSearch = (value) => {
        console.log('search:', value);
    };
    const tagfilterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleUpload = async () => {
        setUploadBulkLoader(true);
        try {
            const crm_user_id = userData?.userData[0]?.user_id;
            const dataWithCrmUserId = { crm_user_id, customers: fileData, tags: selectedTags };
            const response = await fetch('https://connectby.io:3001/api/upload-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataWithCrmUserId),
            });
            setUploadBulkLoader(false);
            console.log('Upload success:', response);
            setFileData(null);
            setExistUserCount(0);
            setNewUserCount(0);
            setSelectedTags(null);
            message.success('Customers uploaded successfully');
            setOpenBulkNewUser(false);
            getTags();
        } catch (error) {
            console.error('Error uploading data:', error);
            message.error('An error occurred while uploading data. Please try again.');
            setUploadBulkLoader(false);
        }
    };

    const handleNewMultipleUserSubmit = async () => {
        try {
            setLoading(true);
            const formData = await multiform.validateFields();
            const numEntries = Object.keys(formData).filter(key => key.startsWith('country_code_')).length;
            const dataArray = Array.from({ length: numEntries }, (_, index) => ({
                country_code: formData[`country_code_${index}`],
                customer_name: formData[`customer_name_${index}`],
                customer_phone: formData[`customer_phone_${index}`],
                tags: formData[`tags_${index}`]
            }));
            console.log(dataArray);
            const response = await fetch('https://connectby.io:3001/api/create-multiple-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.userData[0]?.user_id,
                    customers: dataArray,
                }),
            });
            if (response.ok) {
                message.success('Customer added successfully');
                onCloseMNewUser();
                multiform.resetFields();
                getTags();
                setContactRows([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]);
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error creating customer:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNewUserSubmit = async () => {
        try {
            setLoading(true);
            const formData = await form.validateFields();
            const dobDate = formData.customer_dob ? new Date(formData.customer_dob) : null;
            const formattedDOB = dobDate ? `${dobDate.getFullYear()}-${(dobDate.getMonth() + 1).toString().padStart(2, '0')}-${dobDate.getDate().toString().padStart(2, '0')}` : '';
            const response = await fetch('https://connectby.io:3001/api/create-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.userData[0]?.user_id,
                    country_code: formData.country_code ? formData.country_code.replace('+', '') : '',
                    customer_name: formData.customer_name || '',
                    customer_phone: formData.customer_phone || '',
                    customer_email: formData.customer_email || '',
                    customer_gender: formData.customer_gender || '',
                    customer_dob: formattedDOB,
                    tags: formData.tags || [],
                    note: formData.note || ''
                }),
            });
            if (response.ok) {
                message.success('Customer added successfully');
                onCloseNewUser();
                form.resetFields();
                getTags();
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error creating customer:', error);
        } finally {
            setLoading(false);
        }
    };

    const truncateNotes = (notes) => {
        if (notes.length > 25) {
            return notes.substring(0, 25) + '...';
        }
        return notes;
    };

    return (
        <Row>
            <Col span={7}>
                <Search placeholder="Search contacts by name, email or phone no." onChange={(e) => handleSearch(e.target.value)} style={{ marginBottom: '16px' }} />
            </Col>
            <Col span={17}>
                <Flex gap="small" align="flex-end" justify='end'>
                    <div>
                        <div>
                            <label><b>Filter By</b></label>
                        </div>
                        <Select
                            placeholder="Select Tags"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={handleChange}
                        >
                            <Option key='' value='' >All</Option>
                            {tagsData.map(tag => (
                                <Option key={tag.tag_id} value={tag.tag_id}>
                                    {tag.tag_name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <Button type='primary' onClick={handleAssignToGroup}>Assign Tags</Button>
                    <Dropdown menu={{ items, onClick: onMenuClick }}>
                        <Button type="primary">
                            <Space>
                                <UsergroupAddOutlined /> Add New Customers
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                    {selectedCustomers.length !== 0 ? (
                        <Button type='primary' danger onClick={handleDeleteSelected} icon={<DeleteOutlined />} style={{ marginRight: '8px' }}>
                            Delete {selectedCustomers.length} Selected Contacts
                        </Button>
                    ) : (
                        <Button type='primary' danger onClick={handleDeleteAll} icon={<DeleteOutlined />} style={{ marginRight: '8px' }}>
                            Delete All Contacts {selectedTagName && `from ${selectedTagName?.charAt(0)?.toUpperCase() + selectedTagName?.slice(1)}`}
                        </Button>
                    )}
                </Flex>
            </Col>
            <Col span={24}>
                {isLoading ? (
                    <Skeleton active />
                ) : (
                    <Table
                        pagination={{
                            total: totalItems,
                            current: pageNumber,
                            pageSize: pageSize,
                            pageSizeOptions: ['10', '20', '50', '100', '200', '500', '1000'],
                            onChange: (page, pageSize) => {
                                setPageNumber(page);
                                setPageSize(pageSize);
                            },
                            showTotal: (total, range) => (
                                <span className="totaldata">Showing {range[0]} to {range[1]} of {total} entries</span>
                            )
                        }}
                        columns={columns}
                        dataSource={filteredData.map((item, index) => ({ ...item, key: index }))}
                        onChange={handleTableChange}
                    />
                )}
            </Col>
            <Drawer
                title={<><UserAddOutlined /> Add Multiple Customer</>}
                width={720}
                onClose={onCloseMNewUser}
                open={openNewMUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseMNewUser}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleNewMultipleUserSubmit} type="primary">
                                    <span>Submit</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={multiform}>
                    {contactRows.map((row, index) => (
                        <Row gutter={16} key={index} align="middle">
                            <Col span={8}>
                                <Form.Item
                                    name={`customer_name_${index}`}
                                    label="Customer Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter customer name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter customer name" />
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    name={`tags_${index}`}
                                    label="Assign Tags"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select tags',
                                        },
                                    ]}
                                >
                                    <Select mode="tags" placeholder="Select Tags">
                                        {tagsData.map((tags) => (
                                            <Option key={tags.tag_id} value={tags.tag_id}>
                                                {tags.tag_name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Customer Phone No." style={{ margin: '0' }}>
                                    <Input.Group compact>
                                        <Form.Item
                                            style={{ width: '35%' }}
                                            name={`country_code_${index}`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select country code',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                onSearch={handleCountryCodeSearch}
                                                optionFilterProp="children"
                                                filterOption={false}
                                                placeholder="Select country code"
                                                dropdownMatchSelectWidth={false}
                                            >
                                                {filteredCodes.map((country) => (
                                                    <Option key={`${country.dial_code}-${country.name}`} value={country.dial_code}>
                                                        <img
                                                            src={country.image}
                                                            alt={country.name}
                                                            style={{ width: '1.5em', marginRight: '0.5em', position: 'relative', top: 5 }}
                                                        />
                                                        {country.dial_code} - {country.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            style={{ width: '65%' }}
                                            name={`customer_phone_${index}`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter phone number',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Please enter phone number" />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col span={1} style={{ marginTop: '22px' }}>
                                <MinusCircleOutlined
                                    style={{ fontSize: '20px', color: 'red' }}
                                    onClick={() => handleRemoveRow(index)}
                                />
                            </Col>
                        </Row>
                    ))}
                    <p style={{ cursor: 'pointer', color: '#075e54' }} onClick={handlemoreRows}><PlusOutlined /> Add 5 more rows</p>
                </Form>
            </Drawer>

            <Drawer
                title={<><UserAddOutlined /> Add New Customer</>}
                width={720}
                onClose={onCloseNewUser}
                open={openNewUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseNewUser}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleNewUserSubmit} type="primary">
                                    <span>Submit</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={form}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="customer_name"
                                label="Customer Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter customer name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter customer name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="tags"
                                label="Assign Tags"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter customer name',
                                    },
                                ]}
                            >
                                <Select
                                    // showSearch
                                    mode="tags"
                                    // optionFilterProp="children"
                                    // onSearch={onTagSearch}
                                    // filterOption={tagfilterOption}
                                    placeholder="Select Tags"
                                    rules={[
                                        {
                                            required: false, // Make the field required
                                            message: 'Please select tags',
                                        },
                                    ]}
                                >
                                    {tagsData.map(tags => (
                                        <Option key={tags.tag_id} value={tags.tag_id}>{tags.tag_name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Customer Phone No."
                            >
                                <Input.Group compact>
                                    <Form.Item
                                        style={{ width: '35%' }}
                                        name="country_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}>
                                        <Select
                                            showSearch
                                            onSearch={handleCountryCodeSearch}
                                            optionFilterProp="children"
                                            filterOption={false}
                                            placeholder="Select country code"
                                            dropdownMatchSelectWidth={false}
                                            rules={[
                                                {
                                                    required: true, // Make the field required
                                                    message: 'Please select country code',
                                                },
                                            ]}
                                        >
                                            {filteredCodes.map(country => (
                                                <Option key={`${country.dial_code}-${country.name}`} value={country.dial_code}>
                                                    <img src={country.image} alt={country.name} style={{ width: '1.5em', marginRight: '0.5em', position: 'relative', top: 5 }} /> {country.dial_code} - {country.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: '65%' }} name="customer_phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter phone number',
                                            },
                                        ]}>
                                        <Input placeholder="Please enter phone number" />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="customer_email"
                                label="Customer Email"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter email',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="customer_gender"
                                label="Gender"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select gender',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select gender">
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                    <Option value="other">Other</Option>
                                    <Option value="notdeclared">Don't declared</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="customer_dob"
                                label="Date of Birth"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select date of birth',
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="note"
                                label="Note"
                            >
                                <Input.TextArea rows={4} placeholder="Please enter any additional notes" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <Drawer
                title={<><UsergroupAddOutlined /> Bulk Add New Customers</>}
                width={720}
                onClose={onCloseBulkNewUser}
                open={openBulkNewUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Row gutter={16}>
                    {fileData ? (
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <div id="table-container">
                                <Table
                                    columns={dataColumns}
                                    dataSource={fileData}
                                    rowKey={(record, index) => index}
                                    rowClassName={(record) => record['is_exist'] === 'yes' ? 'exist' : 'notexist'}
                                />
                            </div>
                            <Text>If the data above does not look right, please check the formatting on your CSV file and try again.</Text>
                            <Text className="subtitletextOne">
                                <Text style={{ color: '#ffcdcd', marginRight: 5 }}>■</Text> ({existUserCount} Already Exist Users)&nbsp;&nbsp;
                                <Text style={{ color: '#abf7b1', marginLeft: 10 }}>■</Text> ({newUserCount} New Users)
                            </Text>
                            <Select
                                showSearch
                                mode="tags"
                                onChange={handleTagChange}
                                value={selectedTags}
                                placeholder="Select Tags"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select tags',
                                    },
                                ]}
                                style={{ width: '100%', textAlign: 'left' }}
                            >
                                {tagsData.map(tags => (
                                    <Option key={tags.tag_id} value={tags.tag_id}>{tags.tag_name}</Option>
                                ))}
                            </Select>
                            <div style={{ marginTop: '40px' }}>
                                <Button type="default" style={{ marginRight: '10px' }} onClick={handleCancel}>Cancel</Button>
                                {uploadBulkLoader ? (
                                    <Button type="primary"><Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} /></Button>
                                ) : (
                                    <Button type="primary" onClick={handleUpload}>Upload</Button>
                                )}
                            </div>
                        </Col>
                    ) : (
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Title level={3}>Import Customer from CSV/XLSX</Title>
                            <Title level={5} style={{ marginBottom: 24 }}>Select the CSV/XLSX file containing the list of contacts to import to your Connectby.io account.</Title>
                            <input
                                type="file"
                                id="fileInput"
                                accept=".csv, .xlsx"
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                            />
                            {bulkLoader ? (
                                <Button type='primary'><Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} /></Button>
                            ) : (
                                <Button type='primary' onClick={() => document.getElementById('fileInput').click()}>Upload CSV/XLSX</Button>
                            )}
                            <Title level={5} style={{ marginBottom: 24 }}>The CSV/XLSX file should have 5 columns in the exact order below:</Title>
                            <table className="table table-bordered" style={{ textAlign: 'left' }}>
                                <tbody>
                                    <tr>
                                        <th>Client Name</th>
                                        <td>Max 80 characters</td>
                                    </tr>
                                    <tr>
                                        <th>Country Code</th>
                                        <td>Country code (e.g. 1)</td>
                                    </tr>
                                    <tr>
                                        <th>Phone Number</th>
                                        <td>Phone Number (e.g. 9876543210)</td>
                                    </tr>
                                    <tr>
                                        <th>Email Address</th>
                                        <td>Optional</td>
                                    </tr>
                                    <tr>
                                        <th>Notes</th>
                                        <td>Optional</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Title level={5}>Please download the sample <Link href="https://crm.connectby.io/assets/leadsthrive-import-clients-template.csv" target="_blank">CSV template</Link> and follow the columns & formatting used.</Title>
                        </Col>
                    )}
                </Row>
            </Drawer>
            <Drawer
                title={<><UserAddOutlined /> Edit Customer</>}
                width={720}
                onClose={onCloseEditUser}
                open={openEditUser}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseEditUser}>Cancel</Button>
                            {loading ? (
                                <Button type="primary">
                                    <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                                </Button>
                            ) : (
                                <Button onClick={handleUpdateUserSubmit} type="primary">
                                    <span>Submit</span>
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={editform} >
                    <Form.Item name="customer_id" hidden>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="customer_name"
                                label="Customer Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter customer name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter customer name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Customer Phone No."
                            >
                                <Input.Group compact>
                                    <Form.Item
                                        style={{ width: '35%' }}
                                        name="country_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}>
                                        <Select
                                            showSearch
                                            onSearch={handleCountryCodeSearch}
                                            optionFilterProp="children"
                                            filterOption={false}
                                            placeholder="Select country code"
                                            dropdownMatchSelectWidth={false}
                                            rules={[
                                                {
                                                    required: true, // Make the field required
                                                    message: 'Please select country code',
                                                },
                                            ]}
                                        >
                                            {filteredCodes.map(country => (
                                                <Option key={`${country.dial_code}-${country.name}`} value={country.dial_code}>
                                                    <img src={country.image} alt={country.name} style={{ width: '1.5em', marginRight: '0.5em', position: 'relative', top: 5 }} /> {country.dial_code} - {country.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: '65%' }} name="customer_phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter phone number',
                                            },
                                        ]}>
                                        <Input placeholder="Please enter phone number" />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="customer_email"
                                label="Customer Email"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter email',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="customer_gender"
                                label="Gender"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select gender',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select gender">
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                    <Option value="other">Other</Option>
                                    <Option value="notdeclared">Don't declared</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="customer_dob"
                                label="Date of Birth"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select date of birth',
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="note"
                                label="Note"
                            >
                                <Input.TextArea rows={4} placeholder="Please enter any additional notes" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <Modal
                title="Select Tags"
                visible={showPopup}
                onCancel={() => setShowPopup(false)}
                footer={null}
            >
                <p>Total {selectedCustomers.length} Contacts selected</p>
                <Select showSearch mode="tags" placeholder="Select Tags" style={{ width: '100%' }} onChange={handleTagsSelect} value={selectedTagIds}>
                    {tagsData.map(tag => (
                        <Option key={tag.tag_id} value={tag.tag_id}>{tag.tag_name}</Option>
                    ))}
                </Select>
                <div style={{ height: 40 }}>
                    {isAssignLoading ? (
                        <Button type="primary" style={{ marginTop: 15, float: 'right' }}>
                            <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                        </Button>
                    ) : (
                        <Button type='primary' onClick={handleAssignSubmit} style={{ marginTop: 15, float: 'right' }}>Assign</Button>
                    )}
                </div>
            </Modal>
        </Row>
    );
}

export default Contacts;