import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { Table, Skeleton, Dropdown, Button, Flex, message, Input, Tooltip, Spin, Popconfirm, Col, Row, Drawer, Form, Select, Space, DatePicker, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, UserAddOutlined, UsergroupAddOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tab } from '@mui/material';
import { Label } from '@mui/icons-material';
const { Search } = Input;
const { Option } = Select;
const { Title, Text, Link } = Typography;

const Payments = (userData) => {
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [sortOrder, setSortOrder] = useState('');
    const [sortField, setSortField] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [openNewUser, setOpenNewUser] = useState(false);
    const [openBulkNewUser, setOpenBulkNewUser] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [editform] = Form.useForm();
    const createdAtTimestamp = userData?.userData[0]?.created_at;
    const createdAtDate = new Date(createdAtTimestamp * 1000);
    const today = new Date();
    const differenceInMs = today - createdAtDate;
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    const isWithin15Days = differenceInDays < 15;
    const [paymentStatus, setPaymentStatus] = useState('');
    const [planRate, setPlanRate] = useState('0');
    const [discount, setDiscount] = useState(`${userData?.userData[0]?.discount}%`);
    const [finalAmount, setFinalAmount] = useState('0');
    const [plan, setPlan] = useState('');
    const [tenure, setTenure] = useState('3');
    const [planData, setPlanData] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await fetch('https://connectby.io:3001/countrycode');
                if (!response.ok) {
                    throw new Error('Failed to fetch country codes');
                }
                const data = await response.json();
                const filteredData = data.filter(country => country.dial_code);
                const uniqueCountryCodes = [];
                const map = new Map();
                for (const country of filteredData) {
                    if (!map.has(country.dial_code)) {
                        map.set(country.dial_code, true);
                        uniqueCountryCodes.push(country);
                    }
                }
                setCountryCodes(uniqueCountryCodes);
                setFilteredCodes(uniqueCountryCodes);

            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };
        fetchCountryCodes();
    }, []);

    useEffect(() => {
        const getPlans = async () => {
            fetch('https://connectby.io:3001/api/get-plans', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setPlanData(data);
                })
                .catch(error => {
                    console.error('Error fetching notes:', error);
                });
        };
        getPlans();
    }, []);
    
    const handleEdit = async (record) => {
        const updatedEditUser = {
            ...record,
            customer_dob: record && record.customer_dob && record.customer_dob !== '' ? dayjs(record.customer_dob, "YYYY-MM-DD") : null,
        };
        setEditUser(updatedEditUser);
    };

    useEffect(() => {
        const redirectId = new URLSearchParams(location.search).get("message");
        if (redirectId !== null) {
            if (redirectId === 'success') {
                message.success('Payment Success.');
            }
            if (redirectId === 'failed') {
                message.error('Payment Failed.');
            }
            const newUrl = location.pathname;
            window.history.replaceState({}, '', newUrl);
        }
    }, [location]);

    useEffect(() => {
        if (editUser !== null) {
            editform.setFieldsValue(editUser);
            setOpenEditUser(true);
        }
    }, [editform, editUser]);

    useEffect(() => {
        const fetchCustomerData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`https://connectby.io:3001/api/getpayments/?customerId=${userData?.userData[0]?.user_id}&result=${pageSize}&page=${pageNumber}&searchTerm=${searchTerm}&sortorder=${sortOrder}&sortfield=${sortField}`,);
                if (!response.ok) {
                    throw new Error('Failed to fetch customer data');
                }
                const responseData = await response.json();
                const { data, totalItems } = responseData;
                setFilteredData(data);
                setTotalItems(totalItems);
            } catch (error) {
                console.log(error);
                console.error('Error fetching customer data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCustomerData();
    }, [userData, pageNumber, pageSize, sortOrder, sortField, searchTerm]);


    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);

        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = date.getFullYear();

        return `${dd}-${mm}-${yyyy}`;
    };

    const handleDelete = (id) => {

    };


    const handleTableChange = (pagination, filters, sorter) => {
        setPageNumber(pagination.current);
        setPageSize(pagination.pageSize);
        // console.log(sorter.order);
        // console.log(sorter.field);
        if (sorter.order) {
            setSortOrder(sorter.order);
            setSortField(sorter.field);
        } else {
            setSortOrder('');
            setSortField('');
        }
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'pay_id',
            width: '10%',
            render: (_, __, index) => ((pageNumber - 1) * pageSize) + index + 1,
            sorter: true,
            sortOrder: sortField === 'pay_id' && sortOrder,
        },
        {
            title: 'TransactionID',
            dataIndex: 'payment_id',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'payment_id' && sortOrder,
        },
        {
            title: 'Start Date',
            dataIndex: 'pay_start_date',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'pay_start_date' && sortOrder,
            render: (_, record) => formatDate(record.pay_start_date)
        },
        {
            title: 'End Date',
            dataIndex: 'pay_end_date',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'pay_end_date' && sortOrder,
            render: (_, record) => formatDate(record.pay_end_date)
        },
        {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            width: '20%',
            sorter: true,
            sortOrder: sortField === 'payment_status' && sortOrder,
            render: (_, record) => record.payment_status === 'success' ? <p style={{ color: 'green' }}>Success</p> : record.payment_status === 'pending' ? <p style={{ color: 'orange' }}>Pending</p> : <p style={{ color: 'red' }}>Failed</p>
        },
        {
            title: 'Amount',
            dataIndex: 'payment_amount',
            width: '20%',
            render: (_, record) => (
                `₹ ${record.payment_amount}`
            )
        }
    ];

    useEffect(() => {
        if (countryCodes && countryCodes.length > 0) {
            const filtered = countryCodes.filter(country => {
                return (
                    country.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    country.dial_code.includes(searchText) ||
                    country.code.includes(searchText)
                );
            });
            setFilteredCodes(filtered);
        }
    }, [searchText, countryCodes]);

    const handlePayment = async () => {
        if (plan === '') {
            message.error('Please select Plan');
            return;
        }
        console.log(plan);
        console.log(tenure);
        try {
            const response = await fetch('https://connectby.io:3001/api/new-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: userData?.userData[0]?.user_id,
                    plan_id: plan,
                    phone: userData?.userData[0]?.user_phone,
                    name: userData?.userData[0]?.user_name,
                    payment_end_date: userData?.userData[0]?.end_date,
                    plan_tenure: tenure,
                })
            });
            const responseData = await response.json();
            window.location.href = responseData.redirectUrl;
        } catch (error) {
            console.error('Error initiating payment:', error);
        }
    };

    const formatDateFromTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }

    const handleChangePlan = (value) => {
        const filterPlan = planData.filter(plan => plan.plan_id === value);
        setPlan(filterPlan[0]?.plan_id);
        if (tenure !== '') {
            const rate = parseFloat(filterPlan[0]?.plan_price) * parseFloat(tenure);
            const roundedRate = parseFloat(rate.toFixed(2)).toLocaleString('en-US', {
                style: 'currency',
                currency: 'INR',
            });
            // if (tenure === '12') {
                // setDiscount('20%');
                // const discountedRate = rate * 0.8;
                const discountedRate = rate - ((rate * parseFloat(userData?.userData[0]?.discount)) / 100);
                const gstRate = discountedRate * 0.18;
                const finalRate = discountedRate + gstRate;
                const roundedFinalRate = parseFloat(finalRate.toFixed(2)).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'INR',
                });
                setFinalAmount(roundedFinalRate);
            // } else {
                // setDiscount('0%');
                // const gstRate = rate * 0.18;
                // const finalRate = rate + gstRate;
                // const roundedFinalRate = parseFloat(finalRate.toFixed(2)).toLocaleString('en-US', {
                //     style: 'currency',
                //     currency: 'INR',
                // });
                // setFinalAmount(roundedFinalRate);
            // }
            setPlanRate(roundedRate.toString());
        }
    };

    const handleChangeTenure = (value) => {
        setTenure(value);
        if (plan !== '') {
            const filterPlan = planData.filter(plans => plans.plan_id === plan);
            const rate = parseFloat(filterPlan[0]?.plan_price) * parseFloat(value);
            const roundedRate = parseFloat(rate.toFixed(2)).toLocaleString('en-US', {
                style: 'currency',
                currency: 'INR',
            });
            setPlanRate(roundedRate.toString());
            // if (value === '12') {
                // setDiscount('20%');
                // const discountedRate = rate * 0.8;
                const discountedRate = rate - ((rate * parseFloat(userData?.userData[0]?.discount)) / 100);
                console.log(discountedRate);
                const gstRate = discountedRate * 0.18;
                const finalRate = discountedRate + gstRate;
                const roundedFinalRate = parseFloat(finalRate.toFixed(2)).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'INR',
                });
                setFinalAmount(roundedFinalRate);
            // } else {
            //     setDiscount('0%');
            //     const gstRate = rate * 0.18;
            //     const finalRate = rate + gstRate;
            //     const roundedFinalRate = parseFloat(finalRate.toFixed(2)).toLocaleString('en-US', {
            //         style: 'currency',
            //         currency: 'INR',
            //     });
            //     setFinalAmount(roundedFinalRate);
            // }
        }
    };

    return (
        <Row>
            <Col span={24}>
                {isLoading ? (
                    <Skeleton active />
                ) : (
                    <>
                        <Card
                            bordered={true}
                            hoverable
                            className='paymentCard'
                            style={{
                                width: '100%',
                                marginBottom: 20,
                                padding: 0
                            }}
                        >
                            {userData?.userData[0]?.start_date === "" ? (
                                <>
                                    <Title level={4}>Hey {userData?.userData[0]?.user_name}, Your plan has expired.<br /><span style = {{fontSize:'14px', fontWeight:'normal'}}>Please Purchase the plan to continue using the system without any limitations.</span></Title>
                                    <Row gutter={16} style={{ backgroundColor: '#96b5b1', borderRadius: '10px' }}>
                                        <Col span={4}>
                                            <label>Plans</label>
                                            <Select placeholder="Select Plan" style={{ width: '100%' }} onChange={handleChangePlan} value={plan !== '' ? plan : undefined}>
                                                {planData.map(plans => (
                                                    <Option key={plans.plan_id} value={plans.plan_id}>{plans.plan_name}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col span={4}>
                                            <label>Tenure (In month)</label>
                                            <Select placeholder="Select Tenure" style={{ width: '100%' }} onChange={handleChangeTenure} value={tenure}>
                                                <Option key={'3'} value={'3'}>3 month</Option>
                                                <Option key={'6'} value={'6'}>6 month</Option>
                                                <Option key={'12'} value={'12'}>12 month</Option>
                                            </Select>
                                        </Col>
                                        <Col span={4}>
                                            <label>Rate</label>
                                            <Input placeholder="Rate" readOnly value={planRate} />
                                        </Col>
                                        <Col span={2}>
                                            <label>Discount (%)</label>
                                            <Input placeholder="Discount" readOnly value={discount} />
                                        </Col>
                                        <Col span={2}>
                                            <label>Tax (%)</label>
                                            <Input placeholder="Tax" readOnly value='18%' />
                                        </Col>
                                        <Col span={4}>
                                            <label>Amount</label>
                                            <Input placeholder="Amount" readOnly value={finalAmount} />
                                        </Col>
                                        <Col span={4}>
                                            <label>&nbsp;</label><br />
                                            <Button className="paymentButtonOne" onClick={handlePayment} type="primary">
                                                <span>Pay Now ₹ {finalAmount}</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <br />
                                </>
                            ): userData?.userData[0]?.end_date && userData?.userData[0]?.end_date <= (Math.floor(Date.now() / 1000)+(7 * 24 * 60 * 60)) ?(
                                <>{userData?.userData[0]?.end_date <= Math.floor(Date.now() / 1000) ? (
                                    <Title level={4}>Hey {userData?.userData[0]?.user_name}, Your plan has expired on {formatDateFromTimestamp(userData?.userData[0]?.end_date)}.<br /><span style = {{fontSize:'14px', fontWeight:'normal'}}>Please Purchase the plan to continue using the system without any limitations.</span></Title>
                                ):(
                                    <Title level={4}>Hey {userData?.userData[0]?.user_name}, Your plan expires on {formatDateFromTimestamp(userData?.userData[0]?.end_date)}.<br /><span style = {{fontSize:'14px', fontWeight:'normal'}}>Please Purchase the plan to continue using the system without any limitations.</span></Title>
                                )}
                                    <Row gutter={16} style={{ backgroundColor: '#96b5b1', borderRadius: '10px' }}>
                                        <Col span={4}>
                                            <label>Plans</label>
                                            <Select placeholder="Select Plan" style={{ width: '100%' }} onChange={handleChangePlan} value={plan !== '' ? plan : undefined}>
                                                {planData.map(plans => (
                                                    <Option key={plans.plan_id} value={plans.plan_id}>{plans.plan_name}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col span={4}>
                                            <label>Tenure (In month)</label>
                                            <Select placeholder="Select Tenure" style={{ width: '100%' }} onChange={handleChangeTenure} value={tenure}>
                                                <Option key={'3'} value={'3'}>3 month</Option>
                                                <Option key={'6'} value={'6'}>6 month</Option>
                                                <Option key={'12'} value={'12'}>12 month</Option>
                                            </Select>
                                        </Col>
                                        <Col span={4}>
                                            <label>Rate</label>
                                            <Input placeholder="Rate" readOnly value={planRate} />
                                        </Col>
                                        <Col span={2}>
                                            <label>Discount (%)</label>
                                            <Input placeholder="Discount" readOnly value={discount} />
                                        </Col>
                                        <Col span={2}>
                                            <label>Tax (%)</label>
                                            <Input placeholder="Tax" readOnly value='18%' />
                                        </Col>
                                        <Col span={4}>
                                            <label>Amount</label>
                                            <Input placeholder="Amount" readOnly value={finalAmount} />
                                        </Col>
                                        <Col span={4}>
                                            <label>&nbsp;</label><br />
                                            <Button className="paymentButtonOne" onClick={handlePayment} type="primary">
                                                <span>Pay Now ₹ {finalAmount}</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <br />
                                </>
                            ) : (
                                <Title level={4}>Hey {userData?.userData[0]?.user_name}, Your plan Expire date is {formatDateFromTimestamp(userData?.userData[0]?.end_date)}</Title>
                            )}
                        </Card>
                        <Table
                            pagination={{
                                total: totalItems,
                                current: pageNumber,
                                pageSize: pageSize,
                                onChange: (page, pageSize) => {
                                    setPageNumber(page);
                                    setPageSize(pageSize);
                                },
                                showTotal: (total, range) => (
                                    <span className="totaldata">Showing {range[0]} to {range[1]} of {total} entries</span>
                                )
                            }}
                            columns={columns}
                            dataSource={filteredData.map((item, index) => ({ ...item, key: index }))}
                            onChange={handleTableChange}
                        />
                    </>
                )}
            </Col>
        </Row>
    );
}

export default Payments;